.delivery-info-component {
  font-family: $primaryType;
  font-size: 1.3rem;
  background-color: $white;
  box-shadow: 0 0.2rem 0.5rem 0 rgba($blue500, 0.6);
  display: flex;
  align-items: center;
  padding: 2rem 0;
  z-index: 9;
  position: relative;
  left: 0;
  right: 0;
  width: 100%;
  max-height: 7rem;

  &.locked {
    position: fixed;
  }

  @media screen and (max-width: $xs-tablet) {
    padding: .8rem 0;
    height: 4.6rem;
  } 

  
  .fcs-container {
    width: 100%;
  }
  .location-drawer-toggler {
    color: inherit;
    background-image: url("../img/icons/location-icon.svg");
    background-size: 1em 1.5em;
    background-repeat: no-repeat;
    background-position: .5em center;
    position: relative;
    padding-left: 1.8em !important;
    padding-bottom: 0.8rem;
    padding-top: 0.8rem;
    height: 3.6rem;

    &:hover,
    &:focus,
    &:active {
      color: $green300;
      background-image: url("../img/selected.svg"), url("../img/icons/location-fill.svg");
      background-size: 2.675em, 1em 1.5em;
      background-position: bottom left, .5em center;
      text-decoration: none;
    }
  }
  &.feature-yext-search-enabled{
		.location-drawer-toggler{
			@media screen and (min-width: $small-tablet) {
				visibility: hidden;
			}
		}
	} 
  .pickup-wrapper {
    display: flex;
    justify-content: center;
  }
  .find-order-wrapper {
    display: flex;
    align-items: center;
    justify-content: space-between;
    span {
      font-family: $primaryType;
      font-size: 1.5rem;
      font-weight: 500;
      @media screen and (max-width: $xs-tablet) {
        font-size: 1.2rem;
      }
    }
    img {
      width: 2rem;
      height: 3rem;
      margin-right: 1.2rem;
      @media screen and (max-width: $xs-tablet) {
        width: 1rem;
        height: 1.6rem;
        margin-right: .7rem;
      }
    }
    .btn-wrapper {
      @media screen and (max-width: $xs-tablet) {
        display: none;
      }
      .primary-button {
        &:not(:last-of-type) {
          margin-right: 3rem;
        }
      }
    }
    .store-locator-btn {
      pointer-events: none;
      color: $gray500;
      outline: none;
      text-shadow: none;
      @media screen and (max-width: $xs-tablet) {
        pointer-events: all;
      }
    }
  }
  .favorite-location-wrapper {
    display: flex;
    justify-content: space-between;
    .triangle-link {
      margin-left: 1rem;
    }
  }
  .info {
    display: flex;
    flex-grow: 3;
    @media screen and (max-width: $xs-tablet) {
      margin: 0 auto;
    }
  }
  .icon-wrapper {
    display: flex;
    align-items: center;
    pointer-events: none;
    color: $black;
    font-size: 1.3rem;
    
    @media screen and (max-width: $xs-tablet) {
      pointer-events: auto;
      font-size: 1rem;
    }

    &:focus {
      display: flex;
    }

    img {
      width: 2rem;
      height: 3rem;
      @media screen and (max-width: $xs-tablet) {
        width: 1rem;
        height: 1.6rem;
      }
    }
    .location {
      display: inline-block;
      font-family: $primaryType;
      margin-left: 0.6rem;
      line-height: 1.15;
      @media screen and (max-width: $xs-tablet) {
        font-size: 1rem;
      }
    }
  }
  .address-wrapper {
    display: flex;
    align-items: center;
    .address {
      pointer-events: none;
      color: $black;
      font-size: 1.3rem;
      font-weight: 500;
      line-height: 1;
      margin-left: 0.7rem; 
      border-left: solid 0.1rem $mandarin400;
      border-right: solid 0.1rem $mandarin400;
      padding: 0.4rem 1.2rem 0.4rem 1.1rem;
      @media screen and (max-width: $xs-tablet) {
        pointer-events: auto;
        font-size: 1rem;
        max-width: 22rem;
        font-size: 1rem;
        padding: 0.4rem 0.85rem 0.4rem 0.85rem;
        text-align: center;
      }
    }
    .pick-up-interval {
      padding: 0 2rem 0 1.1rem;
      pointer-events: none;
      color: $black;
      font-weight: 400;
      font-size: 1.3rem;


      @media screen and (max-width: $xs-tablet) {
        pointer-events: auto;
        font-size: 1rem;
        max-width: 6rem;
        font-size: 1rem;
        padding: 0 0 0 0.4rem;
        text-align: right;
      }
      .bull {
        margin: 0 .4rem;
        @media screen and (max-width: $xs-tablet) {
          display: none;
        }
      }
    }
  }
  .pick-up-interval {
    display: flex;
    align-items: center;
  }
  .info-edit-wrapper {
    display: flex;
    align-items: center;
    margin-left: 2rem;
  }
  .triangle-link {
    color: $green300;
    font-size: 1.4rem;
    font-weight: 500;
	display: inline-block;
	margin-left: 1.0rem;
    white-space: nowrap;
    @media screen and (max-width: $xs-tablet) {
      display: none;
    }
    &::after {
      background-image: url("../img/triangle-green.svg");
      margin-left: 0.5rem;
    }
  }
  &.llp .triangle-link {
    display: inline-block;
  }
  +.breadcrumb-component {
    @media screen and (max-width: $xs-tablet) {
      padding-top: 0;
    }
  }
  .btn-wrapper {
    white-space: nowrap;
    margin-left: 2rem;
    @media screen and (max-width: $xs-tablet) {
      display: none;
    }
  }
  .closest-location-wrapper {
    display: flex;
  } 
}
