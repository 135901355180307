.fcs-gift-cards,
.fcs-rewards,
.fcs-sign-up,
.fcs-locations,
.fcs-menu,
.fcs-search {
  position: relative;
  padding-left: 1.8em !important;
  
  &::before {
    content: "";
    display: inline-block;
    vertical-align: middle;
    line-height: 0;
    top: 50%;
    left: 0;
    position: absolute;
    transform: translateY(-50%);
    width: 1.5em;
    height: 1.5em;
  }
}

.fcs-sign-up {
  padding-left: 2em;

  &::before {
    background: url('../img/icons/mail-icon.svg') center center no-repeat;
    background-size: contain;
    width: 1.5em;
    height: 1.5em;
  }

  &:hover,
  &.fcs-nav-active {
    &::before {
      background-image: url('../img/icons/mail-fill.svg');
    }
  }
}

.fcs-gift-cards {
  &::before {
    background: url('../img/icons/gift-cards.svg') center center no-repeat;
    background-size: contain;
  }

  &:hover,
  &.fcs-nav-active {
    &::before {
      background-image: url('../img/icons/gift-cards-fill.svg');
    }
  }
}

.fcs-rewards {
  &::before {
    background: url('../img/icons/rewards-icon.svg') center center no-repeat;
    background-size: contain;
  }

  &:hover,
  &.fcs-nav-active {
    &::before {
      background-image: url('../img/icons/rewards-fill.svg');
    }
  }
}

.fcs-locations {
  &::before {
    background: url('../img/icons/location-icon.svg') center center no-repeat;
    background-size: contain;
  }
 
  &:hover,
  &.fcs-nav-active {
    &::before {
      background-image: url('../img/icons/location-fill.svg');
    }
  }
}

.fcs-menu {
  &::before {
    background: url('../img/icons/menu-icon.svg') center center no-repeat;
    background-size: contain;
  }

  &:hover,
  &.fcs-nav-active {
    &::before {
      background-image: url('../img/icons/menu-fill.svg');
    }
  }
}

.main-nav-component {
  .fcs-gift-cards,
  .fcs-rewards,
  .fcs-sign-up,
  .fcs-locations,
  .fcs-menu,
  .fcs-search {
    background-image: url('../img/selected.svg');
    background-repeat: no-repeat;
    background-size: 2.675em;
    background-position: bottom left;
  }

  .fcs-sign-up {
    background-image: url('../img/icons/mail-icon.svg');
    background-size: 1.5em 1.5em;
    background-repeat: no-repeat;

    &:hover,
    &.fcs-nav-active {
      background-image: url('../img/selected.svg'), url('../img/icons/mail-icon.svg');
      background-size: 2.675em, 1.5em 1.5em;
      background-repeat: no-repeat, no-repeat;
    }
  }

  .fcs-search {
    background-image: url('../img/icons/search.svg');
    background-size: 2rem 2rem;
    background-repeat: no-repeat;
    background-position: .5em center;
  
    &:before {
      display: none;
    }
  
    &:hover,
    &:focus,
    &.fcs-nav-active {
      background-image: url('../img/selected.svg'), url('../img/icons/search.svg');
      background-size: 2.675em, 2rem 2rem;
      background-position: bottom left, .5em center;
      background-repeat: no-repeat, no-repeat;
      letter-spacing: -0.01rem;
    }
  }

  .fcs-locations {
    padding-left: 2em;
    background-image: url('../img/icons/location-icon.svg');
    background-size: 1em 1.5em;
    background-repeat: no-repeat;
    background-position: .5em center;

    &:hover,
    &.fcs-nav-active {
      background-image: url('../img/selected.svg'), url('../img/icons/location-fill.svg');
      background-size: 2.675em, 1em 1.5em;
      background-position: bottom left, .5em center;
      background-repeat: no-repeat, no-repeat;
    }
  }
}