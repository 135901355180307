.city-list-component {
  margin: 0 0 1rem;
  margin-top: 2.5rem;

  @media screen and (max-width: $xs-tablet) {
    margin: 0;
  }

  .row {
    padding: 0;
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  
    @media screen and (max-width: $xs-tablet) {
      width: auto;
      max-width: none;
    }
  }

  .col {
    @media screen and (max-width: $xs-tablet) {
        padding: 0;
    }
  }

  ul {
    list-style-type: none;
    padding: 0;

    @media screen and (max-width: $xs-tablet) {
        margin-bottom: 0;
    }

    li {
      &::after,
      &:first-child::before {
        content: "";
        background-image: url('../img/divider-yellow.svg');
        background-repeat: no-repeat;
        background-size: 300% 100%;
        overflow: hidden;
        height: 0.2rem;
        width: 100%;
        display: block;
        margin: .6rem 0;
      }

      &:hover {
        &::after {
          background-image: url('../img/divider-green.svg');
        }
      }

      a {
        display: block;
        position: relative;

        &::after {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
        }
        
        &.triangle-link {
          font-size: 2.2rem;
          padding-left: 2.4rem;

          @media screen and (max-width: $xs-tablet) {
            padding-left: 23%;
          }

          &:hover {
            text-decoration: none;
            &:after{
              background-image: url("../img/triangle-green.svg");
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: $xs-tablet) {
    div {
      &:not(:first-of-type) {
        ul {
          li {
            &:before {
              display: none;
            }
          }
        }
      }
    }
  
    ul + ul {
      li {
        &:before {
          display: none;
        }
      }
    }
  }
}