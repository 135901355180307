.location-info-component {
  margin-bottom: 3.6rem;

  @media screen and (max-width: $xs-tablet) {
    padding: 0 1rem;
    text-align: center;
  }

  &:before {
    content: "";
    background-image: url("../img/divider-orange.svg");
    overflow: hidden;
    height: 0.3rem;
    display: block;
    margin: 0 -4.4rem;

    @media screen and (max-width: $xs-tablet) {
      margin: 0 0 0 -0.6rem;
    }
  }

  .title {
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: .6rem;
    margin-top: 2.6rem;

    @media screen and (max-width: $xs-tablet) {
      text-align: center;
      margin-bottom: 1rem;
    }
  }

  .social-wrapper {
    padding-left: 0;
    list-style: none;

    li {
      display: inline-block;
      margin: .6rem .8rem 0 0;
      margin-right: .8rem;

      &:last-child {
        margin-right: 0;
      }

      img {
        max-width: 3rem;
      }
    }
  }
}