.member-rank-component {
  display: flex;
  align-items: first baseline;
  margin-bottom: 3rem;

  @media screen and (max-width: $xs-tablet) {
    margin-top: 6rem;
  }

  .logo-desktop {
    margin-right: 4rem;
  }

  .logo-mobile {
    display: none;
    margin-bottom: 3rem;
    align-self: center;
  }
  
  @media screen  and (max-width: $sml-desktop) {
    flex-direction: column;
    padding: 0 1.6rem;
    margin-bottom: 4.2rem;
    
    .logo-desktop {
      display: none;
    }

    .logo-mobile {
      display: unset;
    }
  }

  @media screen and (max-width: $sml-desktop) {
    padding: 0;
  }

  .value {
    margin-right: .6rem;
  }

  .jamba-badge {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

    @media screen and (max-width: $sml-mobile) {
      justify-content: center;
      flex-wrap: wrap;
    }

    @media screen  and (max-width: $sml-desktop) {
      padding-top: 3rem;

      &::before {
        content: '';
        background: url('../../../img/dividermobile.svg');
        height: 0.5rem;
        width: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }
    }
  }

  .available-points {
    font-size: 4rem;
    font-weight: 500;
    color: $green350;
    display: inherit;
    align-items: center;
    gap: 0.5rem;
    margin: 0;
  }

  .description {
    font-size: 1.4rem;
    width: 7.2rem;
    font-weight: 500;
    letter-spacing: 0.4;
    line-height: 1.4rem;

    @media screen  and (max-width: $sml-desktop) {
      font-size: 1.2rem;
    }
  }

  .badge {
    display: grid;
    place-items: center;
    min-width: 15.3rem;
    min-height: 3.4rem; 
    background: url('../../../img/icons/greenvector.svg') center center no-repeat;
    background-size: 100% 100%;
    margin-left: 2rem;   
    
    span {
      font-family: $primaryType;
      font-size: 1.4rem;
      font-weight: 500;
      letter-spacing: 0.02rem;
      color: $blue100;
    }
    
    //Modifier for gold member badge
    &.gold {
      min-width: 17.6rem;
      background: url('../../../img/goldmember.svg') center center no-repeat;
      background-size: 100% 100%;
    }
  }
}