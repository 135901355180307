.cart-control-component {
  @media screen and (max-width: $xs-tablet) {
    .secondary-button {
      font-size: 1.5rem;
      padding-left: 1.6rem;
      padding-right: 1.6rem;
      max-width: 8rem;
      line-height: 1.5rem;
    }
  }
}