.checkout-rewards-offers-redesign {
  position: relative;
  background: $beige650;
  max-width: 55rem;
  margin: 0 auto;
  padding: 0 1.5rem;
 
  .rewards-offers-title {
    color: $green350;
    font-weight: $font-weight-bold;
  }
}