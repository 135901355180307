.account-order-component {
  font-family: $primaryType;

  ul {
    list-style: none;
    max-width: 66rem;
    margin: 0 auto 4.2rem;
    padding: 0;
  }

  .order-item {
    padding: 1rem 0;
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;

    &::before {
      content: '';
      background: url("../img/divider-pale-blue.svg") center center repeat-x;
      background-size: auto;
      background-size: cover;
      display: block;
      width: 100%;
      height: .3rem;
      left: 0;
      right: 0;
      top: 0;
      position: absolute;
      z-index: 1;
    }

    &:last-child {
      &::after {
        content: '';
        background: url("../img/divider-pale-blue.svg") center center repeat-x;
        background-size: auto;
        background-size: cover;
        display: block;
        width: 100%;
        height: .3rem;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        z-index: 1;
      }
    }

    .item-content {
      width: 25rem;
      @media screen and (max-width: $xs-tablet) {
        max-width: 16rem;
      }

      span {
        line-height: 1;
        font-family: $primaryType;
        display: block;
        font-size: 1.5rem;
        &.item-name {
          margin-bottom: .5rem;
        }
      }

      .location {
        display: inline-block;
        font-size: 1rem;
        font-weight: normal;
        margin-bottom: .4rem;
      }
    }


    .item-header {
      .title {
        display: inline-block;
        font-size: 1.4rem;
        line-height: 1;
        color: $black;
        font-weight: 700;
        margin-bottom: .5rem;
        margin-right: 2rem;
      }

      .date {
        display: inline-block;
        font-size: 1.2em;
        line-height: 1.15;
        color: $black;
        font-weight: normal;

        @media screen and (max-width: $xs-tablet) {
          display: block;
        }
      }
    }

    .product-list {
      list-style: none;
      font-size: 1.5rem;
      font-weight: normal;
      margin: 0 0 .4rem;
      padding: 0;

      @media screen and (max-width: $xs-tablet) {
        max-width: 18rem;
      }

      li {
        line-height: 1;
        margin-bottom: .2rem;
      }
    }

    .total-price {
      display: block;
      font-size: 1.4rem;
      font-weight: 700;
    }

    .item-footer {
      display: flex;

      .item-extra {
        display: inline-block;
        font-size: 1.2rem;
        font-weight: normal;
        margin-bottom: 0.4rem;

        &::after {
          display: none;
        }
      }
    }

    .item-receipt {
      @media screen and (max-width: $xs-tablet) {
      position: absolute;
      bottom: 1rem;
      left: 50%;
      transform: translateX(-50%);
      }

      &.triangle-link {
        font-size: 1.2rem;
        margin: .2rem 0 0;
        max-width: 100%;
        
        &::after {
          display: none;
        }
      }
    }

    .item-info {
      font-weight: 700;
      margin-bottom: .5rem;
    }

    &>.triangle-link {
      &::after {
        position: static;
        margin-left: .4rem;

        @media screen and (max-width: $xs-tablet) {
          transform: translateY(-50%);
          position: absolute;
          right: 0;
          top: 50%;
          transform: translateY(-50%); 
        }
      }

      @media screen and (max-width: $xs-tablet) {
        max-width: 6.4rem;
      }
    }
  }
}