.modal-component {
	text-align: center;
	position: fixed;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
	z-index: 1000;
	width: 100%;
	transition: .2s ease opacity, 0s linear .2s transform;
	opacity: 0;
	transform: translateY(-100%);
	
	&.open {
		z-index: 1200;
		opacity: 1;
		transform: translateY(0);
		transition: .2s ease opacity;
	}
	
	.modal-title {
		margin-bottom: 4rem;
		line-height: 1;
	}
	
	.content-wrapper {
		margin-left: auto;
		margin-right: auto;
	}

	.info-text {
		max-width: 42rem;
		width: auto;
		word-wrap: break-word;
	}

	button {
		border: none!important;
		width: 100%;
	}
	
	.primary-button {
		width: 100%;
	}
	
	.modal-content {
		overflow: auto;
		align-items: flex-start;
		padding-top: 4rem;
		@media screen and (max-width: $xs-tablet) {
			padding: 4rem 2.8rem;
		}
	}
	
	.modal-header,
	.modal-body {
		@media screen and (max-width: $xs-tablet) {
			padding: 0;
			margin: 0 auto;
		}
	}
	
	.modal-header {
		.info-wrapper {
			margin-top: 2rem;
			margin-bottom: 2rem;
		}
	}
	
	.modal-body {
		padding-bottom: 2rem;
		.img-wrapper {
			img {
				max-width: 14.6rem;
				display: block;
				margin: 0 auto;
			}
		}
		
		p {
			font-size: 1.8rem;
		}
		
		.divider {
			background-image: url("../img/divider-gray.svg");
			background-position: center center;
			background-repeat: no-repeat;
			height: 2px;
			margin: 3rem 0;
		}
	}
	
	.delimiter-text {
		font-size: 2rem;
		font-weight: 700;
		line-height: 1.4;
		font-family: $primaryType;
		margin: 1.4rem 0;
		display: inline-block;
	}
}

.modal-content {
	display: flex;
	justify-content: center;
	align-items: center;
}

.modal-wrapper {
	width: 100%;
	flex-basis: 100%;
	
	@media screen and (max-width: $xs-tablet) {
		height: 100%;
	}
}

.fcs-container {
	position: relative;
}

.close-wrapper {
	height: 2.3rem;
}

button.close {
	position: absolute;
	right: 0;
	top: 0;
	padding: 0;
	border: 0;
	width: 2.4rem;
	height: 2.4rem;
	background-size: 100%;
	background-color: transparent;
	overflow: hidden;
	
	@media screen and (max-width: $xs-tablet) {
		top: 0;
		right: .2rem;
	}
	
	img {
		max-width: 2.4rem;
		user-select: none;
		pointer-events: none;
	}
	
	&:hover,
	&:focus {
		img {
			transform: translateY(-50%);
			user-select: none;
			pointer-events: none;
		}
	}
}

.modal-header {
	max-width: 42rem;
	margin: 0 auto;
}

.modal-body {
	padding-top: 0;
}

.modal-content {
	background-color: $beige700;
	border: 0;
	box-shadow: none;
	padding: 3.6rem 3.6rem 2.4rem 3.6rem;
	height: 100%;
	
	@media screen and (max-width: $xs-tablet) {
		width: 100vw;
		border-radius: 0;
		padding: 3.9rem 1.8rem 0 1.8rem;
		margin-top: 0;
		overflow-y: auto;
		overflow-x: hidden;
	}
	
	.separator-wrapper {
		font-size: 1.3rem;
		margin: 1.5rem 0;
		span {
			background-color: $beige700;
		}
	}
	
	.input-wrapper {
		label {
			font-weight: 400;
			font-size: 1rem;
		}
	}
	.location-image {
		margin-right: 1.5rem;
	}
}

.modal-dialog {
	width: 41rem;
	height: 47rem;
	margin: 0 auto;
	pointer-events: all !important;
	
	@media screen and (max-width: $xs-tablet) {
		width: 100vw;
		height: 100vh;
		border-radius: 0;
		margin: 0;
	}
}

.modal-footer {
	border: 0;
	text-align: center;
	display: block;
}

.modal-header {
	border: 0;
	position: relative;
	text-align: center;
	padding: 0;
	display: block;
}

.info-wrapper {
	display: flex;
	justify-content: center;
	margin-bottom: 1.5rem;
	
	@media screen and (max-width: $xs-tablet) {
		justify-content: flex-start;
	}
	
	a {
		white-space: nowrap;
		color: $green300;
		font-weight: 500;
		
		&:after {
			margin-left: 0.5rem;
			background-image: url("../img/triangle-green.svg");
		}
	}
}

.info-text {
	font-size: 1.8rem;
	line-height: 1.33;
	font-family: $primaryType;
	
	@media screen and (max-width: $xs-tablet) {
		text-align: left;
		font-size: 1.5rem;
	}
}

.primary-button {
	@media screen and (max-width: $xs-tablet) {
		font-size: 1.8rem;
		padding: 1.64rem 0;
	}
}

.modal-title {
	font-size: 3.2rem;
	color: $black;
	font-family: $primaryType;
	font-weight: 700;
	margin-top: 2.2rem;
	
	@media screen and (max-width: $xs-tablet) {
		margin-top: 2.5rem;
	}
}


.modal-wrapper {
	position: relative;
	
	@media screen and (max-width: $xs-tablet) {
		position: static;
	}
	
	.modal-background {
		background-color: rgba(0, 0, 0, 0.8); 
		position: fixed;
		z-index: 1;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: none;
		animation: .2s fadeIn linear forwards;
	}
	
	[data-target] {
		z-index: 12;
	}
	
	.sign-up-modal {
		position: absolute;
		right: -.8rem;
		top: calc(100% - 2.3rem);
		z-index: 11;
		
		
		@media screen and (max-width: $xs-tablet) {
			right: 0;
			top: 0;
			left: 0;
		}
		
		.modal-dialog {
			z-index: 2;
			position: relative;
			animation: .2s fadeDown linear forwards;
			opacity: 0;
			transform: translateY(-5%);
		}
	}
	
	@keyframes fadeIn {
		0% {
			opacity: 0;
		}
		100% {
			opacity: 1;
		}
	}
	
	@keyframes fadeDown {
		0% {
			opacity: 0;
			transform: translateY(-5%);
		}
		100% {
			opacity: 1;
			transform: translateY(0)
		}
	}
	
	&.modal-visible {
		.modal-background {
			display: block;
			
			@media screen and (max-width: $xs-tablet) {
				display: none;
			}
		}
		
		.sign-up-modal {
			display: block;
		}
	}
}

.sc-editor .success-show,
.sc-editor .fail-show,
.sc-editor .already-in-show,
.sc-editor .sign-up-modal {
	display: block;
}
.agree-error {
	color: $red600;
}

.ios {
	.sign-up-modal {
		.modal-body {
			@media screen and (max-width: $xs-tablet) {
				margin-bottom: 10rem;
			}
		}
	}
}

#signupModal {
	display: none;
}

.content-container {
	display: none;
}

.modal-component {
	.content-container {
		display: block;
	}
}

.modal-header {
	.info-wrapper {
		display: block;
		text-align: left;
	}
	.info-details {
		display: block;
		margin-bottom: .5rem;
	}
	.info-text {
		display: inline-block;
		margin-right: 1rem;
	}
}
.modal-body {
	.address {
		margin-top: 1rem;
	}
}
