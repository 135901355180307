.location-hours-component {
  margin-bottom: 3rem;

  @media screen and (max-width: $xs-tablet) {
    padding: 0 3.6rem;
    margin-bottom: 2.4rem;
  }

  .title {
    font-size: 2.4rem;
    font-weight: 700;
    margin-top: 3.2rem;

    @media screen and (max-width: $xs-tablet) {
      text-align: center;
      margin-bottom: 1.6rem;
      margin-top: 0;
    }
  }

  dl {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 3.4rem;

    @media screen and (max-width: $xs-tablet) {
      margin-bottom: 2rem;
    }

    dt,
    dd {
      display: inline-block;
      font-family: $primaryType;
      color: $black;
      line-height: 1.33;
      font-size: 1.5rem;
      margin-bottom: .2rem;

      &:first-of-type {
        margin-bottom: 1rem;
      }
    }

    dt {
      width: 12.4rem;
      padding-right: 2rem;
      font-weight: 500;

      @media screen and (max-width: $xs-tablet) {
        width: 12rem;
      }
    }

    dd {
      width: calc(100% - 12.4rem);

      @media screen and (max-width: $xs-tablet) {
        width: calc(100% - 12rem);
      }
    }
  }
}