.recent-orders-slider-component {
  .recent-order-item {
    margin-right: 2.4rem;
    text-align: left;
  }

  .title,
  .address,
  .date,
  .ingredients,
  .item-info,
  .priceing-info {
    font-family: $primaryType;
  }

  .title {
    font-size: 1.5rem;
    font-weight: 500;
    line-height: 1.33;
    color: $black;
    margin-bottom: .2rem;
  }
  
  .address {
    font-size: 1.2rem;
    line-height: 1.17;
    color: $black;
    margin-bottom: 1.4rem;
  }

  .date {
    font-size: 1rem;
    line-height: 2;
    color: $black;
  }

  .ingredients {
    font-size: 1.3rem;
    line-height: 1.23;
    color: $black;
    margin-bottom: .6rem;
  }

  .item-info {
    display: flex;
    justify-content: space-between;
    font-size: 1.3rem;
    line-height: 1.23;
    font-weight: 500;
    margin-bottom: 3.4rem;
  }

  .order-again {
    text-align: center;
    font-size: 1.8rem;
    line-height: 1;
  }

  .modal-title {
    font-size: 3rem;
  }

  .modal-subtitle {
    text-align: center;
  }

  .modal-body {
    padding: 0;
    margin-top: 3.4rem;
  }

  .modal-footer {
    padding: 0;
    margin-top: 2rem;
  }

  .separator-wrapper {
    margin: 1rem 0;
  }

  .buttons-wrapper {
    text-align: center;
    max-width: 31.5rem;
    margin: 0 auto;

    .primary-button,
    .tertiary-button {
      width: 100%;
    }
  }

  .priceing-info {
    font-size: 1.5rem;
    line-height: 1.33;
  }
}