.mobile-main-nav-component {
  background: rgba($blue600, .3);

  .icon-location {
    font-size: 2.5rem;
  }

  .icon-mail {
    font-size: 1.5rem;
  }

  .icon-menu {
    font-size: 2.3rem;
  }

  .menu-bar {
    margin-bottom: 0;
    padding: 0;
    flex-basis: 100%;
    display: flex;
    justify-content: space-between;
    padding: 1.75rem 1.6rem;
    align-items: center;

    @media (max-width: $xs-tablet) {
      height: 6rem;
    }

    li {
      display: inline-block;

      a {
        color: $gray500;

        &:hover,
        &.fcs-nav-active {
          text-decoration: none;
          font-family: $primaryType;
          font-weight: 500;
          color: $green300;
        }
      }
    }
  }
}