.scrollbar-component {
  position: absolute;
  background: transparent;
  border-radius: 500px;
  
  .thumb {
    background-color: $green300;
    border-radius: 500px;
    cursor: pointer;
  }

  &.vertical {
    left: auto;
    right: .2rem;
    top: .1rem;
    bottom: .1rem;
    width: .5rem !important;
    height: auto;
  }

  &.horizontal {
    left: .1rem;
    right: .1rem;
    top: auto;
    bottom: .2rem;
    width: auto;
    height: .5rem !important;
  }
}