.nutrition-component {
  @media (max-width: $xs-tablet) and (min-width: $mid-mobile) {
    margin-bottom: 2rem;
  }
  //jj-937 compromise
  padding-top: 1.5rem !important;

.section-title {
  padding-bottom: 4rem;

  &:after {
    content: '';
    background-image: url("../img/underline-green.svg");
    background-repeat: no-repeat;
    display: inline-block;
    width: 100%;
    height: 15px;
    background-position-x: center;
  }

    h2,
    span {
      margin-bottom: 0;
    }
}
  
  .table-tabs {
    width: 95%;
    margin: 3rem auto 0;
    height: 61px;
      
    .tab {
      width: 24%;
      margin-left: 1%; 
      background-color: transparent;
      text-transform: lowercase;
      display: inline-block;
      background-image: url('../img/tab-bg.png');
      background-repeat: no-repeat;
      background-size: 100% 100%;
      border: none;
      height: 70px;
        
      text-align: center;
      font-size: 1.6rem;
      line-height: 1.6rem;
      font-family: $primaryType;
      font-weight: 400;
      color: $gray500;
      padding: 1.2rem 0;
      margin-top: -.7rem;
      @media(max-width: 1225px){
        font-size: 1.2vw;
      }
      @media(max-width: $small-tablet){
        font-size: 1.4rem;
      }
      @media(max-width: 320px){
        font-size: 1.1rem;
      }
      &.active {
        background-color: transparent;
        border: none;
        outline: none;
        color: $white;
        height:80px;
        padding: 2.2rem 0;
        background-image: url('../img/tab-bg-active.png');
        margin-top: -1.8rem;
        font-family: $primaryType;
        font-weight: 700;
        font-size: 1.6rem;
        @media(max-width: 1225px){
          font-size: 1.1vw;
        }
        @media(max-width: $small-tablet){
          font-size: 1.4rem;
        }
        @media(max-width: 320px){
          font-size: 1.1rem;
        }
      }
      &:focus {
        border: none;
        outline: none;
      }
    }
  }
  .table-wrapper {
    border: 2px solid $green300;
    display: inline-block;
    border-radius: 15px;
    padding: 1rem;
    font-family: $primaryType;
    width: 100%;
    @media(max-width: 991px){
        width: 100%;
    }

    .dk-green-text {
      color: $green300;
      font-size: 1.3rem;
    }

    .nut-table-title {
      font-size: 1.3rem;
      display: block;
      border-bottom: 1px solid $gray1100;
      padding: .6rem 0;
      font-family: $primaryType;
      font-weight: 500;
    }
    .nut-cal-count {
      font-size: 1.4rem;
      display: block;
      border-bottom: 3px solid $green600;
      padding: .7rem 0;
      font-family: $primaryType;
      font-weight: 500;
    }

    .nutrition-facts-label {
      color: $gray600;
      font-size: 1.4rem;
      width: 100%;
      @media (max-width: 991px) {
        font-size: 1.3rem;
      }
        
      thead {
        font-size: 1.2rem;

        th {
          text-align: left;
          font-family: $primaryType;
          font-weight: 500;
        }
        
        a[id^="daily-value"] {
          scroll-margin-top: 180px;
        }
      }

      tr {
        border-bottom: 1px solid $green700;
        td, th {
            padding: .5rem;
            padding-right: 0;
        }
        .cell-header {
          font-weight: 400;
          padding-left: 2.5rem;
          text-align: left;
          @media(max-width: $small-tablet){
            padding-left: 1rem;
          }
        }
        td {
          padding: .5rem .5rem;
          padding-right: 7.5rem;
          @media(max-width: $small-tablet){
            padding-right: 2.5rem;
          }
          &:first-child {
            padding-left: 2.5rem;
            text-align: left;
            @media(max-width: $small-tablet){
              padding-left: 1rem;
            }
          }

        &:last-child {
            font-family: $primaryType;
            font-weight: 700;
            text-align: left;
            padding-right: .5rem;
          }
            
          .caloriesFromFat {
            font-weight: normal;
          }

          &.inset-row {
            border-bottom: 1px solid transparent;
            width: 20px;
          }
        }
      }
    }

      .nutrition-extra {
        width: 75%;
        margin: 1.5rem auto;
        font-size: 1.2rem;
        font-family: $primaryType;
        font-weight: 500;
        @media(max-width: $small-tablet){
          width: auto;
        }
        tr {
          float: left;
          width: 50%;
          text-align: center;
          td {
            display: inline-block;
            &:first-child {
              padding-right: .5rem;
            }
            &:last-child {
              padding-left: .5rem;
            }
          }
        }
      }

      .footnote {
        font-size: 1.1rem;
        scroll-margin-top: 180px;

        &:target {
          background: $gold700;
        }

        .back-to-content {
          display: inline-block;

          &::after {
            content: url('../img/icons/back-to-content.svg');
            display: inline-block;
            width: 1rem;
            height: 1rem;
          }
        }
      }
  }

  .nutrition-facts {
    padding-top: 6.1rem;
    @media(max-width: $small-tablet){
      text-align: center;
      padding-top: 2rem;
    }

    .nutrition-facts-title {
      font-family: $primaryType;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 1.33;
      display: block;
    }
    ul { 
      font-size: 1.5rem;
      padding-left: 0;
      list-style: none;
      li:before {
        content: "\2022";  /* Add content: \2022 is the CSS Code/unicode for a bullet */
        color: $green300; 
        padding-right: 1rem;
      }

      @media(max-width: $small-tablet){
        margin-bottom: 2rem;
      }
    }

    p {
      font-size: 0.9rem;
    }
  }
  .bottom-common-detail {
    font-size: .9rem;
    width: 100%;
    td {
      width: 33%;
    }
  }
  .cal-fat {
    float: right;
  }
}

@media screen and (-ms-high-contrast: active) {
  .edge {
    .nutrition-component {
      .table-tabs {
        .tab {
          background: yellow;
          a {
            color: yellow;
            background: yellow;
          }
        }
      }
    }
  } 
}
