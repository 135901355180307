.favorite-products-component {
  .modal-header {
    .info-wrapper {
      margin-bottom: 1.2rem;

    }
    .modal-title {
      margin: 3.2rem 0 3rem;

      @media screen and (max-width: $xs-tablet) {
        margin-bottom:  1.5rem;
      }
    }
  }

  .list-wrapper {
    .gradient-helper {
      background-image: linear-gradient(to left, $beige700, rgba(255, 255, 250, 0));
    }

    .favorite-product-item {
      margin-top: 8rem;
      margin-right: 3rem;
      background-color: $blue900;
    }

    .next,
    .prev {
      img {
        @media screen and (max-width: $xs-tablet) {
          display: none;
        }
      }
    }
  }

  .product-image {
    max-width: 13.3rem;
    width: 100%;
    max-height: 11rem;
    margin: -9rem auto 1rem;
  }

  .title {
    font-family: $primaryType;
    font-size: 2rem;
    font-weight: 500;
    line-height: 1.1;    
    text-align: center;
    margin-bottom: .5rem;
  }

  .details-wrapper {
    font-size: 0;
    font-family: $primaryType;
    margin-bottom: 1rem;
    position: relative;

    span {
      font-size: 1.3rem;
      font-weight: 500;
      line-height: 1.33;
      color: $black;
      display: inline-block;

      & + span {
        margin-left: .8rem;
        padding-left: .8rem;
        border-left: 2px solid $gold500;
      }
    }

    &::after {
      content: "";
      position: absolute;
      bottom: auto;
      background-image: url("../img/divider-orange.svg");
      background-repeat: no-repeat;
      background-size: 300% 100%;
      overflow: hidden;
      height: 0.3rem;
      display: block;
      margin-top: .4rem;
      border: none;
      width: 100%;
    }
  }

  .boosts-list {
    font-family: $primaryType;
    font-size: 1.3rem;
    list-style: none;
    padding: 0;
    text-align: left;
  }

  .add {
    font-size: 1.8rem;
  }

  .buttons-wrapper {
    display: flex;
    justify-content: center;
  }

  .primary-button {
    max-width: 21rem;
    margin-right: 3rem;

    @media screen and (max-width: $xs-tablet) {
      max-width: 15.5rem;
      margin-right: 2rem;
      min-width: 0;
    }
  }

  .modal-body {
    max-width: 100%;
  }
}