.secondary-nav-component {
  margin: 8rem 0 2rem 0;

  @media screen and (max-width: $xs-tablet) {
    margin: 1rem 0 0.3rem 0;
  }

  nav {
    width: 100%;
    margin: 0 auto;

    @media screen and (max-width: $xs-tablet) {
      overflow: hidden;
      overflow-x: auto;
      white-space: nowrap;
      display: flex;
      justify-content: center;
      margin-left: -2rem;
      margin-right: -2rem;
      width: 100vw;
    }

    @media screen and (max-width: $mid-mobile) {
      justify-content: flex-start;
    }

   .list-wrapper {
      margin: 0 auto;
      padding: 0 0 1.6rem;
      list-style-type: none;
      position: relative;
      
      @media screen and (max-width: $xs-tablet) {
        display: flex;
        white-space: nowrap;
        padding-left: 2rem;
        padding-right: 2rem;
        position: relative;
      }

      &:before {
        content: "";
        background: rgba($inactive, 0.35);
        height: 3px;
        width: 100%;
        display: block;
        position: absolute;
        bottom: -1.5rem;
        border-radius: 500px;

        @media screen and (max-width: $xs-tablet) {
          bottom: 0;
          left: 2rem;
          right: 2rem;
          width: auto;
        }
      }
      
      li {
        float: left;

        &:last-child {
          @media screen and (max-width: $xs-tablet) {
            margin-right: 0;
          }
        }
        
        & + li {
          margin-left: 6rem;
        }

        &.fcs-nav-active {
          position: relative;

          &:before {
            content: "";
            background: $green300;
            height: 3px;
            width: 100%;
            display: block;
            position: absolute;
            bottom: -1.3rem;
            border-radius: 500px;

            @media screen and (max-width: $xs-tablet) {
              bottom: -1.6rem;
            }
          }

          a {
            font-family: $primaryType;
            font-weight: 500;
            color: $black;
          }
        }

        a {
          color: $gray500;
          font-weight: 500;
          line-height: 1;
          white-space: nowrap;

          &.selected {
            border-bottom: 2px solid $green300;
            padding-bottom: 1.1rem;
            position: relative;

            @media screen and (max-width: $xs-tablet) {
              padding-bottom: 1.2rem;
            }
          }

          &:hover {
            text-decoration: none;
          }
          &:focus {
            &.fcs-third-party {
              border: none;
              outline: none;
              &:after {
                content: " ";
                border: 2px solid $green300;
                position: absolute;
                left: -.6rem;
                right: -2.2rem;
                top: -.6rem;
                bottom: -.2rem;
              }
            }
          }
        }
      }
    }
    .fcs-third-party-icon {
      top: 0.6rem;
      transform: none;
    }
  }
}

.edge {
  .secondary-nav-component {
    li {
      a {
        &.selected {
          @media screen and (-ms-high-contrast: active) {
            border-bottom: 6px solid $green300 !important;
          }
        }
      }
    }
  }
}