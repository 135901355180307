.checkout-add-info-component {
  max-width: 33rem;
  margin: 0 auto;
  
  .modal-content {
    .modal-header {
      h5 {
        &.modal-title {
          margin-bottom: 2rem;
        }
      }
      p {
        padding: 0 1rem;
        text-align: left;
      }

    }
  }

  .input-wrapper {
    &:last-of-type {
      margin-bottom: 4rem;
    }

    &.valid,
    &.error {
      &::after {
        content: "";
        position: absolute;
        background-size: cover;
        display: inline-block;
        width: 2.4rem;
        height: 2.4rem;
        margin-right: 0;
        right: 0;
      }
    }

    &.valid {
      &::after {
        background-image: url('../img/icons/input-valid.svg');
        width: 2.4rem;
        height: 2.4rem;
        top: 2rem;
      }
    }

    &.error {
      &::after {
        background-image: url('../img/icons/input-invalid.svg');
        width: 1.8rem;
        height: 1.8rem;
        top: 2.3rem;
      }
    }
  }

  .log-in-wrapper {
    position: relative;

    @media screen and (max-width: $xs-tablet) {
      margin-left: -2.8rem;
      margin-right: -2.8rem;
    }

    &:after,
    &:before {
      content: '';
      background: url("../img/divider-pale-blue.svg") center center repeat-x;
      background-size: auto;
      background-size: cover;
      display: block;
      width: 100%;
      height: .3rem;
      left: 0;
      right: 0;
      position: absolute;
      z-index: 1;
      top: 0;
    }

    &:after {
      top: auto;
      bottom: 0;
    }
  }

  .log-in-wrapper {
    & > a {
      display: flex;
      align-items: center;
      text-decoration: none;
      transition: .2s ease background;
      background: $white;
      padding: 1.2rem 1.2rem;
      position: relative;
      min-height: 6rem;

      &.triangle-link {
        &::after {
          position: absolute;
          right: 2rem;
          background-image: url("../img/triangle-green.svg");
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }

  .item-text-wrapper {
    text-align: left;
  }

  .item-title {
    display: block;
    font-size: 1.8rem;
    font-weight: 500;
    color: $green300;
  }

  .item-description {
    font-size: 1.3rem;
    line-height: 1.23;
    font-weight: normal;
  }

}