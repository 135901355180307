.account-receipt-component {
  text-align: center;
  min-width: 56.2rem;
  margin: 0 auto;
  @media screen and (max-width: $xs-tablet) {
    min-width: auto;
    margin-bottom: 4.4rem;
  }

  .mjr-logo {
    margin-bottom: 2.5rem;
    display: inline-block;
  }

  form {
    max-width: 40rem;
    margin: 2rem auto 3rem auto;
    text-align: left;
    @media screen and (max-width: $xs-tablet) {
      margin-top: 3rem;
    }
    .valid {
      @media screen and (max-width: $xs-tablet) {
        margin-bottom: 3rem;
      }
    }
    input[type="text"] {
      margin-bottom: 0;
    }
    .input-wrapper {
      margin-bottom: 4rem;
      
      @media screen and (max-width: $sml-mobile) {
        margin-bottom: 5rem;
      }
    }
  }

  

  .triangle-link {
    color: $green300;
    font-size: 1.8rem;
    
    &::after {
      background-image: url('../img/triangle-green.svg');
    }
  }

  p {
    font-size: 1.5rem;
    margin-bottom: 2.5rem;
  }
  
  & + .account-highlight-component {
    display: none;
    @media screen and (max-width: $xs-tablet) {
    display: block;
    }
  }

  input[type="text"],
  input[type="text"]:hover,
  input[type="text"]:focus {
    -webkit-box-shadow: 0 0 0px 1000px $white inset;
  }
}