.progress-component {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 2.6rem;

  .dot {
    width: 1rem;
    height: 1rem;
    border-radius: 500px;
    color: $green600;
    background: $green600;
    border: 2px solid $green600;
    display: inline-block;
    .highcontrast & {
      border: 5px solid $green600;
    }

    &.empty {
      background: transparent;
      border: 2px solid $green800;
      .highcontrast & {
        border: 2px solid $green600;
      }
    }
  }

  .progress {
    width: 7rem;
    background: $green800;
    height: 2px;
    display: inline-block;
    .highcontrast & {
      height: 0;
      border: 2px solid $green600;
    }

    @media screen and (max-width: $xs-tablet) {
      width: 4.4rem;
    }

    .progress-bar {
      background: $green600;
      height: 2px;
      .highcontrast & {
        display: none;
      }
    }
  }

}