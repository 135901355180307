.account-password-component {
  max-width: 50rem;
  margin: 0 auto 4rem;
  @media screen and (max-width: $xs-tablet) {
    padding: 0 1.3rem;
  }

  .password-text {
    font-family: $primaryType;
    font-size: 1.5rem;
    margin-bottom: 3rem;
    display: block;
  }
  .input-group {
    margin-bottom: 4rem;
  }
  &+.account-link {
    margin-bottom: 4rem;
  }
}