.account-rewards-code-component {
  margin: 0 auto;
  text-align: center;
  background: $green1300;
  color: $black;
  padding: 3.6rem 1rem 2.5rem;
  border-radius: 2rem;
  max-width: 33rem;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  @media screen and (min-width: $xs-tablet) and (max-width: 1215px) {
    margin: 1rem auto;
  }
  &:hover {
    text-decoration: none;;
  }
  
  @media screen and (max-width: $xs-tablet) {
    max-width: 100%;
    padding: 3.6rem .5rem;
  }
  
  .reward-text {
    vertical-align: middle;
    font-size: 1.8rem;
    position: relative;
    margin-bottom: 1.1rem;
    &::before {
      content: url("../img/icons/generic-credit-card.svg");
      display: inline-block;
      margin-right: .4rem;
      width: 2.7rem;
      height: 2.2rem;
      vertical-align: middle;
    }
  }
  strong {
    font-weight: 700;
  }
}