.order-header-component {
	max-width: 55rem;
	margin: 0 auto;
	padding: 8rem 0 3rem;
	
	@media screen and (max-width: $xs-tablet) {
		padding: 0 0 1rem;
	}
	
	.order-title {
		text-align: center;
		
		@media screen and (max-width: $xs-tablet) {
			text-align: left;
		}
		
		h1 {
			font-weight: 700;
		}
	}
	
	.order-type,
	.order-address,
	.order-time {
		display: flex;
		justify-content: center;
		align-items: center;
		font-size: 1.5rem;
		font-family: $primaryType;
		color: $black;
		line-height: 1.33;
		margin-bottom: 1.5rem;
		
		@media screen and (max-width: $xs-tablet) {
			justify-content: flex-start;
		}
	}
	
	.order-type {
		display: block;
		word-break: break-word;
		.info-details {
			display: block;
			font-size: 1rem;
			margin-bottom: 0;
			margin-top: 1.5rem;
		}
		
		
		@media screen and (max-width: $xs-tablet) {
			text-align: left;
		}
		
		.selected-store-title {
			display: block;
			width: 100%;
			font-weight: 500;
			margin-top: 1.5rem;
			margin-bottom: -1.5rem;
		}
	}
	
	.order-address {
		align-items: baseline;
		text-align: center;
		
		@media screen and (max-width: $xs-tablet) {
			text-align: left;
		}
	}
	
	.triangle-link {
		font-size: 1.4rem;
		font-family: $primaryType;
		line-height: 1;
		color: $green300;
		white-space: nowrap;
		margin-left: 1rem;
		
		&::after {
			background-image: url("../img/triangle-green.svg");
		}
	}
	
	.review-order-wrapper {
		.order-title {
			margin-bottom: 2rem;
			
			h1, h2, h3, h4, h5, h6, .h1-like, .h2-like, .h3-like, .h4-like, .h5-like, .h6-like {
				font-size: 3rem;
				line-height: 1;
				font-weight: 700;
			}
		}
		
		.order-type {
			margin-bottom: 1rem;
			
			&:last-of-type {
				margin-bottom: 0;
			}
		}
	}
	
	.selected-store-wrapper {
		text-align: center;
		margin-bottom: 4rem;
		margin-top: 3rem;
		
		@media screen and (max-width: $xs-tablet) {
			text-align: left;
		}
		
		.store-title {
			margin-bottom: 1.2rem;
			
			span {
				font-size: 1.6rem;
				font-family: $primaryType;
				font-weight: 500;
				color: $black;
			}
		}
		
		.store-info {
			display: flex;
			flex-direction: column;
			
			span {
				font-size: 1.5rem;
				font-family: $primaryType;
				font-weight: 400;
				color: $black;
				line-height: 1;
				margin-bottom: .5rem;
			}
		}
	}
}
