.account-interior-structure {
  background: $white;
  position: relative;
  padding: 0 3.5rem 8rem 3.5rem;
  margin-top: 3rem;
  height: calc(100% - 3rem);
  
  &::before {
    content: '';
    display: block;
    height: 1rem;
    position: absolute;
    top: -1rem;
    background: $white;
    left: 0;
    width: 100%;
    clip-path: polygon(0% 0%, 8.71% 1.6%, 10.399% 2.17%, 11.355% 2.53%, 13.32% 3.34%, 14.952% 4.09%, 17.651% 5.4700%, 19.78705% 6.7%, 21.498288% 7.7700000%, 23.55850% 9.15%, 24.940% 10.149%, 26.224598% 11.15%, 27.920855% 12.490000%, 29.156791% 13.489%, 30.58% 14.730%, 31.726096% 15.73%, 32.827700% 16.73%, 33.979679% 17.9%, 36.0427% 20%, 37.63% 21.5499%, 38.63262% 22.43%, 39.86951% 23.43%, 41.105240% 24.430%, 43.359% 26.179%, 44.958181% 27.38%, 46.34032% 28.38%, 47.89187% 29.53%, 49.175% 30.9%, 51.11358% 33.36%, 53.563636% 37.36000000000001%, 54.86631% 40%, 56.18181% 42.800000%, 57.80374% 46.22%, 59.508770% 49.74999%, 61.41743% 53.61%, 63.13839% 56.98000%, 64.66470% 59.870000%, 66.26320% 62.779999%, 67.69262% 65.27000%, 68.97679% 67.4%, 70.69871% 70.07%, 72.29786% 72.35000%, 73.96983% 74.49%, 75.66620% 76.36999%, 77.12074% 77.72%, 78.52652406417113% 78.72%, 80.07732% 79.53%, 81.24042% 79.88000%, 82.24598% 80%, 83.32502% 79.75999%, 84.05229% 79.34%, 84.97304% 78.53%, 85.62748% 77.77000%, 86.42695% 76.62%, 87.05796% 75.54%, 88.14887% 73.31%, 89.02417% 71.16%, 89.96641% 68.46%, 90.81433% 65.64%, 91.78620% 61.89%, 92.44042% 58.989999%, 93.18909% 55.230000%, 93.72021% 52.23%, 94.67080% 45.940000%, 95.40106% 40%, 95.97754% 34.809999%, 97.25967% 23.359999%, 98.00898% 16.75%, 98.93326% 8.75%, 99.37465% 5%, 100% 0%, 100% 1910.0000%, 0% 1910.0000%, 0% 0%);
    @media screen and (max-width: $xs-tablet) {
      display: none
    }
  }
  @media screen and (max-width: $xs-tablet) {
    margin-top: 1rem;
    padding: 0 1.5rem 5rem;
    height: 100%;
  }
  .basic-spot-component {
    padding-top: 3rem;

    .highlighted {
      color: $red500;
    }

    p {
      max-width: 45rem;
      margin-left: auto;
      margin-right: auto;
    }

    img {
      width: auto;
      display: inline-block;
      margin: auto;
      max-width: none;
    }

    .info-text {
      font-size: 1.5rem;
      text-align: center;
      line-height: 1.33;
    }

    @media screen and (max-width: $xs-tablet) {
      background: $green1300;
      margin-left: calc(50% - 50vw);
      margin-right: calc(50% - 50vw);
      padding: 0 2rem 2.5rem;
      width: auto;
      position: relative;
      &::after {
        content: '';
        display: block;
        height: 1rem;
        position: absolute;
        bottom: 0;
        background: $white;
        left: 0;
        width: 100%;
        clip-path: polygon(0% 0%, 8.71% 1.6%, 10.399% 2.17%, 11.355% 2.53%, 13.32% 3.34%, 14.952% 4.09%, 17.651% 5.4700%, 19.78705% 6.7%, 21.498288% 7.7700000%, 23.55850% 9.15%, 24.940% 10.149%, 26.224598% 11.15%, 27.920855% 12.490000%, 29.156791% 13.489%, 30.58% 14.730%, 31.726096% 15.73%, 32.827700% 16.73%, 33.979679% 17.9%, 36.0427% 20%, 37.63% 21.5499%, 38.63262% 22.43%, 39.86951% 23.43%, 41.105240% 24.430%, 43.359% 26.179%, 44.958181% 27.38%, 46.34032% 28.38%, 47.89187% 29.53%, 49.175% 30.9%, 51.11358% 33.36%, 53.563636% 37.36000000000001%, 54.86631% 40%, 56.18181% 42.800000%, 57.80374% 46.22%, 59.508770% 49.74999%, 61.41743% 53.61%, 63.13839% 56.98000%, 64.66470% 59.870000%, 66.26320% 62.779999%, 67.69262% 65.27000%, 68.97679% 67.4%, 70.69871% 70.07%, 72.29786% 72.35000%, 73.96983% 74.49%, 75.66620% 76.36999%, 77.12074% 77.72%, 78.52652406417113% 78.72%, 80.07732% 79.53%, 81.24042% 79.88000%, 82.24598% 80%, 83.32502% 79.75999%, 84.05229% 79.34%, 84.97304% 78.53%, 85.62748% 77.77000%, 86.42695% 76.62%, 87.05796% 75.54%, 88.14887% 73.31%, 89.02417% 71.16%, 89.96641% 68.46%, 90.81433% 65.64%, 91.78620% 61.89%, 92.44042% 58.989999%, 93.18909% 55.230000%, 93.72021% 52.23%, 94.67080% 45.940000%, 95.40106% 40%, 95.97754% 34.809999%, 97.25967% 23.359999%, 98.00898% 16.75%, 98.93326% 8.75%, 99.37465% 5%, 100% 0%, 100% 1910.0000%, 0% 1910.0000%, 0% 0%);
      }

      .info-text {
        max-width: 30rem;
        margin: .7rem auto 0;
      }
    }
    & + .row,
    & + script + .row {
      & > .col {
        &:first-of-type {
          &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            background: url("../img/account/divider-vertical.svg");
            height: calc(100% - 2.8rem);
            width: .3rem;
  
            @media screen and (max-width: $xs-tablet) {
              content: none;
            }
          }
        }
      }
    }
    & + div,
    & + script + div {
      @media screen and (max-width: $xs-tablet) {
        padding-top: 2rem;
      }
    }
  }
  .account-rewards-migrate-component {
    + .row {
      .col {
        &:first-of-type {
          &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            background: url("../img/account/divider-vertical.svg");
            height: calc(100% - 2.8rem);
            width: .3rem;
  
            @media screen and (max-width: $xs-tablet) {
              content: none;
            }
          }
        }
      }
    }
  }
  .account-link {
    text-align: center;
    display: none;
    margin-bottom: 3rem;
    font-size: 1.8rem;
    
    @media screen and (max-width: $xs-tablet) {
      display: block;
      margin-top: 3rem;
    }
  }
  .account-progress-component {
    align-self: start;
    padding-left: 0;
  }
  .account-highlight-component {
    padding: 3.2rem;
    margin-bottom: 2.8rem;
    @media screen and (max-width: $xs-tablet) {
      margin-left: -1.5rem;
      margin-right: -1.5rem;
      width: auto;
      margin-bottom: 0;
    }
    .row {
      max-width: 61rem;
      flex-wrap: nowrap;
      margin: 0 auto;
      @media screen and (max-width: $xs-tablet) {
        display: block;
      }
    }
    &::before {
      clip-path: polygon(0% 8.33333%, 1.35132% 7.9%, 3.290173% 7.391666%, 4.99421% 7.03333%, 7.34427% 6.66666%, 10.22289% 6.35%, 12.63179% 6.21666%, 15.27583% 6.21666%, 17.27352% 6.3%, 19.97641% 6.54166%, 21.62161% 6.76666%, 22.9142% 6.98333%, 25.6169% 7.5%, 26.7920% 7.8%, 28.5549% 8.33333%, 30.78728% 8.39166%, 32.55017% 7.86666%, 34.3132% 7.23333%, 36.0752% 6.83333%, 38.25028% 7.15833%, 39.88439% 8.33333%, 42.2473% 11.45833%, 44.9470% 16.75%, 46.6509% 20.91666%, 48.4131% 25.80833%, 49.5909% 29.27500%, 51.4092% 34.75%, 52.9390% 39.2833%, 55.2313% 45.6333%, 56.9942% 50%, 58.3418% 53.025%, 59.576% 55.6583%, 61.27895% 59.0916%, 63.5691% 63.3583%, 65.3897% 66.4833%, 67.3868% 69.6416%, 69.2112% 72.2916%, 70.9146% 74.55%, 72.7945% 76.8%, 74.264% 78.3666%, 75.7923% 79.8083%, 77.9069% 81.475%, 80.6690% 82.9250%, 83.0057% 83.3333%, 85.7820% 81.2333%, 87.6040% 77.6499%, 90.193% 69.6916%, 92.251% 61.01666%, 94.1223% 51.32499%, 96.7234% 34.9%, 98.06% 25%, 98.8734% 18.425%, 100% 8.33333%, 100% 100%, 0% 100%, 0% 8.33333%)
    }
  }
  .account-progress-component {
    margin: 0;
    padding-top: 0;
    &::before {
      display: none;
    }
  }
  .left-separator {
    background: url('../img/divider-pale-blue.svg') center center repeat-x;
  }
  .triangle-link {
    color: $green300;
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 1.8rem;
    line-height: 1;
    display: inline-block;
    &:last-child {
      margin-bottom: 0;
    }
    &::after {
      background-image: url("../img/triangle-green.svg");
    }
    @media screen and (max-width: $xs-tablet) {
      padding: 0;
      display: inline-block;
    }
  }
  .submit {
    display: block;
    width: 100%;
    max-width: 30rem;
    margin: 0 auto;
  }
  .button-interior-wrapper {
    button {
      max-width: 26rem;
      width: 100%;
      display: block;
      margin: 0 auto;
      margin-bottom: 3rem;
      font-family: $primaryType;
      font-size: 1.8rem;
      &:last-child {
        margin-bottom: 0;
      }
      @media screen and (max-width: $xs-tablet) {
        max-width: 100%;
        height: 6rem;
      }
    }
  }
  .account-history-component {
    @media screen and (max-width: $xs-tablet) {
      display: none
    }
    &.mobile {
      display: none;
      @media screen and (max-width: $xs-tablet) {
        display: block;
      }
    }
  }

  .how-it-works-wrapper {
    text-align: center;
    padding: 0 8rem 13.5rem;

    @media screen and (max-width: $xs-tablet) {
      text-align: left;
      padding: 0;

      .basic-spot-component {
        text-align: left;
        padding: 0 1.5rem 2.5rem;
      }
    }

    .section-title {
      font-size: 3rem;
    }

    .title {
      margin-bottom: 2rem;
    }

    .description {
      font-size: 1.6rem;
      font-weight: 400;
      color: $blue100;
    }

    .mbottom-4 {
      margin-bottom: 4rem;
    }

    .row {
      margin-top: 2rem;
      margin-bottom: 5rem;
      margin-left: 0;
      margin-right: 0;
      padding-top: 1.2rem;
      justify-content: center;
      text-align: center;
      position: relative;

      @media screen and (max-width: $xs-tablet) {
        margin-bottom: 2.2rem;
      }

      .col {
        @media screen and (max-width: $xs-tablet) {
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          padding: 0;
        }

        @media screen and (max-width: $xs-tablet) {
          &:first-child {
            margin-bottom: 1.2rem;
            border-bottom: 1px solid $gray1150;
          }
        }
      }
    }

    .row.divider {
      border-top: 1px solid $gray1150;
      border-bottom: 1px solid $gray1150;

      @media screen and (max-width: $xs-tablet) {
        flex-direction: column;
      }

      @media screen and (max-width: $xs-tablet) {
        border-bottom: none;
      }
    }

    .info-text {
      font-size: 1.6rem;
      margin-top: 0.8rem;
      margin-bottom: 1.2rem;
    }
  }
}
