.menu-item-card-component {
  background-color: $white;
  border-radius: 1rem;
  padding: 2rem;
  margin-top: 15rem;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  position: relative;
  padding-bottom: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: stretch;
  height: calc(100% - 15rem);  

  .unavailable-text {
    display: none;
  }

  .grocery & {
    max-width: 1200px;
    margin: 15rem auto 0 auto;
    @media (max-width: 525px) {
      .card-footer {
        margin-top: 1rem;
      }
    }
  }

  @media screen and (min-width: $xs-tablet) {
    min-width: 28rem;
  }

  @media screen and (max-width: $xs-tablet) {
    padding: 1.6rem
  }

  &:hover {
    cursor: pointer;

    .details {
      color: $green300;
      text-decoration: none;
    }
  }

  &.separator {
    .card-footer {
      &:before {
        content: "";
        background-image: url("../img/divider-orange.svg");
        background-repeat: no-repeat;
        background-size: 300% 100%;
        overflow: hidden;
        height: 0.3rem;
        display: block;
        margin-bottom: 1.2rem;
        border: none;
        width: 100%;
      }
    }
  }

  img {
    margin: -13rem -2rem 1.5rem;
    max-width: calc(100% + 4rem);
    display: inline-block;
    margin-bottom: 2rem;
    max-height: 23.5rem;
  }

  .icon {
    position: absolute;
    top: -10rem;
    left: 4.6rem;
    max-width: 7.2rem;
    margin: 0;
    width: 100%;

    @media screen and (max-width: $xs-tablet) {
      max-width: 4.3rem;
      margin: 0 !important;
      top: -8rem;
      left: 5.6rem;
    }

    @media screen and (max-width: $mid-mobile) {
      max-width: 4.3rem;
      margin: 0 !important;
      top: -5rem;
      left: 2rem;
    }
  }

  .title {
    margin-bottom: 1.5rem;
    display: flex;
    justify-content: center;
    flex-direction: column;

    span {
      line-height: 0.9;
      display: block;
      max-width: 22rem;
      margin: 0 auto;

      @media screen and (max-width: $xs-tablet) {
        font-size: 2rem;
      }
    }

    .script-text {
      display: block;
      margin-bottom: .5rem;
      line-height: 1.33;
      font-size: 2.8rem;
      color: $green300;
      max-width: none;

      @media screen and (max-width: $xs-tablet) {
        font-size: 2.4rem;
      }

      &.gold-theme {
        color: $gold500;
      }
    
      &.mandarin-theme {
        color: $mandarin400;
      }

      &.blue-theme {
        color: $blue600;
      }

      &.limited {
				color: $red700;
			}
			&.back {
				color: $gold500;
			}
    }
  }
  &.whirl {
    .script-text {
      color: $mandarin400;
    }
  }

  .details-wrapper {
    font-size: 0;
    font-family: $primaryType;
    margin-bottom: 1rem;

    span {
      font-size: 1.3rem;
      font-weight: 500;
      line-height: 1.33;
      color: $black;
      display: inline-block;

      & + span {
        margin-left: .8rem;
        padding-left: .8rem;
        border-left: 2px solid $gold500;
      }
    }
  }

  .allergen-list {
    display: inline-block;
    font-family: $primaryType;
    font-size: 1.3rem;
    font-weight: 500;
    color: $red600;
    margin-bottom: .8rem;
    position: relative;
    padding-left: 1.8rem;
    vertical-align: middle;

    @media screen and (max-width: $xs-tablet) {
      margin-bottom: 0;
    }

    &::before {
      content:  url("../img/icons/alert.svg");
      display: inline-block;
      width: 1.4rem;
      height: 1.4rem;
      margin-right: .4rem;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  .ingredient-list {
    margin-bottom: 0;
    font-size: 1.3rem;

    @media screen and (max-width: $xs-tablet) {
      display: none;
    }

    span {
      &:not(:last-of-type)::after {
        content: ", ";
        color: $black;
      }
    }

    .ingredint {
      display: inline-block;
    }

    .allergen {
      color: $red600;
      position: relative;
      display: inline-block;
      padding-left: 1.4rem;

      &::before {
        content:  url("../img/icons/alert.svg");
        display: inline-block;
        width: 1.2rem;
        height: 1.2rem;
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
      }
    }
  }

  .card-body {
    margin-bottom: 1.8rem;
  }

  .card-footer {
    display: inline-flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;

    .primary-button {
      display: flex;
      justify-content: center;
      padding: 1.15rem 2.2rem;
      min-width: 0;

      a {
        font-size: 1.8rem;
      }

      @media screen and (max-width: $xs-small-tablet){
        width: 100%;
        padding: 1.8rem 1.6rem;
        font-size: 1.8rem;
      }
      
      @media screen and (max-width: 374px){
        font-size: 1.3rem;
      }

      &:after {
        color: $gold600;
        margin-top: 0.8rem;
        margin-left: 0.5rem;
      }

      &:hover {
        &:after {
          color: $black;
        }
      }

      span {
        + span {
          margin-left: .5rem;
        }
      }
    }

    .secondary-button {
      display: flex;
      justify-content: center;
      padding: 1.15rem 2.2rem !important;

      @media screen and (max-width: $xs-tablet){
        width: 100%;
        padding: 1.8rem 2.2rem;
      }
      
      &:after {
        color: $green300;
        margin-top: 0.8rem;
        margin-left: 0.5rem;
      }

      &:hover {
        &:after {
          color: $white;
        }
      }

      span {
        + span {
          margin-left: .5rem;
        }
      }
    }
  }

  .info-text {
    display: block;
    font-size: 1.3rem;
    font-family: $primaryType;
    font-weight: 500;
    line-height: 1.54;
    color: $gray900;
    margin-bottom: .6rem;

    @media screen and (max-width: $xs-tablet) {
      text-align: center;
    }
  }

  .description {
    display: block;
    color: $black;
    line-height: 1.23;
    font-size: 1.3rem;
    font-family: $primaryType;
    
    @media screen and (max-width: $xs-tablet) {
      display: none;
    }
  }

  .details {
    display: inline-block;
    margin-bottom: 1.2rem;
    font-size: 1.8rem;
    font-family: $primaryType;
    font-weight: 500;
    line-height: 1;
    border-bottom: .2rem solid transparent;

    &:hover,
    &:focus {
      color: $green300;
      text-decoration: none;
      border-bottom: .2rem solid $green300;
    }

    @media screen and (max-width: 374px) {
      font-size: 1.7rem;
    }

    &.arrow {
      &::after {
        content: none;
      }
    }
  }

  &.unavailable {
    .unavailable-text {
      font-size: 1.6rem;
      font-family: $primaryType;
      color: $black;
      display: flex;
      align-items: center;
      font-weight: 700;
      width: 100%;
      max-width: 14rem;
      min-height: 7.6rem;

      @media screen and (max-width: $xs-tablet) {
        min-height: 9.2rem;
      }
    }

    .primary-button {
      display: none;
    }

    a.details {
      display: none;
    }
  }

  &.gold-theme {
    background-color: $gold600;
  }

  &.mandarin-theme {
    background-color: $mandarin700;
  }

  &.lightBeige-theme {
    background-color: $beige600;
  }

  &.mint-theme {
    background-color: $green1000;
  }

  &.blue-theme {
    background-color: $blue600;
  }

  &.gray-theme {
    background-color: $blue900;
  }
}