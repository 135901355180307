.add-ons-modal-component {
  .modal-header {
    text-align: left;
    padding: 0 2.5rem;

    @media screen and (max-width: $xs-tablet) {
      text-align: center;
      padding: 0 5.6rem;
    }
  }
  
  .modal-title {
    font-size: 2.6rem;
  }
  
  .modal-footer {
    padding: 3.6rem;

    .primary-button {
      display: inline-block;
      margin: 0 auto;
    }
  }
}