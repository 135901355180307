.show-mobile {
  @media screen and (min-width: $small-tablet) {
    display: none !important;
  }
}

.hide-mobile {
  @media screen and (max-width: $xs-tablet) {
    display: none !important;
  }
}

.text-right {
  text-align: right !important;
}

.center-block {
  margin-left: auto !important;
  margin-right: auto !important;
}

.no-scroll {
  overflow: hidden !important;
}

.img-responsive {
  width: auto;
  max-width: 100%;
  display: block;
}

.pb-50 {
  padding-bottom: 5rem !important;
}

.pt-50 {
  padding-top: 5rem !important;
}

.pt-60 {
  padding-top: 6rem !important;
}

.py-15 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-12 {
  padding-top: 1.2rem !important;
  padding-bottom: 1.2rem !important;
}

.mt-50 {
  margin-top: 5rem !important;
}

.mt-neg-10 {
  margin-top: -1rem !important;
}

.mb-neg-10 {
  margin-bottom: -1rem !important;
}

.m0-auto { 
  margin: 0 auto;
}

.z-i-5 {
  z-index: 5 !important;
}