.unlock-reward-component {
  max-width: 32rem;
  margin: 0 auto;
  
  .modal-title {
    margin-bottom: 1.4rem;
  }

  .modal-body {
    p {
      font-size: 1.5rem;
      text-align: left;
    }
  }

  .generic-form {
    .input-wrapper {
      .form-control {
        margin-bottom: .5rem;
      }
    }
  }

  .info-label {
    font-family: $primaryType;
    font-size: 1.2rem;
    font-weight: 500;
    color: $gray900;
    text-align: left;
  }
}