.franchise-banner {
  display: flex;
  align-items: center;

  .img-wrapper {
    display: block;
    height: 100%;

    img {
      width: 100%;
    }
  }

  .info {
    padding-top: 2rem;
    padding-bottom: 2rem;
    margin-left: 10rem;
  }

  .script-text {
    font-size: 4rem;
    color: $green300;
    margin-bottom: 1.6rem;
  }

  .h2-like {
    font-weight: 700;
    font-size: 4rem;
    margin-bottom: 1rem;
    line-height: 1;
  }

  .description {
    margin-bottom: 2.6rem;
    font-size: 1.6rem;
  }

  //START themes
  &.gold-theme {
    .frame {
      border-color: rgba($gold500, 0.6);
    }
  }

  &.blue-theme {
    .frame {
      border-color: rgba($blue600, 0.6);
    }
  }

  &.mandarin-theme {
    .frame {
      border-color: rgba($mandarin700, 0.6);
    }
  }

  &.mint-theme {
    .frame {
      border-color: rgba($green1000, 0.6);
    }
  }
  //END themes


  @media screen and (max-width: $large) {
    .info {
      margin-left: 5rem;
    }

    .script-text {
      margin-bottom: 1.2rem;
    }

    .description {
      margin-bottom: 1.6rem;
    }
    
  }

  @media screen and (max-width: $xs-tablet) {
    flex-direction: column;
    justify-content: flex-start;
    padding: 2.5rem 0;
    margin: 0 -1.5rem;
    min-height: 55rem;

    .info {
      width: 32rem;
      min-height: 27.5rem;
      background-color: rgba($white, 0.5);
      padding: 1rem;
      border-radius: 5px;
      display: flex;
      justify-content: center;
      margin: -10rem 0 0;
    }

    .script-text {
      font-size: 2rem;
      margin-bottom: 0;
      line-height: 1;
    }

    .h2-like {
      font-size: 2rem;
      margin-bottom: 2.4rem;
    }

    .description {
      margin-bottom: 2rem;
      font-size: 1.4rem;
    }

    .frame {
      padding: 2rem;
      text-align: center;
      border-radius: 5px;
      border: 2px solid $green850;
      background-color: rgba($white, 0.6);
    }
  }
}