.jamba-mobile-history {
    display: none;
    
    &.section-component.wave-top {
        margin-top: -1rem;
        padding: 5.2rem 1.6rem;
        
        &::before {
            background: $blue750;
        }
    }
    
    @media screen  and (max-width: $sml-desktop) {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
        gap: 3rem;
        background: $blue750; 
        text-align: center;
        margin: 0;

        h2 {
        line-height: 2.8rem;  
        }

        a {
        font-size: 1.8rem;
        }
    }

    @media screen and (min-width: $mid-mobile) and (max-width: $sml-desktop) {
        padding: 5rem 5.6rem;
    }
}

.jamba-fcs-third-party-icon::after {
    content: "";
    background-image: url("../img/arrow-green.svg");
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    width: 2rem;
    height: 0.8rem;
    margin-left: 0.6rem;
  }