.skip {
  left:-100rem;
  position: absolute;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden;
  z-index: -999;

  @media (max-width: $small-tablet) {
    display: flex;
    width: fit-content;
    height: 2rem;
  }

  &:active,
  &:focus {
    left: 1.5rem;
    top: 1.5rem;
    width: auto;
    height: auto;
    overflow: auto;
    padding: .5rem;
    border-radius: 1.5rem;
    text-align: left;
    font-size: 1.2em;
    z-index: 999;
    text-decoration: none;

    @media screen and (max-width: $xs-tablet) {
      top: 0;
    }
  }
}

.focus-outlines {
  .breadcrumb-component {
    a {
      &:focus {
        color: $green300;
        text-decoration: underline;
      }
    }
  }

  .footer {
    .footer-primary-nav-component,
    .footer-secondary-nav-component {
      .primary-link-list,
      .secondary-link-list {
        a {
          &:focus {
            color: $green300;
          }
        }
      }
    }
  }

  .option-selection-component {
    label {
      &:focus {
        outline: 2px solid $green300;
        outline-offset: 8px;
      }
    }
  }

  .callout-image {
    a {
      &:focus {
        outline: 2px solid $green300;
        outline-offset: -4px;
      }
    }
  }

  .menu-item-card-component {
    &:focus {
      outline: 2px solid $green300;
      outline-offset: 6px;
    }

    img {
      &:focus {
        outline: 2px solid $green300;
        outline-offset: 6px;
      } 
    }
  }

  .start-order-wrapper {
    a {
      &:focus {
        outline: 2px solid $green300;
        outline-offset: 6px;
        display: inline;
      }
    }
  }

  .log-in-wrapper {
    .triangle-link  {
      &:focus {
        display: block;
      }
    }
  }

  a {
    &:focus {
      outline: 2px solid $green300;
      outline-offset: 1px;
      display: inline-block;
    }

    &.reward-button {
      &:focus {
        display: block;
      }
    }
  }

  .offers-list--item {
    &:focus {
      clip-path: none;
      outline: 2px solid $green300;
      outline-offset: 6px;
  }

  &:nth-child(n+2):not(:last-child) {
    &:focus {
      clip-path: none;
      outline: 2px solid $green300;
      outline-offset: 6px;
  }
  }
}

  .secondary-nav-component {
    a {
      &:focus {
        display: inline;
      }
    }
  }
  
  .log-in-helpers,
  .account-card-component,
  .faq-component {
    a {
      &:focus {
        display: inline;
      }
    }
  }

  .faq-component {
    a {
      &:focus {
        text-decoration: underline;
      }
    }
  }

  .card-content {
    p {
      a {
        &:focus {
          text-decoration: underline;
        }
      }
    }
  }

  .account-structure {
    .logout-button {
      &:focus {
        display: block;
      }
    }

    .triangle-link {
      &:focus {
        position: relative;
        z-index: 1;
      }
    }

    .info-link {
      &:focus {
        outline: 2px solid $white;
      }
    }
  }

  .account-favorites-component {
    .item-favorite {
      &:focus {
        outline: 2px solid $green300;
        outline-offset: 6px;
        z-index: 9;
        position: relative;
      }
    }
  }

  .account-menu-component {
    .item {
      .triangle-link  {
        &:focus {
          z-index: 1  ;
          position: relative;
          outline-offset: 10px;
        }
      }
    }
  }

  .account-highlight-component {
    .highlight-footer {
      a {
        &:focus {
          z-index: 1;
        }

        &.primary-button {
          &:focus {
            box-shadow: 0 0 0 6px $gold700, 0 0 0 8px $green300;
          }
        }
      }
    }
  }

  .basket-component {
    .basket-list {
      .favorite {
        &:focus {
          outline: 2px solid $green300; 
          outline-offset: 6px;
        }
      }
    }
  }

  .upsell {
    .item-name {
      &:focus {
        display: block;
        outline: .2rem solid $green300; 
        outline-offset: .6rem;
      }
    }
  }


  .primary-button,
  .secondary-button,
  .tertiary-button {
    &:focus {
      outline: none;
      box-shadow: 0 0 0 6px $white, 0 0 0 8px $green300;
      .highcontrast & {
        box-shadow: none;
        outline: .2rem solid $green300 !important; 
        outline-offset: .6rem !important;
      }
    }
  }
  
  input[type=email], input[type=text], input[type=password],
  .close[type=button] {
    &:focus {
      outline: 2px solid $green300;
      outline-offset: 2px;
    }
  }

  .account-messaging-component {
    .unsubscribe-item {
      input {
        &:focus {
          & + .checkmark {
            outline: 2px solid $green300;
            outline-offset: 2px;
          }
        }
      }
    }
  }

  .menu-search-component{
    .menu-search-submit {
      &:focus{
        outline: 2px solid $green300;
        outline-offset: 2px;
      }
    }
  }
  .date-time-wrapper {
    select:-moz-focusring, select::-moz-focus-inner {
      color: transparent !important;
      text-shadow: 0 0 0 $black !important;
      border:0;
    }
    select {
      &:focus{
        outline: 2px solid $green300;
        outline-offset: 5px;
      }
    }
  } 
  .location-form-component {
    input[type=text]:focus {
      outline: none;
      box-shadow: 0 0 0 6px $green1300, 0 0 0 8px $green300;
    }
    select:-moz-focusring, select::-moz-focus-inner {
      color: transparent !important;
      text-shadow: 0 0 0 $black !important;
      background-image: none !important;
      border:0;
    }
    .primary-button,
    .secondary-button,
    .tertiary-button,
    .custom-select-mobile {
      &:focus {
        outline: none;
        box-shadow: 0 0 0 6px $green1300, 0 0 0 8px $green300;
      }
    }

    .custom-select {
      &:focus {
        outline: none;
        box-shadow: 0 0 0 6px $green1300, 0 0 0 8px $green300;
      }

      li {
        position: relative;
        z-index: 3;
        &:focus {
          outline: none;
          box-shadow: 0 0 0 6px $green1300, 0 0 0 8px $green300;
          z-index: 4;
        }
      }
    }    
  }

  .location-delivery-component {
    li {
      a {
        &:focus {
          display: flex;
        }
      }
    }
  }

  .mega-menu-component, .item-slider {
      a:focus  {
      outline: 2px solid $green300;
      outline-offset: 3px;
      @media(max-width: $small-tablet){
        outline-offset: -0.3rem;
      }
    }
  }
  
  .item-slider {
    a:focus  {
      @media(max-width: 992px){
        outline-offset: -0.3rem;
      }
    }
  }


  .mega-menu-component {
    .mega-detail-cta {
      a:focus  {
        outline: none;
      }
    }
    .main-links-col {
      a {
        &:focus {
          text-decoration: underline;
          color: $green300;
        }
      }
    }
  } 

  .menu-results-filters-list {
    input {
      &:focus {
        + label {
          outline: 2px solid $green300;
          outline-offset: 6px;
        }
      }
    }
  }
  
  .mobile-drop-nav-component {
    a:focus  {
      outline: 2px solid $gold500;
      outline-offset: 3px;
    }
  }
  .nutrition-component {
    a:focus {
        outline: 2px solid $green300 !important;
        outline-offset: 6px;
        display: inline-block;
    }
  }

  .log-in-component {
    .close {
      outline: 2px solid $green300 !important;
      outline-offset: 6px;
    }
  }

  .selectric-focus {
		.selectric {
      outline: 2px solid $green300 !important;
      outline-offset: 6px;
		}
  }
  
  .checkbox-list {
    .checkbox-wrapper {
      input {
        &:focus {
          & + label {
            outline: 2px solid $green300 !important;
            outline-offset: 6px;
          }
        }
      }
    }
  }

  .modal-wrapper {
    .close {
      &:focus {
        outline: 2px solid $green300 !important;
        outline-offset: 6px;
      }
    }
  }

  .size-selection-component {
    input {
      &:focus {
        & + label {
          outline: 2px solid $green300 !important;
          outline-offset: 6px;
        }
      }
    }
  }

  .customize-modal-component,
  .customize-active-modal-component {
    .ingredient-list {
      .item {
        &:focus {
          outline: 2px solid $green300 !important;
          outline-offset: 6px;
          z-index: 1;
        }
      }
    }
  }

  .boost-component {
    .toggle,
    .info {
      &:focus {
        outline: 2px solid $green300 !important;
        outline-offset: 6px;
      }
    }
    
    .boost-details-wrapper {
      &:focus {
        outline: 2px solid $green300 !important;
        outline-offset: 6px;
      }
    }
  }
  
  button {
    &.remove-product {
            &:focus {
        outline: 2px solid $green300;
        outline-offset: 2px;
      }
    }
  }
  
  .account-payment-component {
    input[type=radio],
    input[type=radio]:checked,
    .remove-card {
      &:focus {
        outline: 2px solid $green300;
        outline-offset: 2px;
      }
    }
  }

  .category-card-component {
    .image-wrapper {
      a {
        &:focus {
          outline: 2px solid $green300;;
          outline-offset: 6px;
          display: block;
        }
      }
    }

    .card-links {
      a {
        &:focus {
          position: relative;
          z-index: 8;
        }
      }
    }
  }
  .order-location-component {
    .order-location-form-wrapper {
      .locate {
        &:focus {
          background-color: $gold500;
        }
      }
    }
    
    .location-list {
      .location-info {
        &:focus {
          outline: 2px solid $green300;
          outline-offset: 6px;
        }
        .location-name {
          &:focus {
            outline: none;
          }
        }
      }
    }
  }

  .preferred-locations-component {
    a {
      &:focus {
        display: block;
      }
    }
  }

  .dispatch-delivery-component {
    .saved-location {
      &:focus {
        outline: 2px solid $green300;
        outline-offset: 6px;
      }
    }

    .search-results {
      .result {
        &:focus {
          outline: 2px solid $green300;
          outline-offset: 6px;
        }
      }
    }
  }

  .delivery-tipping-component {
    .add-btn {
      &:focus {
        outline: 2px solid $green300;
        outline-offset: 2px;
      }
    }
  }

  .favorite-product-list-component,
  .account-locations-component {
    .item-favorite {
      &:focus {
        outline: 2px solid $green300;
        outline-offset: 4px;
      }
    }
  }

  .sign-up-component {
    .birthday {
      select {
        &:focus {
          outline: 2px solid $green300 !important;
          outline-offset: 4px;
        }
      }
    }
  }

  .delivery-info-component {
    .pick-up-interval {
      &:focus {
        display: flex;
      }
    }
  }

  .wide-card-component {
    .image-wrapper {
      &:focus {
        display: block;
      }
    }
  }

  .cpt-site-alert {
    a {
      &:focus {
        outline: 2px solid $white !important;
        outline-offset: 3px;
      }
    }
  }

  .menu-results-component {
    .menu-results-body {
      button {
        &:focus {
          outline: 2px solid $green300;
          outline-offset: 2px;
        }
      }
    }
  }

  .reward-checkout-component,
  .offer-checkout-component {
    input[type="checkbox"]:focus + label {
      outline: 2px solid $green300 !important;
      outline-offset: 0.2rem;
    }
  }

  .accordion-component,
  .offers-component {
    button {
      &:focus {
        outline: 2px solid $green300;
        outline-offset: 2px;
      }
    }
  }

  .receipt-component {
    .btn-receipt:focus {
      outline: none;
      box-shadow: 0 0 0 6px $green1300, 0 0 0 8px $green300;
    }
  }

  .cta-rewards-component {
    .quaternary-button:focus {
      outline: none;
      box-shadow: 0 0 0 6px $green1300, 0 0 0 8px $green300;
    }
  }

  .progress-radial-component {
    &:focus{
      outline: 2px solid $green300;
    }
  }
}

.highcontrast {
  .focus-outlines {
    .delivery-tipping-component {
      .tip-btn {
        &:focus {
          outline: 2px solid $green300 !important;
          outline-offset: 4px !important;
        }
      }
    }

    .location-form-component {
      .selectric-wrapper {
        li {
          &.highlighted {
            outline: 2px solid $green300 !important;
            outline-offset: 2px !important;
            position: relative;
            z-index: 10;
          }
        }
      }
    }

    .footer-primary-nav-component {
      .primary-link-list {
        a {
          &:focus {
            position: relative;
            z-index: 9;
          }
        }
      }
    }
  }

  .basket-modal-component {
    .triangle-link {
      position: relative;
      z-index: 2;
    }
  }
}

a:focus,
button:focus,
input:focus,
select:focus,
textarea:focus {
  outline: none;
} 

.reward-checkout-component {
  input[type="checkbox"]:focus + label {
    outline-offset: 0.2rem;
  }
}

.offer-checkout-component {
  input[type="checkbox"]:focus + label {
    outline-offset: 0.2rem;
  }
}
