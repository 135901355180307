.footer-secondary-nav-component {
  .secondary-link-list {
    display: flex;
    justify-content: flex-end;
    padding-left: 0;
    list-style-type: none;
    flex-wrap: wrap;

    @media screen and (max-width: $xs-tablet) {
      flex-wrap: wrap;
      justify-content: space-between;
    }

    li {
      @media screen and (max-width: $xs-tablet) {
        margin-bottom: 2.5rem; 
        width: 100%;
        max-width: 11rem;
        text-align: center;
        margin-left: 0;
      }

      @media (min-width: $sml-mobile) and (max-width: $small) {
        margin: 0 2rem;
      }

      & + li {
        margin-left: 4rem;

        @media screen and (max-width: $xs-tablet) {
          margin-bottom: 2.5rem; 
          margin-left: 0;
        }
      }

      a {
        text-decoration: underline;
        font-size: 1.2rem;
        color: $black;
        text-shadow: none !important;
        line-height: 1.5;
        display: inline-block;

        &:hover {
          color: $green300;
        }

        &.fcs-third-party:focus {
          border: none;
          outline: none;
          &:after {
            content: " ";
            border: 2px solid $green300;
            position: absolute;
            left: -0.6rem;
            right: -2.2rem; 
            top: -0.6rem;
            bottom: -0.2rem;
          }
        }

        &.cookieSettingButton{
            text-decoration: none;

            & span{
                text-decoration: underline;
            }
        }
      }
    }
  }
}

.ios9,
.ios10 {
  .footer-secondary-nav-component {
    .secondary-link-list {
      li {
        @media screen and (max-width: $xs-tablet) {
          width: 33%;
          max-width: auto;
        }
      }
    }
  }
}
