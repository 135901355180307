.account-favorite-addresses-component {
  max-width: 66rem;
  margin: 2rem auto 3rem;
  font-family: $primaryType;
  color: $black;

  .title {
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: lowercase;
    margin-bottom: 0.5rem;
  }

  ul {
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }

  .item {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0;

    &:before {
      content: '';
      background: url("../img/divider-pale-blue.svg") center center repeat-x;
      background-size: auto;
      background-size: auto;
      background-size: cover;
      display: block;
      width: 100%;
      height: .3rem;
      left: 0;
      right: 0;
      top: 0;
      position: absolute;
      z-index: 1;
    }

    &:last-child {
      &:after {
        content: '';
        background: url("../img/divider-pale-blue.svg") center center repeat-x;
        background-size: auto;
        background-size: auto;
        background-size: cover;
        display: block;
        width: 100%;
        height: .3rem;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        z-index: 1;
      }
    }
  }

  .item-wrapper {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .item-description {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 26rem;
    margin-left: 1.6rem;
  }

  .item-name {
    font-size: 1.6rem;
    font-weight: 500;
    line-height: 1.2;
  }

  .item-address {
    font-size: 1.2rem;
  }
}