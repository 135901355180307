.account-history-component {
  font-family: $primaryType;
  margin-bottom: 7rem;
  .title {
    font-size: 1.8rem;
    color: $black;
  }
  ul {
    list-style-type: none;
    padding: 0;
    li {
      display: flex;
      position: relative;
      align-items: center;
      padding: .8rem 0;
      &::after {
        content: "";
        background: url('../img/divider-pale-orange.svg') center center repeat-x;
        background-size: cover;
        position: absolute;
        bottom: 0;
        height: .3rem;
        width: 100%;
        display: block;
      }
    }
  }
  .text-wrapper {
    text-align: right;
  }
  .item-title {
    font-size: 1.5rem;
    display: block;
    line-height: 1;
    flex-basis: 100%;
  }
  .item-status {
    font-size: 1.5rem;
    display: block;
    line-height: 1;
  }
  .item-date {
    font-size: 1.2rem;
    display: block;
  }
}
