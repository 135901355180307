.connect-component {
	background-color: $beige600;
	padding: 5rem 5rem 5rem 10rem;
	font-size: 1.5rem;
	font-family: $primaryType;

	@media screen and (max-width: $xs-tablet) {
		padding: 3rem;
		width: 100vw;
		margin-left: -1.5rem;
	}

	h4, h1, h2, h3, h5, span {
		font-size: 3.2rem
	}

	.script-text {
		color:  $green300;
		font-size: 2.5rem;
		margin-right: 0.23em;
	}

	p {
    max-width: 70%;

    @media screen and (max-width: $mid-mobile) {
      max-width: 100%;
    }
	}

	a {
    margin-top: 2rem;
    cursor: pointer;
    
		@media screen and (max-width: $xs-tablet) {
			margin-top: 2.4rem;
		}
	}

	ul {
		list-style-type: none;
		margin: 1rem 0 1rem 0;
		padding-left: 0;

		li {
      margin: 0 0 1.4rem 0;
      
			a {
				color: $green300 !important; /* TODO find override and tweak it a:not([href]):not([tabindex])*/
        margin: 0 0 0 2rem;
        display: inline-block;
        text-decoration: underline !important; /* TODO find override and tweak ita:not([href]):not([tabindex])*/
        
				@media screen and (max-width:$xs-tablet) {
					margin: 0;
				}
			}
		}
  }
  
  .primary-button {
    @media screen and (max-width: $mid-mobile) {
      width: 100%;
      text-align: center;
      padding: 1.9rem 0;
    }
  }

  .connect-right {
    @media screen and (max-width: $xs-tablet) {
      margin-top: 1rem;
    }
  }

	&.hide-right {
		.connect-right {
			display: none;
		}
		.connect-left {
			text-align: center;
		}
	}
}