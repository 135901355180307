.floating-modal-component {
  .modal-dialog {
    max-width: 50rem;
    width: 100%;

    @media screen and (max-width: $xs-tablet) {
      max-width: 33.5rem;
    }
  }

  .close {
    right: 2.4rem;
  }

  .modal-content {
    background: $green1300;
    height: auto;
    margin: 3rem auto 0;
    border-radius: 0.6rem;
    box-shadow: 0 2px 5px 0 rgba($blue500, 0.6);
    padding: 3.6rem 0 0;

    @media screen and (max-width: $xs-tablet) {
      width: 100%;
    }
  }

  .modal-title {
    font-size: 2.6rem;
    margin-bottom: 2.2rem;
  }

  .modal-body {
    padding-bottom: 1.8rem;

    p {
      font-size: 1.5rem;
      max-width: 24.5rem;
      margin: 0 auto;
    }
  }

  .modal-footer {
    display: flex;
    background: $white;
    justify-content: space-between;
    padding: 1.5rem 0;

    a,
    button {
      font-size: 1.8rem;
    }

    a {
      margin-left: 4rem;

      @media screen and (max-width: $xs-tablet) {
        max-width: 11rem;
      }
    }

    .primary-button {
      max-width: 18rem;
      margin-right: 2rem;

      @media screen and (max-width: $xs-tablet) {
        min-width: 14rem;
      }
    }
  }
}
