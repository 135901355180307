.social-spot-component {
  text-align: center;
  margin-top: 4.2rem;

  @media screen and (max-width: $xs-tablet) {
    margin-top: 0;
    margin-bottom: 4.1rem;
    min-height: 10.6rem;
    flex-shrink: 0;
  }

  .script-text {
    font-size: 1.6rem;
    color: $green300;
    line-height: 1;
    display: block;
  }

  h4 {
    line-height: 1;
    margin-bottom: 2.8rem;
    font-size: 2.4rem;
  }

  .social-list {
    display: flex;
    justify-content: center;
    flex-wrap: nowrap;
    list-style-type: none;
    margin-bottom: 0;
    padding: 0;


    @media screen and (max-width: $xs-tablet) {
      margin-bottom: 0;

    }

    li {
      margin: 0 1.2rem;
      
      &:hover {
        filter: brightness(85%);
      }
    }
  }

}
