.progress-radial-component {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    &.progress-additional {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        gap: 0.5rem;
        text-align: center;
        min-width: 15rem;
    }

  .progress-container {
      position: relative;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;
      align-items: center;
      text-align: center;
      width: 14.1rem;
      height: 100%;
    
      .reward-img {
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 6.8rem;
        height: 6.8rem;
        transform: scale(0.75);
        overflow: hidden;
        border-radius: 50%;
        object-fit: contain;
      }
    
      .progress-radial {      
        $circle-size: 6.9rem;
        $circle-size-half: 3.45rem;
        $circle-background: transparent;
        $inset-size: 5.9rem;
        $inset-color: $white;
        $transition-length: 1s;
        width: $circle-size;
        height: $circle-size;
        transform: rotate(-360deg);
        position: relative;
        background-color: $white;
        border-radius: 50%;
        margin-bottom: 1.2rem;
    
        &:before {
          content: "";
          background: $circle-background;
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          border-radius: 50%;
          overflow: hidden;
        }
    
        .highcontrast & {
          background: transparent;
          
          &:before {
            content: '';
            background: transparent;
            line-height: 100%;
          }
        }
    
        .circle {
          .progress-mask,
          .fill {
            width: $circle-size;
            height: $circle-size;
            position: absolute;
            display: block;
            background: transparent;
            border-radius: 500px;
            width: 100%;
            height: 100%;
            overflow: hidden;
          }
        
          .progress-mask,
          .fill {
            -webkit-backface-visibility: hidden;
            transition: -webkit-transform $transition-length;
            transition: -ms-transform $transition-length;
            transition: transform $transition-length;
          }
          
          .progress-mask {
            clip-path: inset(0px calc(100% - #{$circle-size}) calc(100% - #{$circle-size}) #{$circle-size-half});
            overflow: hidden;
            background: transparent;
        
            .fill {
              clip-path: inset(0px calc(100% - #{$circle-size-half}) calc(100% - #{$circle-size}) 0px);
              border-radius: 50%;
              &::after {
                content: "";
                display: block;
                height: 100%;
                width: 100%;
                
                .highcontrast & {
                  content: url('../img/account/yellow-square.svg');
                  line-height: 100%;
                  position: relative;
                  background: transparent;
                  transform: scale(1.4);
                }
                .edge & {
                  height: 40rem;
                  width: 40rem;
                }
              }
            }
          }
        }
        .inset {
          width: $inset-size;
          height: $inset-size;
          position: absolute;
          margin-left: ($circle-size - $inset-size)/2;
          margin-top: ($circle-size - $inset-size)/2;
          background-color: $inset-color;
          border-radius: 50%;
        }
        $i: 1;
      @while($i <=49) {
        &[data-progress="#{$i}"] {
          .progress-mask {
            .fill {
              background: $mandarin500;
              &::after {
              background-color: $mandarin500;
              }
            }
        }
      }
        $i: $i+1;
      }

      $i: 50;
      @while($i <=99) {
        &[data-progress="#{$i}"] {
          .progress-mask {
            .fill {
              background: $gold500;
              &::after {
              background-color: $gold500;
              }
            }
        }
      }
        $i: $i+1;
      }

      $i: 100;
      @while($i == 100) {
        &[data-progress="#{$i}"] {
          .progress-mask {
            .fill {
              background: $green550;
              &::after {
                background: $green550;
              }
            }
        }
      }
        $i: $i+1;
      }

        $i: 0;
        $increment: 180deg / 100;
        @while($i <=100) {
          &[data-progress="#{$i}"] {
            .circle {
              .progress-mask.full,
              .fill {
                -webkit-transform: rotate($increment * $i);
                -ms-transform: rotate($increment * $i);
                transform: rotate($increment * $i);
              }
              .fill.fix {
                -webkit-transform: rotate($increment * $i * 2);
                -ms-transform: rotate($increment * $i * 2);
                transform: rotate($increment * $i * 2);
              }
            }
          }
          $i: $i+1;
        }
      }

      .size-grow {    
        $circle-size: 10.2rem;
        $circle-size-half: 5.1rem;
        $circle-background: transparent;
        $inset-size: 9rem;
        $inset-color: $white;
        $transition-length: 1s;
        width: $circle-size;
        height: $circle-size;
        transform: rotate(-360deg);
        position: relative;
        background-color: $white;
        border-radius: 50%;

        .triangle-link {
            min-width: 14.1rem;
        }
        .reward-img {
            width: 10.2rem;
            height: 10.2rem;
        }
    
        &:before {
          content: "";
          background: $circle-background;
          display: block;
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          border-radius: 50%;
          overflow: hidden;
        }
    
        .highcontrast & {
          background: transparent;
          
          &:before {
            content: '';
            background: transparent;
            line-height: 100%;
          }
        }
    
        .circle {
          .progress-mask,
          .fill {
            width: $circle-size;
            height: $circle-size;
            position: absolute;
            display: block;
            background: transparent;
            border-radius: 500px;
            width: 100%;
            height: 100%;
            overflow: hidden;
          }
        
          .progress-mask,
          .fill {
            -webkit-backface-visibility: hidden;
            transition: -webkit-transform $transition-length;
            transition: -ms-transform $transition-length;
            transition: transform $transition-length;
          }
          
          .progress-mask {
            clip-path: inset(0px calc(100% - #{$circle-size}) calc(100% - #{$circle-size}) #{$circle-size-half});
            overflow: hidden;
            background: transparent;
        
            .fill {
              clip-path: inset(0px calc(100% - #{$circle-size-half}) calc(100% - #{$circle-size}) 0px);
              border-radius: 50%;
              &::after {
                content: "";
                display: block;
                height: 100%;
                width: 100%;
                
                .highcontrast & {
                  content: url('../img/account/yellow-square.svg');
                  line-height: 100%;
                  position: relative;
                  background: transparent;
                  transform: scale(1.4);
                }
                .edge & {
                  height: 40rem;
                  width: 40rem;
                }
              }
            }
          }
        }
        .inset {
          width: $inset-size;
          height: $inset-size;
          position: absolute;
          margin-left: ($circle-size - $inset-size)/2;
          margin-top: ($circle-size - $inset-size)/2;
          background-color: $inset-color;
          border-radius: 50%;
        }
        $i: 1;
      @while($i <=49) {
        &[data-progress="#{$i}"] {
          .progress-mask {
            .fill {
              background: $mandarin500;
              &::after {
              background-color: $mandarin500;
              }
            }
        }
      }
        $i: $i+1;
      }

      $i: 50;
      @while($i <=99) {
        &[data-progress="#{$i}"] {
          .progress-mask {
            .fill {
              background: $gold500;
              &::after {
              background-color: $gold500;
              }
            }
        }
      }
        $i: $i+1;
      }

      $i: 100;
      @while($i == 100) {
        &[data-progress="#{$i}"] {
          .progress-mask {
            .fill {
              background: $green550;
              &::after {
                background: $green550;
              }
            }
        }
      }
        $i: $i+1;
      }

        $i: 0;
        $increment: 180deg / 100;
        @while($i <=100) {
          &[data-progress="#{$i}"] {
            .circle {
              .progress-mask.full,
              .fill {
                -webkit-transform: rotate($increment * $i);
                -ms-transform: rotate($increment * $i);
                transform: rotate($increment * $i);
              }
              .fill.fix {
                -webkit-transform: rotate($increment * $i * 2);
                -ms-transform: rotate($increment * $i * 2);
                transform: rotate($increment * $i * 2);
              }
            }
          }
          $i: $i+1;
        }
      }


  }

  .triangle-link {
    font-size: 1.5rem;
    text-align: center;

    &:hover::after {
      background-image: url('../../../img/triangle-green.svg');
    }
  }

  .required-points {
    font-family: 'Filson Soft W03', Arial, Helvetica, sans-serif;
    font-size: 1.5rem;
    font-weight: 700;
    display: flex;
    align-items: center;
    gap: .5rem;
    color: $gray700;

    img {
      display: inline-block;
      align-self: center;
      height: 1.5rem;
      width: 1.5rem;
      padding: .3rem;
      background-color: $green350;
      border-radius: 1rem;
    }
    
    &.reward-unlocked {
      color: $green350;
    }

    &.reward-locked {
      flex-direction: column;
    }
  }

  .redeem-reward {
    letter-spacing: 0.02rem;
    font-size: 1.4rem;
    color: $gray700;
    font-family: 'Filson Soft W03', Arial, Helvetica, sans-serif;

    &.reward-locked {
      font-family: 'Filson Soft W03', Arial, Helvetica, sans-serif;
      font-weight: 700;
      display: grid;
      place-items: center;
      background: $grayOp05;
      border-radius: 10rem;
      min-width: 6.5rem;
      padding-inline: 1rem;
    }
  }

}
  
  