.rewards-checkout-structure {
  margin-top: 2.8rem;

  .rewards-checkout-list {
    display: grid;
    column-gap: 0.8rem;
    row-gap: 0.832rem;
    grid-template-columns: repeat(3, 1fr);
    padding: 0.4rem;
    margin-top: 1.6rem;
    list-style-type: none;
    position: relative;
    overflow: clip;
    max-height: 22.2rem;

    @media screen and (max-width: $mobile) {
      grid-template-columns: repeat(2, 1fr);
    }

    &.bottom-gradient {
      &::before {
        bottom: 0;        
        height: 9.4rem;
        background-image: linear-gradient(to top, rgba($beige650, 0.941), rgba(246, 244, 238, 0) 180%);
        pointer-events: auto;
      }
    }
  }

  .show-accordion-rewards {
    @media screen and (min-width: $large) {
      display: flex;
      justify-content: center;
    }
  }

  .fullHeight {
    max-height: 100%;
  }

  .section-subtitle {
    margin: 0;
  }

  .visible {
    display: block;
  }

  .hidden {
    display: none;
  }
}

.ios12,
.ios13,
.ios14,
.ios15 {
  .rewards-checkout-list {
    @media screen and (max-width: $large) {
      overflow: hidden;
    }
  }
}