.favorite-product-list-component {
  max-width: 45.8rem;
  margin: 0 auto 3.5rem;
  font-family: $primaryType;
  @media screen and (max-width: $xs-tablet) {
    max-width: 100%;
  }
  .title {
    font-size: 1.5rem;
    font-weight: 700;
    @media screen and (max-width: $xs-tablet) {
      font-size: 1.8rem;
    }
  }
  ul {
    width: 100%;
    list-style-type: none;
    padding: 0;
  }
  li {
    width: 100%;
    display: flex;
    text-align: left;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding: 1rem 0;
    &::before {
      content: '';
      background: url("../img/divider-pale-blue.svg") center center repeat-x;
      background-size: auto;
      background-size: cover;
      display: block;
      width: 100%;
      height: .3rem;
      left: 0;
      right: 0;
      top: 0;
      position: absolute;
      z-index: 1;
    }
    &:last-child {
      &::after {
        content: '';
        background: url("../img/divider-pale-blue.svg") center center repeat-x;
        background-size: auto;
        background-size: cover;
        display: block;
        width: 100%;
        height: .3rem;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        z-index: 1
      }
    }
  }
  .item-wrapper {
    display: flex;
     width: 100%;
  }
  .item-image-wrapper {
    max-width: 5.8rem;
    flex-shrink: 0;
  }
  .item-img {
    width: 100%;
    margin-bottom: 1rem;
  }

  .item-description-wrapper {
    display: flex;
    flex-direction: column;
    max-width: 26rem;
    margin-left: 1.6rem;
    width: 100%;
    @media screen and (max-width: $xs-tablet) {
      max-width: 18rem;
    }
  }
  .item-name {
    font-size: 1.6rem;
    font-weight: 500;
    max-width: 20rem;
    line-height: 1.2;
  }
  .item-cal {
    font-size: 1.2rem;
    color: $green300;
    font-weight: 500;
  }
  .item-ingredients {
    font-size: 1.2rem;
  }

  .triangle-link {
    max-width: 10.4rem;
    position: relative;
    &::after {
      transform: translate(-50%, -50%);
      position: absolute;
      right: 0;
      top: 50%;
    }
  }
}

.highcontrast {
  .item-description-wrapper {
    .item-cal {
      line-height: 3.4;
    }
  }
}
