.footer-primary-nav-component {
	display: flex;
	flex-wrap: wrap;
	
	@media screen and (max-width: $xs-tablet) {
		margin-top: 3.5rem;
	}
	
	.primary-link-list {
		display: flex;
		justify-content: flex-start;
		padding-left: 0;
		margin-bottom: 0;
		list-style-type: none;
		flex-wrap: wrap;
		
		@media screen and (max-width: $xs-tablet) {
			
			text-align: center;
		}
		
		li {
			@media screen and (max-width: $xs-tablet) {
				width: 50%;
			}

			@media screen and (max-width: 230px) { 
				word-wrap: break-word;
			}
			& + li {
				margin-left: 4.5rem;
				
				@media screen and (max-width: $xs-tablet) {
					margin-bottom: 2.0rem;
					margin-left: 0;
				}
			}
			
			a {
				font-size: 1.4rem;
				color: $black;
				font-weight: 500;
				text-shadow: none !important;        
				
				&:hover {
					text-decoration: none;
					color: $green300;
				}
				
				&.fcs-third-party {
					@media screen and (max-width: $xs-tablet) {
						padding-right: 0;
					}
				}
				
				&.fcs-third-party {
					@media screen and (max-width: $xs-tablet) {
						padding-right: 0;
					}
				}
				
				&.fcs-third-party {
					@media screen and (max-width: $xs-tablet) {
						padding-right: 0;
					}
				}
				
				.fcs-third-party-icon {
					position: relative;
					display: inline;
					right: 0;
					top: -50%;
					&:after {
						position: relative;
					}
				}
				
				&.fcs-third-party:focus {
					border: none;
					outline: none;
					&:after {
						content: " ";
						border: 2px solid $green300;
						position: absolute;
						left: -0.6rem;
						right: -2.2rem;
						top: -0.6rem;
						bottom: -0.2rem;
					}
				}
			}
		}
	}
}
