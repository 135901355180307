.progress-redesign-structure {
  @media screen and (max-width: $xs-tablet) {
    padding: 0;
  }

  .rewards-progress-component {
    margin-top: 1.6rem;
    margin-bottom: 2rem;
    min-height: 40.6rem;
    padding: 3.6rem 9rem;

    @media screen and (max-width: $xs-tablet) {
      padding: 3.6rem 0;
      min-height: 38.4rem;
      margin-bottom: 0;
    }

    &::before {
      content: '';
      display: block;
      background: $green1300;
      height: 3rem;
      position: absolute;
      z-index: 2;
      top: 0;
      left: 0;
      clip-path: polygon(0% 0%, 100% 0%, 100% 52.63157894736842%, 94.447125% 57.3421052631579%, 94.3445% 57.39736842105263%, 92.95075% 57.968421052631584%, 89.064125% 60.507894736842104%, 88.554625% 61.03421052631579%, 87.6265% 62.055263157894736%, 82.99425% 68.57631578947368%, 82.80675% 68.82105263157895%, 82.20675% 69.59736842105264%, 81.150375% 70.98421052631579%, 80.87725% 71.35526315789474%, 78.992125% 74.01315789473685%, 75.861% 78.64736842105263%, 75.49975% 79.1736842105263%, 74.56725% 80.56052631578947%, 73.34525% 82.36052631578947%, 70.706375% 86.11052631578949%, 69.173625% 88.16315789473684%, 61.40599999999999% 96.39999999999999%, 60.02925% 97.39999999999999%, 54.061375% 99.8763157894737%, 53.687625% 99.92631578947369%, 51.81025% 99.98947368421052%, 50.66587500000001% 99.9%, 40.9705% 95.95263157894738%, 37.442125% 93.22631578947369%, 35.842875% 91.77105263157894%, 35.040125% 90.98157894736842%, 33.696% 89.58947368421052%, 28.99175% 83.8921052631579%, 26.978% 81.02368421052631%, 26.448624999999996% 80.23421052631579%, 25.84275% 79.2921052631579%, 25.624999999999996% 78.94736842105263%, 24.407625% 76.96315789473684%, 22.332625% 73.46052631578948%, 20.560875% 70.41578947368421%, 17.106125% 64.43684210526315%, 15.8965% 62.35000000000001%, 14.055000000000001% 59.19210526315789%, 12.7165% 56.939473684210526%, 9.5695% 51.921052631578945%, 7.313625% 49.34210526315789%, 0.176125% 39.26052631578948%, 0% 39.473684210526315%, 0% 0%);
      width: 100%;
  
      @media screen and (max-width: $xs-tablet) {
        background: $white;
        height: 1.6rem;
        top: -1.6rem;
        clip-path: polygon(0% 56.25%, 0.69085% 59.0500%, 1.82342% 63.15625%, 3.313% 67.95625%, 4.650% 71.7875%, 5.981% 75.20625%, 6.87% 77.2875%, 7.900% 79.5%, 9.309% 82.19%, 10.2790% 83.84375%, 11.691% 85.9%, 12.9523% 87.5%, 14.5028% 88.9%, 15.764% 89.5874%, 17.5441% 90.01875%, 19.101% 90.01875%, 20.436% 89.7625%, 22.734% 88.94375%, 24.5883% 88.0375%, 25.52380% 87.5%, 26.5904% 86.875%, 28.07% 85.83125%, 29.55% 84.64375%, 31.555% 82.83%, 33.332% 81.0625%, 34.7472% 79.55%, 36.8996% 77.10625%, 38.82209% 74.8%, 40.6047% 72.575%, 42.458% 70.1875%, 44.01% 68.13%, 45.86% 65.6375%, 47.868% 62.925%, 49.7832% 60.3062%, 51.359% 58.1437%, 52.987% 55.91874%, 54.85% 53.41875%, 56.173% 51.6375%, 57.798% 49.4819%, 60.0312% 46.59375%, 62.2857% 43.75%, 63.734% 41.949%, 66.106% 38.824%, 68.25% 35.80625%, 69.970% 33.3%, 71.968% 30.337%, 73.447% 28.125%, 75.149% 25.6249%, 76.195% 24.1375%, 78.57% 20.9375%, 80.3809% 18.75%, 81.9758% 16.875%, 83.9758% 14.4625%, 85.760% 12.2937%, 86.87% 10.9625%, 88.352% 9.24375%, 89.465% 7.9937%, 91.238% 6.25%, 92.5062% 5.1125%, 93.7659% 4.0625%, 94.6533% 3.3687%, 95.768% 2.55%, 97.1015% 1.64375%, 98.2899% 0.9125%, 100% 0%, 100% 100%, 0% 100%, 0% 56.25%);
      }
    }
  }
}
