.custom-slider-component {
  .custom-slider-list {
    max-width: 63.6rem;
    margin: 0 auto;
    position: relative;
  }
  
  .list-wrapper {
    margin-bottom: 3.4rem;
    display: flex;
    justify-content: flex-start;
    overflow-x: hidden;
    overflow-y: hidden;
    
    @media screen and (max-width: $xs-tablet) {
      overflow-x: auto;
      
    }
    &.right-gradient {
      &::after {
        content: "";
        position: absolute;
        right: 0;
        width: 16rem;
        max-height: 100%;
        height: 100%;
        bottom: 0;
        z-index: 1;
        pointer-events: none;
        background-image: linear-gradient(to left, #fdf9ef, rgba(255, 255, 250, 0));
      }
    }
    
    &.left-gradient {
      &::before {
        content: "";
        position: absolute;
        width: 10rem;
        height: 100%;
        bottom: 0;
        right: auto;
        left: 0;
        z-index: 1;
        pointer-events: none;
        background-image: linear-gradient(to right, #fdf9ef, rgba(255, 255, 250, 0));
      }
    }
  }

  .gradient-helper {
    display: none;

  }

  .custom-slider-item {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-width: 19rem;
    max-width: 19.7rem;
    width: 100%;
    background-color: $white;
    padding: 1.2rem 1.6rem 1.8rem;
    border-radius: .5rem;
    box-shadow: 0 0 .5rem 0 rgba($blue600, 0.4);
    
    &:last-child {
      margin-right: 0;
    }
  }

  .next,
  .prev {
    position: absolute;
    transform: translateY(-50%);
    top: 50%;
    z-index: 2;
    padding: 1rem;
    
    img {
      width: 1.5rem;
      height: 2rem;
    }
  }
  
  .next {
    right: -2rem;
  }

  .prev {
    display: none;
    left: -2rem;
    transform: rotateY(180deg) translateY(-50%);
  }
}