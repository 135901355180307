.rewards-progress-component {
  position: relative;
  background: $white;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2.5rem 3rem;

  @media screen and (max-width: $xs-tablet) {
    flex-direction: column;
    gap: 3.6rem;
  }

  .points-count {
    font-family: $primaryType;
    font-size: 10rem;
    font-weight: $font-weight-bold;
    color: $green350;
    line-height: 1;

    @media screen and (max-width: $v-small) {
      font-size: 9rem;
    }
  }

  .available-points {
    font-family: $primaryType;
    font-size: 1.8rem;
    font-weight: $font-weight-bold;
    color: $gray750;
    margin-bottom: 2rem;

    @media screen and (max-width: $xs-tablet) {
      margin-bottom: 1.2rem;
    }
  }

  .points-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .info-container {
    margin-left: 6.2rem;

    @media screen and (max-width: $xs-tablet) {
      padding: 0 3rem;
      margin-left: 0;
      width: 100%;
    }
  }

  .info-title {
    display: block;
    margin-bottom: 1.2rem;
    font-family: $primaryType;
    font-size: 2.4rem;
    font-weight: $font-weight-bold;
    color: $blue100;

    @media screen and (max-width: $xs-tablet) {
      margin-bottom: 0.4rem;
    }
  }

  .description {
    font-size: 1.6rem;
    color: $blue100;
    font-weight: $font-weight-normal;
    margin-bottom: 2.4rem;

    @media screen and (max-width: $xs-tablet) {
      font-size: 1.4rem;
      margin-bottom: 1.2rem;
    }
  }
  
  .triangle-link {
    color: $green350;
    font-size: 1.8rem;
    
    @media screen and (max-width: $xs-tablet) {
      font-size: 1.6rem;
    }

    &::after {
      background-image: url("../img/triangle-green.svg");
    }
  }

  // Modifiers 
  &.secondary-theme {
    padding: 4rem 3rem;

    @media screen and (max-width: $xs-tablet) {
      gap: 3rem;
      padding: 1rem 1.8rem;
    }

    .info-container {
      background: url("../img/dotted-green-border.png") center center no-repeat;
      background-size: 100% 100%;
      max-width: 47.6rem;
      text-align: center;
      padding: 4rem 5.8rem;

      @media screen and (max-width: $xs-tablet) {
        padding: 2.6rem 1rem;
        background-image: url("../img/dotted-green-border-mobile.png");
      }
    }
  }
}