.desktop-header-structure {
  display: block;
  max-width: 1400px;
  margin: 0 auto;
  padding: 0 1.5rem;
  padding-top: 2rem;
  transition: .2s ease padding;

  .locked & {
    padding-top: .8rem;
  }
  
  @media screen and (max-width: $xs-tablet) {
    display: none;
  }

  .fcs-container {
    position: relative;
  }

  .main-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
    transition: .2s ease all;

    .right-side {
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }
  }

  .utility-nav-component {
    transition: .2s ease all;
  }

  .site-logo-component {
    .show-scrolled {
      display: none;
    }
  }
}

.scrolled {
  .desktop-header-structure {
    
    .utility-nav-component {
      transform: translateY(-100%);
      opacity: 0;
    }

    .main-bar {
      transform: translateY(-3rem);
    }

    .site-logo-component {
      .hide-mobile {
        display: none;
      }
      .show-scrolled {
        display: block;
      }
    }
  }
}