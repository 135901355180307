.back-to-top-component {
  display: none;
  position: absolute;
  right: 2rem;
  bottom: 2.2rem;
  text-align: center;
  
  @media screen and (max-width: $xs-tablet) {
    display: block;
  }

  .back-to-top-text {
    display: block;
    font-family: $primaryType;
    font-weight: 700;
    font-size: 1.3rem;
    color: $green300;
    text-transform: uppercase;
    position: relative;
  }
}