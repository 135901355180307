.boost-component {
  .title {
    margin: 0 auto;
    text-align: center;
    font-size: 4rem;
    font-weight: 700;
    margin-bottom: 2.6rem;

    @media screen and (max-width: $xs-tablet) {
      font-size: 3rem;
    }
  }
  .subtitle {
    font-size: 2.2rem;
    line-height: 1.36;
    margin-bottom: 1rem;
    display: block;
    color: $black;
    font-family: $primaryType;
    line-height: 1.36;
  }

  .info-wrapper {
    font-size: 1.5rem;
    font-family: $primaryType;
    font-weight: 500;
    line-height: 1.33;

    span {
      width: 100%;
    }
  }

  .boost-list-wrapper {
    position: relative;

    .mobile-gradient {
      width: 4rem;
      right: -1.5rem;
      background-image: linear-gradient(to right, rgba(255, 255, 255, 0), $white);
    }
  }

  .unavailable-description {
    display: none;
  }

  .boost-list {
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    margin: 0 -0.9rem;

    @media screen and (max-width: $xs-tablet) {
      flex-wrap: nowrap;
      overflow-x: auto;
      overflow-y: hidden;
    }

    & + .subtitle {
      margin-top: 3.4rem;
    }

    .item {
      border: 2px solid $blue600;
      border-radius: 4px;
      padding: 1rem 1rem 2.2rem;
      position: relative;
      list-style: none;
      text-align: center;
      width: 16.6rem;
      margin: 0 .9rem 3rem;
      font-family: $primaryType;

      &:hover {
        cursor: pointer;
      }

      @media screen and (max-width: $xs-tablet) {
        flex-shrink: 0;
        width: 15rem;
        margin: 0 1rem 2rem 0;
      }

      &:last-child {
        @media screen and (max-width: $xs-tablet) {
          margin-right: 4rem;
        }
      }

      &.selected {
        background: $beige700;
        box-shadow: 0 0 .8rem 0 rgba($blue600, .79);

        .boost-details-wrapper {
          .circle-check {
            display: block;
          }
        }
      }

      &.unavailable {
        .unavailable-description {
          display: block;
          position: absolute;
          background-color: rgba($banner, 0.5);
          left: -0.2rem;
          top: -0.2rem;
          bottom: -2.4rem;
          right: -0.2rem;
          display: flex;
          align-items: flex-end;
          justify-content: center;
          font-size: 1.5rem;
          line-height: 1.1;
          padding-bottom: .3rem;
          border: 1px solid rgba(151, 151, 151, 0.5);
        }
      }

      .info {
        position: absolute;
        top: .5rem;
        right: .5rem;
        background: transparent;
        border: 0;
        padding: 0;
        outline: none;
        height: 1.5rem;

        img {
          height: 1.5rem;
        }
      }

      .boost-details-wrapper {
        border: 0;
        padding: 0;
        background-color: transparent;

        img {
          max-height: 5.2rem;
          max-width: 9rem;
          display: block;
          margin: 0 auto .2rem;
          cursor: pointer;
        }

        .title {
          font-size: 1.5rem;
          line-height: 1.1;
          display: block;
          margin-bottom: .6rem;
          font-weight: 400;
          color: $black;
        }

        .details-wrapper {
          font-size: 0;
  
          .allergen {
            max-width: 10.8rem;
            margin: 0 auto;
            color: $red500;
            width: 100%;
            line-height: 1.13;
            font-weight: normal;
            margin-bottom: .6rem;
            text-align: center;
  
            &:before {
              content: "";
              display: inline-block;
              background: url("../img/icons/alert.svg");
              background-size: auto;
              background-size: cover;
              width: 1rem;
              height: 1rem;
              margin-right: .24rem;
            }
          }
  
          span {
            font-size: 1.2rem;
            padding: 0 .6rem;
            line-height: 14px;
            min-width: 5.6rem;
            display: inline-block;
            color: $black;
          }
          
          .calories {
            & + .price {
              border-left: 1px solid $gold500;
            }
          } 
        }
        .circle-check {
          max-width: 1.7rem;
          margin: 0;
          display: none;
          position: absolute;
          top: .5rem;
          left: .5rem;
        }

        &.selected {
          background: inherit;
          box-shadow: none;

          .circle-check {
            display: none;
          }
        }
      }
    }
  }
}
