.account-messaging-component {
  max-width: 50rem;
  margin: 0 auto;

  @media screen and (max-width: $xs-tablet) {
    max-width: none;
  }

  strong {
    font-weight: 500;
  }

  .basic-spot-component {
    padding-bottom: 1rem;

    h4 {
      font-size: 3.6rem;
      margin-bottom: 1rem;
    }
  }

  .title {
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1;
    margin-top: 0;
  }

  .sms-notifications-wrapper {
    position: relative;
    padding: 0 2.6rem 1rem;
    margin-bottom: 1.6rem;

    @media screen and (max-width: $xs-tablet) {
      padding: 0 0 1rem;
    }

    &::after {
      content: "";
      position: absolute;
      background: url('../img/divider-pale-blue.svg');
      background-size: cover;
      width: 100%;
      height: .3rem;
      left: 0;
      right: 0;
      bottom: 0;
    }
  }

  .email-notifications-wrapper {
    padding: 0 2.6rem;

    @media screen and (max-width: $xs-tablet) {
      padding: 0;
    }
  }

  .unsubscribe-form {
    font-family: $primaryType;
    padding: 0 2.6rem;
    margin-bottom: 2.5rem;

    @media screen and (max-width: $xs-tablet) {
      padding: 0;
    }
  }

  .unsubscribe-item {
    justify-content: space-between;
    border-bottom: none;
    display: flex;
    flex-direction: row-reverse;

    &:first-of-type {
      border-top: none;
    }

    .description {
      span {
        font-size: 1.3rem;
      }
    }
  }

  .details-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 30rem;
 
    .title {
      span {
        color: $black;
      }
    }
  }

  .checkbox-container {
    position: relative;
    align-self: center;
  
    @media screen and (max-width: $xs-tablet) {
      padding: 0;      
    }
  
    input[type="checkbox"] {
      opacity: 0;
      right: auto;
      position: absolute;
    }
  
    .checkmark {
      position: relative;
      padding-left: 2.4rem;
      cursor: pointer;
      font-size: 1.6rem;
      user-select: none;
      
      &::before,
      &::after {
        content: "";
        display: inline-block;
        cursor: pointer;
        height: 2.4rem;
        width: 2.4rem;
        border: .2rem solid $green300;
        background: $white;
        border-radius: .4rem; 
        top: 50%;
        transform: translateY(-50%);
        left: 0;
        position: absolute;
      }
      
      &::after {
        background-color: $green700;
        animation: .2s scaleOut ease forwards;
        transform: translateY(-50%) scale(0);
        opacity: 0;
        background-image: none;
        content: url("../img/icons/checkmark-green.svg");
        padding: 0 0.4rem;
        line-height: 1.5rem;
      }
    }
  
    /*Adding focus styles on the outer-box of the fake checkbox*/
    input[type="checkbox"]:focus + .checkmark {
      outline: 2px solid $green300;
      outline-offset: 8px;
    }
  
    input[type="checkbox"]:checked + .checkmark {
      &::before {
        display: none;
      }
      &::after {
        animation: .2s scaleIn .05s linear forwards;
        .safari & {
          animation: none;
          transform: translateY(-50%) scale(1);
          opacity: 1;
        }
      }
    }
    
    @keyframes scaleIn {
      0% {
        transform: translateY(-50%) scale(0);
        opacity: 0;
      }
  
      50% {
        transform: translateY(-50%) scale(1.1);
      }
  
      80% {
        transform: translateY(-50%) scale(.89);
      }
  
      100% {
        transform: translateY(-50%) scale(1);
        opacity: 1;
      }
    }
  }
}
