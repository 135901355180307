.order-receipt-component {
  text-align: left;

  &.account {
    .order-main-info {
      background: $white;
      max-width: 52rem;
      padding: 2.4rem 3.6rem;
      margin-bottom: 3rem;
    }

    .order-info-component {
      .location-street,
      .location-address {
        text-decoration: none;
      }
    }

    .item-info {
      padding: 1.2rem 3rem 0;
    }

    .item-ingredients {
      max-width: 100%
    }

    .item-price {
      margin-top: auto;
      font-size: 1.3rem;
      font-weight: 500;
    }

    &.updated {
      .order-main-info {
        padding: 2.4rem 3.6rem 2rem;
        margin-bottom: 0;
        background: inherit;

        @media screen and (max-width: 500px) {
          padding: 2rem 0 2rem;
        }
      }

      .order-info-component {
        .location-address {
          @media screen and (max-width: $xs-tablet) {
            max-width: 18rem;
          }
        }
      }

      .order-contact-info {
        display: flex;
        flex-direction: column;
        background: $white;
        padding: 1.5rem 3.6rem 1.8rem;
        margin: 0 auto;
        max-width: 52rem;
        
        @media screen and (max-width: 500px) {
          padding: 1.5rem 4.2rem 1.8rem;
          margin-left: -4.3rem;
          margin-right: -4.2rem;
        }
  
        .title {
          font-family: $primaryType;
          font-size: 2.6rem;
          font-weight: 700;
          line-height: 1.15;
          color: $black;
        }
  
        .name,
        .email,
        .telephone {
          font-family: $primaryType;
          font-size: 1.5rem;
          line-height: 1.33;
          font-weight: 400;
        }
      }

      .order-location-pick {
        .location-date-time {
          white-space: nowrap;
        }
      }
    }
  }

  .modal-content {
    background-color: $beige700;
    color: $black;
  }

  .modal-header {
    h5 {
      &.modal-title {
        margin-bottom: 1rem;
      }
    }

    .order-nr {
      display: inline-block;
      font-family: $primaryType;
      font-size: 1.5rem;
      line-height: 1.33;
      margin-bottom: 2rem;
    }
  }

  .modal-body {
    max-width: 100%;

    .order-info-component {
      padding-bottom: 0;

      @media screen and (max-width: $xs-tablet) {
        background-color: transparent;
      }

      .contact-wrapper {
        padding: 1.5rem 0 1.5rem 11rem;
        margin-bottom: 2rem;
        background-color: $white;

        @media screen and (max-width: $xs-tablet) {
          text-align: left;
          padding: 1.5rem;
        }

        .title {
          display: inline-block;
          font-family: $primaryType;
          font-size: 2.6rem;
          font-weight: bold;
          line-height: 1.15;
          margin-bottom: 1rem;
        }

        .contact-details {
          display: flex;
          flex-flow: column;
          font-family: $primaryType;
          font-size: 1.5rem;
        }
      }

      .info-wrapper {
        justify-content: flex-start;
        margin-bottom: 0;
      }

      .order-location-pick {
        margin-bottom: 1rem;
      }

      .order-details-wrapper {
        .title {
          font-family: $primaryType;
          font-size: 2.6rem;
          font-weight: bold;
          line-height: 1.15;
        }

        .phone-number {
          display: inline-block;
        }
      }
    }

    .basket-structure {
      .basket-component,
      .basket-wrapper {
        background-color: transparent;
      }

      .basket-item {
        &::before,
        &::after {
          position: static;
        }

        .item-quantity {
          span {
            margin: 0 auto;
          }
        }

        .item-cal {
          color: $black;
        }
      }

      .basket-total-wrapper {
        padding-bottom: .6rem;
      }

      .credit-card {
        font-family: $primaryType;
        font-size: 1.3rem;
        font-weight: 500;
      }
    }
  }
}