.account-rewards-code-redesign-component {
  padding: 4.8rem;
  
  .title {
    &::before {
      content: url("../img/icons/generic-credit-card.svg");
      display: inline-block;
      margin-right: .4rem;
      width: 2.7rem;
      height: 2.2rem;
      vertical-align: middle;
    }
  }
}