.order-location-component {
  .info-wrapper {
    align-items: flex-end;
  }
 
  .order-location-form-wrapper {
    padding-top: 0;
    max-width: 31.5rem;
    margin: 0 auto;
    margin-bottom: 3.4rem;

    @media screen and (max-width: $xs-tablet) {
      margin-top: 1.5rem;
    }

    input {
      color: $gray700;
      font-family: $primaryType;
      font-size: 1.6rem;
      background: transparent;

      &::placeholder {
        font-weight: 400;
      }
    }

    + p {
      font-size: 1.5rem;
      max-width: 30rem;
      margin: 0 auto;
    }

    .input-wrapper {
      &:after {
        content: url('../img/icons/search-green.svg');
        display: block;
        width: 2.2rem;
        height: 2.2rem;
        position: absolute;
        right: 0;
        bottom: 0.2rem;
      }
      .error-list {
        position: absolute;
        bottom: -2rem;
        margin: 0;
        left: 0;
      }
    }
  }
  
	.primary-button {
    margin-top: 3rem;
    display: block;
    width: 100%;
    cursor: pointer;
    border: 0;
    
    @media screen and (max-width: $xs-tablet) {
      margin-top: 3rem;
      font-size: 1.8rem;
    }

    &:last-child {
      margin-top: 3.4rem;
    }
  }

  .search {
    &:after {
      content: url("../img/icons/search-yellow.svg");
      display: inline-block;
      width: 1.8rem;
      height: 1.8rem;
      margin-left: .5rem;

      @media screen and (max-width: $xs-tablet) {
        width: 2rem;
        height: 2rem;
      }
    }

    &:hover,
    &:focus {
      &:after {
        background-image: url("../img/icons/search.svg");
      }
    }
  }

  .locate {
    background: $green700;
    color: $black;

    &:after {
      content: url("../img/icons/locate.svg");
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      margin-left: .5rem;
      margin-top: -0.3rem;
      
      @media screen and (max-width: $xs-tablet) {
        width: 1.8rem;
        height: 1.8rem;
      }
    }

    &:focus {
      display: block;
    }

    &:hover {
      background: $gold500;
    }
  }
  
  .separator-wrapper {
    max-width: 34.6rem;
    margin: 2rem auto 0;
    background-image: url("../img/divider-gray.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 350%;

    span {
      font-size: 1.5rem;
      font-size: 1.5rem;
      line-height: 1.33;
      font-family: $primaryType;
      display: inline-block;
      max-width: 3rem;
      width: 100%;
    }
  }


  .order-location-helper {
    label {
      display: inline;
    }
  }

  .location-list {
    font-family: $primaryType;
    width: 100%;
    max-width: 65rem;
    margin: 0 auto;
    list-style-type: none;
    padding: 0;
  }

  .location-item {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    position: relative;
    padding-top: 1.6rem;
    padding-bottom: 1rem;
    z-index: 2;

    &:after {
      content: "";
      display: inline-block;
      background-image: url(../img/divider-gray.svg);
      overflow: hidden;
      background-size: 150% 100%;
      height: 3px;
      width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;

      @media screen and (max-width: $xs-tablet) {
        background-size: 200% 100%;
      }

      @media screen and (max-width: 414px) {
        background-size: 350% 100%;
      }
    }

    .more-wrapper {
      display: none;
      flex: 1 1 100%;
      text-align: left;
      padding-left: 5rem;

      .location-hours {
        display: flex;
        flex-wrap: wrap;
        margin: .6rem 0;

        dt,
        dd {
          font-weight: 500;
          font-size: 1.2rem;
          color: $black;
          line-height: 1.2;
          margin: 0;
        }

        dt {
          width: 9rem;
          padding-right: 2rem;
        }

        dd {
          width: calc(100% - 9rem);
        }
      }
    }
  }

  .icon-wrapper {
    border-radius: 50%;
    display: inline-block;
    width: 3.5rem;
    background: $green1300;
    height: 3.5rem;
    margin-right: 1.5rem;

    img {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      margin: 0 auto;
    }
  }

  .location-icon {
    display: flex;
    flex-direction: column;
    width: 5rem;
  } 

  .icon-info {
    font-size: 1rem;
    font-family: $primaryType !important;
    line-height: 1;
    font-weight: 500;
    margin-top: .3rem;
    color: $gray900;
    max-width: 3.5rem;
  }
  
  .location-info {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-right: 1rem;
    width: calc(100% - 10rem);

    .location-title {
      font-size: inherit;
    }
  }

  .location-adress,
  .location-name {
    font-size: 1.5rem;
    line-height: 1;
    margin-bottom: .5rem;
  }

  .location-adress {
    span {
      display: block;
    }
  }

  .location-name {
    font-weight: 700;
  }

  .location-hours {
    .status,
    .open-until {
      font-size: 1.2rem;
      font-weight: 500;
      line-height: 1.25;
    }
  
    .status {
      color: $green400;

      &.closed {
        color: $gray900;
      }
    }

    .open-until {
      &:before {
        content: "•";
        padding-right: .4rem;
      }
    }
  } 

  .location-cta {
    display: flex;
    align-items: flex-end;
    width: 4rem;
    text-align: right;
    position: relative;
    z-index: 3;

    a {
      color: $green300;
      font-size: 1.2rem;


      @media screen and (max-width: $xs-tablet) {
        font-size: 1.2rem;
        width: 4.2rem;
      }

      &:after {
        margin-left: .4rem;
        transform: rotateZ(90deg);
        width: .5rem;
        background-image: url("../img/triangle-green.svg");
      }

      &.less {
        display: none;

        &:after {
          transform: rotateZ(-90deg);
        }
      }
    }
  }

  .no-results {
    display: inline-block;
    text-align: left;
    width: 100%;
    max-width: 31rem;
    font-size: 1.5rem;
    font-family: $primaryType;
    line-height: 1.33;
    color: $black;

    @media screen and (max-width: $xs-tablet) {
      padding-top: 1.2rem;
    }
  }

  .search-location-name {
    font-weight: bold;
    color: $green300;
    word-wrap: break-word;
    word-break: break-all;
  }
}
