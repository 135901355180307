.add-card-component {
  max-width: 33rem;
  margin: 0 auto;

  #card-number-placeholder,
  #cvv-placeholder {
    height: 3rem !important;
    padding-bottom: 0.4rem;
    font-weight: 400;

    &.has-error {
      &::before {
        content: "";
        position: absolute;
        background-image: url('../img/icons/input-invalid.svg');
        background-size: cover;
        display: inline-block;
        width: 1.8rem;
        height: 1.8rem;
        margin-right: 0;
        right: 0;
      }
    }
  }

  #expirationDate {
    height: 2.6rem;
  }
  
  .input-row {
    .input-wrapper {
      border-bottom: none !important;
    }
  }

  #card-number-placeholder[aria-invalid="false"],
  #cvv-placeholder[aria-invalid="false"] {
    &::before {
      content: "";
      position: absolute;
      background-image: url('../img/icons/input-valid.svg');
      background-size: cover;
      display: inline-block;
      width: 2.4rem;
      height: 2.4rem;
      margin-right: 0;
      right: 0;
    }
  }

  .input-wrapper {
    display: inline-block;
    width: 100%;
    margin-bottom: 2rem;

    &.valid,
    &.error {
      &::after {
        content: "";
        position: absolute;
        background-size: cover;
        display: inline-block;
        width: 2.4rem;
        height: 2.4rem;
        margin-right: 0;
        right: 0;
      }
    }

    &.valid {
      &::after {
        background-image: url('../img/icons/input-valid.svg');
        width: 2.4rem;
        height: 2.4rem;
        top: 2rem;
      }
    }

    &.error {
      &::after {
        background-image: url('../img/icons/input-invalid.svg');
        width: 1.8rem;
        height: 1.8rem;
        top: 2.3rem;
      }
    }
  }

  .generic-form {
    input[type="text"] {
      background-image: none;
      margin-bottom: 0;
    }

    .input-row {
      .error-label {
        margin-top: 0;
      }
    }
  }

  .input-row {
    .input-wrapper {
      &.error {
        &::after {
          top: 2.1rem;
        }
      }

      &.valid {
        &::after {
          top: 1.8rem;
        }
      }
    }
  }
}