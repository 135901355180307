.location-careers-component {
  margin-bottom: 2.4rem;

  @media screen and (max-width: $xs-tablet) {
    padding: 0 1rem;
  }

  &:before {
    content: "";
    background-image: url("../img/divider-orange.svg");
    overflow: hidden;
    height: 0.3rem;
    display: block;
    margin: 0 -4.4rem;

    @media screen and (max-width: $xs-tablet) {
      margin: 0 0 0 -0.6rem;
    }
  }

  .title {
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: .6rem;
    margin-top: 2.6rem;

    @media screen and (max-width: $xs-tablet) {
      text-align: center;
      margin-bottom: 1rem;
    }
  }

  .content-wrapper {
    display: flex;
    align-content: space-between;
    margin-bottom: 2.6rem;

    @media screen and (max-width: $xs-tablet) {
      flex-wrap: wrap;
    }

    .text-wrapper {
      padding-right: 2rem;

      @media screen and (max-width: $xs-tablet) {
        padding-right: 0;
        text-align: center;
      }

      p {
        color: $black;
      }
    }

    .link-wrapper {
      text-align: right;
      min-width: 29rem;
      padding: 0 2.4rem 0 0;

      @media screen and (max-width: 991px) {
        min-width: 20rem;
      }

      @media screen and (max-width: $xs-tablet) {
        min-width: 0;
        margin: .6rem auto 0;
        padding: 0;
      }

      .triangle-link {
        white-space: nowrap;
        font-size: 1.8rem;

        &:after {
          background-image: url("../img/triangle-green.svg");
        }
      }
    }
  }
}