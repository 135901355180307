.menu-detail-hero {
	display: flex;
	padding-left: 7.6rem;
	@media (max-width: $xs-tablet) {
		flex-direction: column;
    padding: 0;
	}

	.unavailable-text {
		display: none;
	}

	.image-wrapper {
		width: 56%;
		padding-top: 2rem;
		@media (max-width: $xs-tablet) {
			order: 1;
		}

		img {
			object-fit: cover;
			object-position: center;
			width: auto;
		}
  }
  
	.special-category {
		position: relative;
		@media screen and (max-width: $xs-tablet) {
			text-align: center;
		}

    img {
      width: 100%;
      max-width: 5rem;
      margin-right: 1.5rem;

      @media screen and (max-width: $xs-tablet) {
        max-width: 4.5rem;
        margin-right: .5rem;
      }
    }

	.script-text {
      font-size: 3.2rem;
      vertical-align: middle;
      color: $green300;

	    &.mandarin-theme {
				color: $mandarin400;
			}
	    &.whirl {
				color: $mandarin400;
			}
			&.limited {
				color: $red700;
			}
			&.back {
				color: $gold500;
			}
		}
	}

	.content-wrapper {
		width: auto;
		padding: 4.6rem 2rem 2rem;
		background: {
			size: cover;
		}

		@media (max-width: $xs-tablet) {
			order: 2;
      padding: 0;
      text-align: center;
      margin-bottom: 2rem;
		}


		header {
			margin-top: 1rem;
			@media screen and (max-width: $xs-tablet) {
		  		text-align: center;
		  	}
			h1,
			h2,
			h3,
			h4,
			h5,
			h6,
			span
			{
				text-transform: lowercase;
        font-family: $primaryType;
        font-weight: 500;
			}
			sup {
				text-transform: uppercase;
        font-family: $primaryType;
        font-weight: 500;
			}
		}

		header * {
			font-family: $primaryType;
			font-size: 5rem;
			line-height: 1;
			display: block;
			letter-spacing: normal;
			font-weight: 400;
			@media (max-width: $xs-tablet) {
				font-size: 3.8rem;
				line-height: 41px;
			}
		}

		.subtitle {
			font-size: 1.8rem;
			line-height: 24px;
			font-family: $primaryType;
      font-weight: 500;
			display: block;

			&::after {
				content: "";
				border-right: 2px solid $gold500;
				margin: 0 1.6rem;
			}

			@media (max-width: $xs-tablet) {
				padding-right: 0;
				margin: 0;
			}
		}

		p {
			font-size: 1.5rem;
			padding-right: 5rem;
			//max-height: 100px;
			overflow: hidden;
			@media (max-width: $xs-tablet) {
				padding-right: 0;
				margin-top: .2rem;
			}
		}

		a {
			margin-top: 3.2rem;
			@media (max-width: $xs-tablet) {
				margin-bottom: 0.4rem;
				width: 100%;
        text-align: center;
        max-width: 30rem;
        padding: 1.8rem 2.2rem;
      }
      @media (max-width: $mid-mobile) {
        max-width: 100%;
			}

			&.add-order {
				margin-top: 2.6rem;
				margin-bottom: 2.6rem;
			}
		}
  }
  
	.ingredients-box {
		@media screen and (max-width: $xs-tablet) {
      text-align: center;
		}
		
		.subtitle {
			&::after {
				content: none;
			}
		}

    p {
			color: $black;
			margin-bottom: 0;
			
			&.toppings,
			&.ingredients {
				strong {
					font-weight: 500;
				}

				span {
					display: inline-block;
					margin-right: .4rem;
					&:after {
						content: ", ";
						color: $black;
					}

					&:last-of-type {
						margin-right: 0;
						&:after {
							display: none;
						}
					}
				}
			}
		}
		
		.allergen {
			color: $red500;
	
			&:before {
				content: url("../img/icons/alert.svg");
				display: inline-block;
				width: 1.4rem;
				height: 1.4rem;
				margin-right: .4rem;
        vertical-align: middle;
        line-height: 1;
			}

			&:after {
				content: ", ";
				color: $black;
			}
		}
	}

	.start-order-wrapper {
		background-color: $green1300;
		text-align: center;
		margin-top: 2rem;
		padding: 3rem 0 2rem;
		position: relative;

		.bg-helper {
			&::before {
				content: "";
				display: block;
				width: 100%;
				margin: 0 auto;
				height: 2vw;
				position: absolute;
				background-color: $white;
				top: 0;
				left: 0;
				right: 0;
				z-index: 2;
				clip-path: polygon(0% 0%, 100% 0%, 100% 12.5%, 97.87087% 13.0125%, 96.53252% 13.425%, 94.34296% 14.2%, 92.15218% 15.0875%, 90.08301% 16.0125%, 88.01553% 17.025%, 85.64345% 18.275%, 83.14976% 19.7375%, 80.77767% 21.2375%, 77.91044% 23.2%, 75.48544% 25%, 73.96529% 26.25%, 71.77063% 28.3625%, 69.5835% 30.7125%, 67.58544% 33%, 64.82233% 36.25%, 61.35316% 40.2625%, 58.49976% 43.3375%, 55.29587% 46.35%, 52.1784% 48.6625%, 49.51456% 50%, 47.99539% 50.5375%, 45.94757% 51.175%, 41.94078% 52.125%, 40.51553% 52.375%, 37.6665% 52.75%, 35.35146% 52.9375%, 32.94733% 53.0375%, 30.18714% 53.0375%, 27.60485% 52.925%, 24.84466% 52.7%, 22.88568% 52.5%, 20.48277% 52.1625%, 18.07767% 51.75%, 15.67354% 51.25%, 13.4466% 50.8625%, 10.59733% 50.275%, 9.2233% 50%, 6.68277% 48.75%, 4.71456% 46.775%, 1.91383% 42.2375%, 0.99951% 40.2%, 0% 37.5%, 0% 0%);
			}
		}
		
		a,
		span {
			font-family: $primaryType;
			font-size: 1.8rem;
		}

		a {
			display: inline;
		}

		span {
			display: block;
		}
	}
  
	.price-box {
		margin-top: 1rem;
		display: flex;
		color: $black;

		@media screen and (max-width: $xs-tablet) {
			justify-content: center;
			margin-top: 1.7rem;
		}

		+ .allergens-list {
			margin-top: 1rem;
		}

		p {
			color: $black;
			font-size: 1.8rem;
			font-weight: 500;
			margin: .1rem 0 0;
		}
	}

	@media (max-width: $xs-tablet) {
		max-height: none;
		margin-top: 0;
		min-height: 0;
		margin-bottom: 1.5%;

		.image-wrapper,
		.content-wrapper {
			width: 100%;
		}
  }
  
	@media screen and (max-width: $xs-tablet) {
		.fcs-third-party.secondary-button .fcs-third-party-icon {
      right: 8.5rem;
	  }
	}

	//grocery detail additions
	.detail-link-box {
		margin-top: 3rem;

		div {
			width: 50%;
			float:  left;
		}


		.subtitle {
			margin-top: 3rem;

			@media (max-width: $xs-tablet) {
				margin-bottom: 3rem;
			}
		}

		a {
			color: $black;
			float: left;
			clear: both;
			margin-bottom: 0.5rem;

			@media (max-width: $xs-tablet) {
				margin-top: 0;
				padding: 0.5rem 0rem;
			}

			&:after {
				content: "";
				background-image: url("../img/triangle.svg");
				background-size: contain;
				background-repeat: no-repeat;
				display: inline-block;
				width: 0.7rem;
				height: 0.8rem;
				margin-left: .5rem;
			}
			&.fcs-third-party:after {
				content: '';
				background-image: none;
			}
			.fcs-third-party-icon {
				position: relative;
				transform: translateY(-150%);
				display: inline;
				&:after {
					transform: translateY(-95%);
				}
			}
		}
	}

	.size-selection {
		margin-top: 2.2rem;
		@media screen and (min-width: $small-tablet) and (max-width: 991px) {
			display: flex;
			align-items: flex-end;
			justify-content: space-between;
		}

		label {
			display: inline-block;
			margin-right: 5.4rem;
			text-align: center;
			cursor: pointer;

			@media screen and (min-width: $small-tablet) and (max-width: 991px) {
				margin-right: 0;
			}

			&:last-of-type {
				margin-right: 0;
			}

			.text {
				font-size: 1.8rem;
				color: $black;
				font-family: $primaryType;
				font-weight: 700;
				width: 3.5rem;
				height: 3.5rem;
				border: 3px solid $green300;
				border-radius: 500px;
				text-align: center;
				display: block;
				user-select: none;
			}
		}

		.size {
			margin: 0;
			padding: 0;
			opacity: 0;
			position: absolute;

			&:checked {
				& + label {
					.text {
						background: $green300;
						color: $white;
					}
				}
			}

			& + label {
				.icon {
					&:before {
						display: block;
						margin-bottom: 1.2rem;
					}
				}
			}

			&.s {
				& + label {
					.icon {
						&:before {
							content: url("../img/icons/sizes/sm.svg");
						}
					}
				}

				&:checked {
					& + label {
						.icon {
							&:before {
								content: url("../img/icons/sizes/sm-green.svg");
							}
						}
					}
				}
			}

			&.m {
				& + label {
					.icon {
						&:before {
							content: url("../img/icons/sizes/med.svg");
						}
					}
				}

				&:checked {
					& + label {
						.icon {
							&:before {
								content: url("../img/icons/sizes/med-green.svg");
							}
						}
					}
				}
			}

			&.l {
				& + label {
					.icon {
						&:before {
							content: url("../img/icons/sizes/lg.svg");
						}
					}
				}

				&:checked {
					& + label {
						.icon {
							&:before {
								content: url("../img/icons/sizes/lg-green.svg");
							}
						}
					}
				}
			}

			&.xl {
				& + label {
					.icon {
						&:before {
							content: url("../img/icons/sizes/xl.svg");
						}
					}
				}

				&:checked {
					& + label {
						.icon {
							&:before {
								content: url("../img/icons/sizes/xl-green.svg");
							}
						}
					}
				}
			}
		}
	}

	.allergens-list {
		color: $red500;
		font-size: 1.3rem;
		font-weight: 500;
		font-family: $primaryType;
		margin-bottom: 2.2rem;
    display: block;

		&:before {
			content: url("../img/icons/alert.svg");
			display: inline-block;
			width: 1.4rem;
			height: 1.4rem;
			margin-right: .4rem;
			vertical-align: middle;
		}

		& + a.add-order {
			margin-top: 1.2rem;
		}
	}

	.checkbox {
		margin-top: 2.4rem;
		label {
			display: inline;
			padding-left: 0;
			padding-right: 3rem;
			font-size: 1.8rem;
			color: $black;
			font-family: $primaryType;

			&:before,
			&:after {
				left: auto;
				right: 0;
			}

			img,
			svg {
				max-width: 1.6rem;
				margin-right: .5rem;
			}
		}
	}

	&.unavailable {
		.content-wrapper {
			a {
				&.primary-button {
					display: none;
				}
			}

			.unavailable-text {
				display: block;
				font-size: 1.6rem;
				font-family: $primaryType;
				color: $black;
				font-weight: 700;
				margin: 2.6rem 0;
			}
		}
	}
}

.edge {
	.menu-detail-hero {
		.start-order-wrapper {
			padding-top: 2rem;

			.bg-helper {
				&:before {
					content: none;
				}
			}
		}
	}
}
