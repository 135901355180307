.button-link-selection-component {
  text-align: center;
  margin-top: 8rem;
  margin-bottom: .2rem;

  @media screen and (max-width: $xs-tablet) {
      margin-top: 1rem;
  }

  .link-list {
    padding: 0;
    margin: 0;
    .link-item {
      display: inline-block;
      margin: 2.2rem;
      min-width: 190px;
      & a {
        width: 100%;
      }
      @media screen and (max-width: $xs-tablet) {
        margin: auto;
        width: 100%;
      }
    }
  }

  .link-separator {
    display: inline-block;
    font-size: 1.5rem;

    @media screen and (max-width: $xs-tablet) {
      display: block;
      margin: 2rem 0 2rem 0;
    }
  }

  .primary-button {
    font-family: $primaryType;
    font-weight: 700;
    padding: 1.2rem 7.9rem 1.2rem 6.8rem;
    &:after {
    content: none;
    }
    @media screen and (max-width: $xs-tablet) {
      font-size: 1.8rem;
      padding: 1.8rem 7.9rem 1.8rem 6.8rem;
    }
  }

  .fcs-third-party .fcs-third-party-icon:after {
    content: none !important;
  }

  .fcs-third-party .link-text-wrap:after {
      content: " \e900" !important;
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      background-image: none !important;
      font-size: 1.2rem;
      margin-left: .6rem;
      top: 52%;
      position: absolute;
      transform: translateY(-50%) translateY(0.1rem);
  
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
  }

  .section-component + &  {
    margin-top: 3rem;
  }
}
