.account-contact-component {
  max-width: 50rem;
  margin: 0 auto;
  .generic-form-component {
    margin-bottom: 4rem;
  }
  .read-only {
    font-family: $primaryType;
    margin-bottom:1.8rem;
    span {
      display: block;
    }
  }
  .input-wrapper input[type="text"],
  .input-wrapper input[type="text"]::placeholder {
    font-weight: 400;
  }
  .input-group {
    margin-bottom: 5rem;
    .input-wrapper {
      &:before {
        content: '';
        display: none;
        position: absolute; 
        right: 0;
        top: 2rem;
        display: block;
        z-index: 1;
      }
      &.error {
        .form-control {
          margin: 0;
        }
        &::before {
          display: block;
          content: url("../img/icons/input-invalid.svg");
          top: 2.5rem;
          right: .4rem;
        }
      }
      &.valid:before {
        display: block;
        content: url("../img/icons/input-valid.svg");
     }
     .error-list {
       li {
         margin-bottom: 1rem;
         &:before {
          top: .5rem;
           background: transparent;
           content: url("../img/icons/input-invalid.svg");
           transform: scale(.7);
         }
       }
     }
    }
  }
  .contact-label {
    margin-bottom: .8rem;
    font-size: 1rem;
 
    &+span{
      font-size: 1.5rem;
    }
  }
  .contact-info {
    font-size: 1.2rem;
    font-family: $primaryType;
    font-weight: 500;
    color: $gray700;
    a {
      font-size: 1.2rem;
      font-weight: 700;
    }
  }
  &+.account-link {
    margin-bottom: 4rem!important;
  }

  input,
  input:hover,
  input:focus {
    -webkit-box-shadow: 0 0 0px 1000px $white inset;
  }
}