.newsroom-header-component {
  text-align: center;
  text-transform: lowercase;
  margin: 0 auto;

  & > div {
    margin: 0 auto;
  }
  
  .title {
    line-height: 1.13;
    
    h1,
    h2 {
      font-size: 4.8rem;
      margin-top: 5rem;
      margin-bottom: 2rem;

      @media screen and (max-width: $xs-tablet) {
        font-size: 3.8rem;
        line-height: 1.18;
        margin-top: 3rem;
        margin-bottom: 1.8rem;
      }
    }
  }

  .subtitle {
    line-height: 0.91;

    h2 {
      font-size: 2.2rem;
      color: $green300;
      margin-bottom: 4.5rem;

      @media screen and (max-width: $xs-tablet) {
        font-size: 2rem;
        line-height: 1.1;
      }
    }
  }

  .date {
    display: flex;
    flex-direction: row;
    justify-content: center;
    position: relative;
    margin-bottom: 3.5rem;
    white-space: nowrap;

    @media screen and (max-width: $xs-tablet) {
      margin-bottom: 4.3rem;
    }

    &:before {
      content: "";
      background-image: url('../img/underline.svg');
      background-position: center;
      background-size: contain;
      display: inline-block;
      width: 21.2rem;
      height: 1rem;
      position: absolute;
      top: -1.8rem;
      left: 50%;
      transform: translateX(-50%);
    }

    span {
      font-family: $primaryType;
      color: $black;
      font-size: 1.4rem;
      font-weight: 500;
      line-height: 1.43;

      @media screen and (max-width: $xs-tablet) {
        font-size: 1.2rem;
        line-height: 1.67;
      }

      & + span {
        margin-left: 1.5rem;

        &:before {
          content: "|";
          color: $green300;
          margin-right: 1.5rem;
        }
      }
    }
  }
}