.footer {
  margin-top: 5rem;

  @media screen and (max-width: $xs-tablet) {
    margin-top: 0;
  }

  .primary-footer-structure {
    margin-bottom: 9rem;
  }
  
  .basic-spot-component {
  	padding: 0 1.5rem 0 1.5rem;
    margin-top: 4.2rem;
    max-width: 33%;

    @media screen and (max-width: $xs-tablet) {
      max-width: 100%;
      margin-bottom: 4.5rem !important;
    }

  	h4 {
  		font-size: 2.4rem;
    }
    
  	img {
      @media screen and (max-width: $xs-tablet) {
        position: relative;
      }
    }
    .script-text {
      font-size: 1.6rem;
    }
  }
  .script-text {
    @media screen and (max-width: $xs-tablet) {
      padding-top: 0 !important;
    }
  }

  .section-component {
    padding: 3rem 0;
  }
}