.dietary-preferences-component {
  text-align: center;
  font-family: $primaryType;

  .modal-header,
  .modal-body {
    max-width: 76rem;
  }

  .modal-content {
    .modal-body {
      p {
        font-size: 1.2rem;
      }
    }
  }

  .modal-title {
    @media screen and (max-width: $xs-tablet) {
      line-height: 1;
      font-size: 3rem;
    }
  }
   
	.primary-button {
    margin: 0 auto 3rem;
    display: block;
    cursor: pointer;
    border: 0;
    width: auto;

    @media screen and (max-width: $xs-tablet) {
      font-size: 1.8rem;
      width: 100%;
      max-width: 31.5rem;
    }

    &.account-dietary-button {
      margin-top: 4rem;
      @media screen and (max-width: $xs-tablet) {
        margin-top: 6rem;
      }
    }
  }

  .note {
    font-size: 1.2rem !important;
    line-height: 1.17;
    color: $gray900;
    max-width: 63rem;
    margin: 0 auto 3rem;
    font-weight: 500;
    text-align: left;
  }

  a {
    font-size: 1.8rem;
    display: inline-block;

    @media screen and (max-width: $xs-tablet) {
      margin-bottom: 4rem;
    }
  }

  .checkbox-list {
    margin-bottom: 3.8rem;
    
    @media screen and (max-width: $xs-tablet) {
      margin: 0 -2rem 2rem;
      padding-left: 1rem;
      padding-right: 1rem;
      justify-content: flex-start;
    }
    
    .checkbox-wrapper {
      margin: 1rem 2rem;
      @media screen and (max-width: $xs-tablet) {
        margin: 1rem;
        flex-basis: calc(50% - 2rem);
      }
    }
  }
}
