.account-payment-component {
  max-width: 50rem;
  margin: 0 auto;

  .title-wrapper {
    display: flex;
    margin-bottom: 3rem;
    align-items: center;
  }
  
  .preferred-card {
    margin-left: auto;
    position: relative;
    font-size: 1.5rem;
    color: $black;
    vertical-align: middle;
    font-family: $primaryType;
    &::before {
      content: url("../img/icons/fav-fill.svg");
      display: block;
      position: absolute;
      left: -2rem;
      pointer-events: none; 
      top: 50%;
      height: 100%;
      transform: translateY(-50%);
    }
  }
  
  .title {
    font-size: 1.8rem;
    line-height: 1;
    font-weight: 500;
    margin: 0;
  }

  .card-list {
    margin-bottom: 3rem;

    &.gift-cards {
      .card-details {
        &::before {
          content: none;
        }

        .card-logo {
          margin-left: 0;
        }
      }
      .balance-remove-wrapper {
        .card-balance {
          font-family: $primaryType;
          font-size: 1.8rem;
          font-weight: 500;
          line-height: 1;
          vertical-align: middle;
        }

        button {
          &.remove-card {
            background: transparent;
            border: 0;
            width: 2rem;
            height: 2rem;
            position: relative;
            vertical-align: middle;
            margin-left: .4rem;
            &:after {
              content: url('../img/icons/delete.svg');
              display: block;
              position: absolute;
              right: 0;
              top: 0;
            }
          }
        }
      }
    }
  }

  .card-logo-wrapper {
    border-radius: .34rem;
    text-align: center;
  }

  .card-wrapper {
    display: flex;
    justify-content: space-between;
    flex-direction: row;
    align-items: center;
    padding: 0 2.4rem 1.6rem;
    position: relative;
    margin-bottom: 2rem;

    &::after {
      content: '';
      background: url("../img/divider-pale-blue.svg") center center repeat-x;
      background-size: cover;
      display: block;
      width: 100%;
      height: .3rem;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
    }
  }

  .card-details,
  label {
    display: flex;
    align-items: center;
  }
  
  .card-details {
    &::before {
      content: url("../img/icons/fav.svg");
      display: block;
      position: absolute;
      pointer-events: none;
      line-height: 1;
      top:0;
    }
    &.checked::before {
      content: url("../img/icons/fav-fill.svg");
    }
  }

  .card-details,
  input[type=radio],
  label {
    &:hover {
      cursor: pointer;
    }
  }

  button {
    &.remove-card {
      background: transparent;
      border: 0;
      width: 2rem;
      height: 2rem;
      position: relative;
      vertical-align: middle;
      margin-left: .4rem;
      &:after {
        content: url('../img/icons/delete.svg');
        display: block;
        position: absolute;
        right: 0;
        top: 0;
      }
    }
  }
  .balance-remove-wrapper {
    margin-left: auto;
  }
  .card-logo {
    margin: 0 2rem;
    max-width: 2.7rem;
  }

  .card-number,
  .card-balance {
    font-family: $primaryType;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1;
    vertical-align: middle;
  }



  input[type=radio] {
    appearance: none;
    width: 1.9rem;
    height: 2.2rem;
    display: block;
    margin: 0 auto;
    height: 2rem;
    z-index: 2;
  }
}
