$iconSpacing: 1.5rem;

.fcs-third-party {
  position: relative;
  padding-right: $iconSpacing;
  
  .fcs-third-party-icon {
    font-size: 0;
    position: absolute;
    top: 50%;
    height: 1rem;
    display: block;
    width: 1rem;
    transform: translateY(-50%) translateY(.2rem);
    right: 0;

    &:after {
      content: " \e900" !important;
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: 'icomoon' !important;
      speak: none;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      background-image: none !important;
      font-size: 1rem;
      left: 0;
      top: 50%;
      position: absolute;
      transform: translateY(-50%);
  
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

  &.secondary-button {
    padding-right: calc(2.2rem + #{$iconSpacing});
    
    .fcs-third-party-icon {
      right: 2.2rem;
      @media screen and (max-width: $xs-tablet) {
        right: 1rem;  
      }
    }
  }
}

.main-nav-component {
  a {
    &.fcs-third-party {
      &:before {
        top: calc(50% - 0.3rem);
      }
    }
  }
}

.mobile-drop-nav-component {
  .mobile-drop-nav-container {
    ul {
      li {
        a {
          &.fcs-third-party {
            &:hover,
            &.fcs-nav-active {
              .fcs-third-party-icon {
                &::after {
                  @media screen and(max-width: $xs-tablet) {
                    color: $gold500;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.location-list-component {
  .location-delivery {
    a {
      &.fcs-third-party {
        &:first-of-type {
          @media screen and (max-width: $xs-tablet) {
            margin-right: 1rem;
          }
        }

        span {
          &:first-of-type {
            &::after {
              display: none;
            }
          }
        }

        .fcs-third-party-icon {
          transform: none;
          width: auto;
          height: auto;
          top: auto;
          bottom: .6rem;
          right: .2rem;
          font-size: 0;

          &::after {
            position: static;
            transform: none;
          }
        }
      }
    }

    &.show-mobile {
      flex-direction: row;
    }
  }
}

.fluid-card-set-component,
.menu-detail-hero,
.secondary-img-hero-component {
  .secondary-button {
    &.fcs-third-party {
      @media screen and (max-width: $mid-mobile) {
        display: flex;
        justify-content: center;
      }
    }

    .fcs-third-party-icon {
      right: 2.2rem;


      
      @media screen and (max-width: $mid-mobile) {
        position: relative;
        left: .6rem;
        right: 0;
        top: -0.1rem !important;
        transform: none;

        &:after {
          position: relative;
          left: 0;
          right: 0;
          top: 0;
          transform: none;
        }
      }
    }
  }
}

iframe[src^="https://acuityplatform.com"],
img[src^="https://secure.adnxs.com"],
img[src^="https://imp.control.kochava.com"] {
  position: absolute;
  bottom: 0;
}

.webkit {
	@media screen and (max-width: $mid-mobile) {
    .fcs-third-party.secondary-button {
      align-items: center;
    }
  }
}

@media screen and(max-width: $xs-tablet) {
  .footer .fcs-third-party .fcs-third-party-icon {
    &:after {
      left: 0 !important;
      right: 0.3rem;
    }
  }
}

.mobile-header-structure {
  .main-bar {
    .secondary-button {
      &.fcs-third-party {
        .fcs-third-party-icon {
          top: 50% !important;
        }
      }
    }
  }
}

.chrome,
.safari {
  .header {
    .main-nav-component {
      .fcs-third-party {
        .fcs-third-party-icon {
          transform: translateY(-50%);
  
          @media screen and(max-width: $xs-tablet) {
            transform: translateY(-50%) translateY(0.2rem);
          }
        }
      }
    }
  }
}
