.testimonial-component {
  margin: 0 auto;
  padding: 2.4rem 1.5rem 5.7rem 1.5rem;
  max-width: 92rem;

  @media screen and (max-width: $xs-small-tablet) {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: flex-end;
    padding: 3rem 1.5rem 4rem;
  }
  
  img {
    display: block;
    width: 100%;
    max-width: 20.8rem;
    float: left;
    margin-bottom: 4rem;
    margin-right: 1rem;
    
    @media screen and (max-width: $xs-small-tablet) {
      width: 37%;
      margin-bottom: 0;
      margin-right: 0;
    }

    & + .title {
      padding-left: 3rem;
    }

    & + .title + blockquote {
      padding-left: 3rem;
      @media (max-width: $xs-tablet) {
        padding-left: 0;
      }
    }
  }
  
  blockquote {
    font-family: $primaryType;
    font-size: 3.5rem;
    color: $black;
    line-height: 1.14;
    margin: 0;
    overflow: hidden;
    margin-top: 4rem;
    max-width: 61.4rem;

    @media screen and (max-width: $xs-small-tablet) {
      clear: both;
      padding-left: 0;
    }

    &::before {
      content: '"';
    }

    &::after {
      content: '”';
    }
  }

  .title {
    display: flex;
    flex-direction: column;
    font-size: 3rem;
    font-family: $primaryType;
    font-weight: 500;
    color: $black;
    line-height: 1;

    @media screen and (max-width: $xs-small-tablet) {
      width: 63%;
      font-size: 2.2rem;
    }

    .script-text {
      color: $green300;
      font-family: $primaryType;
      line-height: 1;
      margin-right: 0.23em;
    }
  }

  p {
    margin-top: 4rem;
    color: $black;
    clear: both;

    @media screen and (max-width: $xs-small-tablet) {
      margin-top: 3rem;
    }
  }
}