.sign-up-component {
  text-align: center;
  font-family: $primaryType;
  width: 33rem;
  margin: 0 auto;

  @media screen and (max-width: $xs-tablet) {
    width: 100%;
    padding: 0 2.8rem;
  }

  &.error,
  &.success {
    margin-bottom: 6.4rem;

    @media screen and (max-width: $xs-tablet) {
      margin-bottom: 3.2rem;
    }

    .title {
      font-size: 3.2rem;
      margin-bottom: 2rem;
    }

    p {
      margin-bottom: 1.6rem;
    }

    .error-info {
      display: block;
      font-weight: 500;
      font-size: 1.5rem;
      margin-bottom: 3.4rem;
      line-height: 1;

      @media screen and (max-width: $xs-tablet) {
        margin-bottom: 2.4rem;
      }
    }

    img {
      margin-bottom: 4rem;
      max-width: 27rem;

      @media screen and (max-width: $xs-tablet) {
        margin-bottom: 2.6rem;
      }
    }

    button:not(.close) {
      margin: 0 auto 2.4rem;
      padding: 1rem 3.4rem 1.1rem;
      
      @media screen and (max-width: $xs-tablet) {
        font-size: 1.8rem;
        padding: 1.6rem 3.4rem;
        max-width: none;
      }

      @media screen and (max-width: $sml-mobile) {
        font-size: 1.4rem;
      }
    }
  }

  &.success {
    width: 55rem;
    margin-bottom: 5rem;

    @media screen and (max-width: $xs-tablet) {
      width: 100%;
      margin-bottom: 3.4rem;
    }
    .form-show {
      max-width: 31.6rem;
      margin: 0 auto;
    }
    .section-component {
      margin: 4rem auto;
      padding: 0;

      @media screen and (max-width: $xs-tablet) {
        margin: 5rem -2.8rem 3.4rem;
      }
    }
    button[data-ref=search] {
      max-width: none;
    }
    .input-wrapper {
      &:after {
        content: url('../img/icons/search-green.svg');
        display: block;
        width: 2.2rem;
        height: 2.2rem;
        position: absolute;
        right: 0;
        bottom: 0.2rem;
      }
    }

    a {
      font-size: 1.8rem;
    }
  }

  .title {
    font-weight: 700;
    margin: 4rem 0 1.6rem;
    line-height: 1;
  }

  p {
    color: $black;
  }

  .checkbox input[type="checkbox"]:checked{
    &+label::after {
      background-image: none;
      content:  url("../img/icons/checkmark-green.svg");
      padding: 0 .4rem;
      line-height: 1.5rem;
    } 
  }
 
  .sign-up-helpers {
    text-align: left;
    padding-top: 1rem;
    margin-bottom: 2.6rem;

    .checkbox {
      display: block;
      margin-bottom: 1.9rem;

      & + .note {
        margin-top: -0.6rem;
      }

      &:last-of-type {
        margin-bottom: 0;

        input {
          &:focus {
            + label {
              + .error-label {
                display: inline-block;
                margin-top: 1rem;
              }
            }
          }
        }
      }

      label {
        padding-left: 3.6rem;
        font-size: 1.5rem;
        line-height: 1.33;
      }
    }
  }

  .note {
    display: block;
    font-size: 1.2rem;
    font-weight: 500;
    line-height: 1.17;
    color: $gray900;
    margin-bottom: 1.2rem;
    text-align: left;

    .fcs-third-party {
      display: inline-block;
    }
  }

  .placeholder {
    font-weight: 400;
    font-size: 1rem;
  }

  .input-wrapper {
    margin-bottom: 1.6rem;
    &.error {
      margin-bottom: 2.4rem;
    }
    &:before {
      content: '';
      display: none;
      position: absolute; 
      z-index: 1;
      right: 0;
      top: 2rem;
      display: block;
    }
    &.error:before {
      display: block;
      content :url("../img/icons/input-invalid.svg");
      top: 2.5rem;
      right: .4rem;
    }
    &.valid:before {
      display: block;
      content :url("../img/icons/input-valid.svg");
   }

    input {
      font-weight: 400;
      margin-bottom: 0;

      &::placeholder {
        font-weight: 400;
      }
    }

    .note {
      margin: 1rem 0 0;
    }

    .error-label {
      &.password-error {
        text-align: left;
      }
    }
  }
  
  .input-group .input-wrapper:nth-child(5){
    &.error {
      margin-bottom: 1rem
    }

    .error-label {
      position: relative;
    }
  }

  .input-group .input-wrapper:nth-child(5){
    &.error {
      margin-bottom: 1rem
    }

    .error-label {
      position: relative;
    }
  }

  .recaptcha-disclaimer {
    padding-top: 3rem;

    .note {
      margin: 0;
    }

    a {
      font-size: 1.2rem;
      font-weight: 600;

      .fcs-third-party-icon {
          right: .4rem;
      }
    }
  }
  
  .backend-validation-block {
    .error {
      color: $red600;
      font-weight: 500;
      font-family: $primaryType;
    }
  }

  .separator-wrapper {
    max-width: 34.6rem;
    margin: 2rem auto 1rem;
    background-image: url("../img/divider-gray.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 350%;

    span {
      font-size: 1.5rem;
      font-size: 1.5rem;
      line-height: 1.33;
      font-family: $primaryType;
    }
  }

  .log-in-form-wrapper {
    padding-top: 0;

    @media screen and (max-width: $xs-tablet) {
      margin-top: 1.5rem;
    }
  }
  
	.primary-button {
    margin-top: 2rem;
    display: block;
    width: 100%;
    cursor: pointer;
    border: 0;

    @media screen and (max-width: $xs-tablet) {
      margin-top: 4rem;
      font-size: 1.6rem;
    } 

    &.facebook {
      @media screen and (max-width: $xs-tablet) {
        padding: 1.55rem 4rem 1.55rem 1rem;
        margin-top: 2rem;
      }
    }

    &.guest {
      background: $green700;
      color: $black;
      margin-top: 2rem;
    }
  }

  .info {
    font-size: 1.5rem;
    color: $black;
    line-height: 1;
    padding: 0 3rem;
    display: inline-block;
    margin-top: .6rem;

    @media screen and (max-width: $xs-tablet) {
      padding: 0 2rem;
    }

    a {
      font-size: 1.8rem;
      margin-left: 1rem;
      line-height: 1;
    }
  }

  .birthday {
    @media screen and (max-width: $xs-tablet) {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      justify-content: center;
    }

    .placeholder {
      opacity: 1;
      font-weight: 400;
      font-size: 1.4rem;
      display: block;
      height: 3rem;

      @media screen and (max-width: $xs-tablet) {
        width: 100%;
      }
    }

    select {
      width: 14rem;
      border: none;
      -moz-appearance: none;
      -webkit-appearance: none;
      appearance: none;
      background-color: $green300;
      border-radius: 3.5rem;
      background-image: url("../img/drop.svg");
      background-position: calc(100% - 0.8rem) center;
      background-repeat: no-repeat;
      background-size: 1rem;
      text-align: center;
      text-align-last: center;
      font-family: $primaryType;
      font-size: 1.4rem;
      font-weight: bold;
      color: $white;
      vertical-align: middle;
      padding: 1.0rem;

      @media screen and (max-width: $xs-tablet) {
        max-width: 12rem;
        width: 100%;
      }

      &:focus,
      &:active {
        outline: 0 !important;
      }

      &::-ms-expand {
        display: none;
      }

      &:first-of-type,
      &:last-of-type {
        width: 8.4rem;

        @media screen and (max-width: $xs-tablet) {
          max-width: 8.4rem;
          width: 100%;
        }

        @media screen and (max-width: 374px) {
          max-width: 7.4rem;
        }
      }

      + select {
        margin-left: .8rem;
      }
    }
  }

  input[type="password"],
  input[type="password"]:hover,
  input[type="password"]:focus,
  input[type="email"],
  input[type="email"]:hover,
  input[type="email"]:focus,
  input[type="text"],
  input[type="text"]:hover,
  input[type="text"]:focus {
    -webkit-box-shadow: 0 0 0px 1000px $white inset;
  }
}

.success-show, .fail-show, .already-in-show {
  display: none;
  background-color: $cream;

  @media screen and (max-width: $xs-tablet) {
    width: 100%;
    height: auto;
  }

  img {
    max-height: 15.9rem;
    margin-top: 1rem;
    margin-bottom: 1rem;

    @media screen and (max-width: $xs-tablet) {
      margin-bottom: 5rem;
      margin-top: 2rem;
    }
  }

  .message-title {
    font-family: $primaryType;
    font-weight: 500;
    font-size: 3.2rem;
    color: $green300;
  }

  .primary-button {
    @media screen and (max-width: $xs-tablet) {
      margin-top: 3.8rem;
    }
  }
}
.sign-up-instructions {
  margin-left: -10.0rem;
  margin-right: -10.0rem;
  @media screen and (max-width: $xs-tablet) {
    margin-left: 0;
    margin-right: 0;
  }
}
#completeCaptchaBeforeSubmit {
  margin: 1.5rem 0 1.5rem -1.0rem
}
