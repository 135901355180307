//main colors

$white:           #ffffff;
$cream:   		    #fffdf4;
$black:           #2b3936;

$gold900:         #fdf9e8;
$gold800:         #fcf7e0;
$gold700:         #f7ebb1;
$gold600:         #fce29d;
$gold500:         #fac53c;

$blue900:         #f6f9f9;
$blue800:         #f1ebe4;
$blue750:         #ECF4F3;
$blue700:         #ecf4f2;
$blue600:         #c0d9d4;
$blue500:         #99afac;
$blue300:         #6B7673;
$blue100:         #2C3937;

$beige700:        #fdf9ef;
$beige650:        #F6F4EE;
$beige600:	      #fcf4f1;
$beige500:        #eedbb0;


$mandarin800:     #fbefea;
$mandarin700:     #f8e4dc;
$mandarin600:     #f2caba;
$mandarin550:     #e4a891;
$mandarin500:     #f5804b; 
$mandarin450:     #e6804b;
$mandarin400:     #cd4b0f;

$green1400:       #dfe5df;
$green1300:       #edf4f3;
$green1200:       #ecf4da;
$green1100:		    #ecf4d9;
$green1000:       #eaf3d6;
$green900:        #d2eae5;
$green850:        #dee8ac;
$green800:        #c3e0b6;
$green700:        #d5e8ad;
$green600:        #b3d343;
$green550:        #B1D461;
$green500:        #74c04c;
$green400:        #468425;
$green350:        #117159;
$green300:        #036d58;
$green250:        #006C5B;
$green200:        #287201;
$green100:        #225B01;
$green50:         #4c4d4c;

$gray1200:        #eeeeee;
$gray1150:        #E5E4E0;
$gray1100:        #c2bbb3;
$gray1000:        #787878;
$gray900:         #67726f;    
$gray800:         #5c6765;
$gray750:         #595a5a;
$gray700:	  	    #595a59;
$gray600:         #454545;
$gray500:         #363636;
$gray400:         #323232;

$grayOp05:        #cccccc80;

$red700:          #da6259;
$red600:          #dd2106;
$red500:          #e20000;
$red400:          #892942; 

$inactive:        #bcc7c5; 
$facebook:        #3b5998;
$disabled:        #6a6a6a;
$banner:          #d8d8d8;

//main fonts

$primaryType: Filson Soft W03, Arial, Helvetica, sans-serif;
$secondaryType: Charleston, Arial, Helvetica, sans-serif;

/* Widths */
$xlarge: 1440px;
$large: 1280px;
$mid: 959px;
$small: 690px;

/* Breakpoints */
$desktop: 1439px;
$mid-desktop: 1400px;
$sml-desktop: 850px;
$small-tablet: 769px;
$xs-tablet: 768px;
$xs-small-tablet: 767px;
$tablet: 641px;
$mobile: 640px;
$mid-mobile: 425px;
$sml-mobile: 420px;
$v-small: 350px;

/* Columns */
$col1: 5.128%;
$col2: 11.452%;
$col3: 17.777%;
$col4: 24.102%;
$col5: 30.427%;
$col6: 36.752%;
$col7: 43.076%;
$col8: 49.401%;
$col9: 55.726%;
$col10: 62.051%;
$col11: 68.376%;
$col12: 74.7%;
$col13: 81.025%;
$col14: 87.35%;
$col15: 93.675%;
$col16: 100%;
$col-margin:  1.197%;
