.delivery-tipping-component {
  font-family: $primaryType;
  font-size: 1.5rem;
  line-height: 1.2;
  margin-top: 2rem;
  color: $black;
  margin-bottom: 2rem;

  .subtotal-tax-wrapper,
  .discount-tax-wrapper,
  .delivery-tax-wrapper,
  .extra-tax-wrapper,
  .tip-tax-wrapper,
  .tax-fees-wrapper{
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
  }

  .group-title {
    margin-top: 3rem;
    margin-bottom: 1rem;
  }

  .cta-group {
    display: flex;
    justify-content: center;
    justify-content: space-between;
    margin-bottom: 2rem;
  }

  .other-wrapper {
    display: flex;
    align-items: center;
    justify-content: flex-end; 
    margin-bottom: 1rem;
  }

  .tip-btn {
    border-radius: 2rem;
    border: .2rem solid $green300;
    background: $white;
    width: 100%;
    max-width: 10rem;
    padding: 0.6rem 1rem;
    font-weight: 400;
    color: $black;

    &:hover,
    &:focus,
    &:active {
      background: $green300;
      color: $white;
    }

    &.active {
      background: $green300;
      color: $white;
    }
  }

  .value-wrapper {
    display: flex;
    flex-direction: column;
    font-weight: 500;
    width: 100%;
    max-width: 10rem;
  }

  .value {
    margin-bottom: 0;
    color: $black;
    font-weight: 500;
    
    &::placeholder {
      font-weight: 500;
      color: $black;
    }
  }

  .info {
    border: none;
    margin-bottom: 0;
    font-size: 1.5rem;
    font-weight: 500;
    color: $black;
  }

  .add-btn {
    display: flex;
    margin-left: 4rem;
    margin-right: 4rem;
    background: none;
    border: none;
    color: $green300;
    font-weight: 500;
    font-size: 1.8rem;

    &:after {
      content: url("../img/icons/plus-green.svg");
      color: $green300;
      display: inline-block;
      width: 2rem;
      height: 2.2rem;
      margin-left: 1rem;
    }
  }

  .total-tax-wrapper {
    font-weight: 900;
    display: flex;
    justify-content: space-between;
    font-size: 1.6rem;
  }
}

.highcontrast {
  .delivery-tipping-component {
    .tip-btn {
      border-radius: 0;
      background-color: inherit;
      
      &:focus,
      &:active,
      &.active {
        background-color: $green300 !important;
        outline: 2px solid $green300 !important;
        outline-offset: 4px !important;
      }
    }
  }
}
