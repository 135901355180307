.location-delivery-component {
  margin-bottom: 3.2rem;

  @media screen and (max-width: $xs-tablet) {
    padding: 0 0 0 1.6rem;
  }

  &:before {
    content: "";
    background-image: url("../img/divider-orange.svg");
    overflow: hidden;
    height: 0.3rem;
    display: block;
    margin: 0 -4.4rem;

    @media screen and (max-width: $xs-tablet) {
      margin: 0 .6rem 0 -1rem;
    }
  }

  .title {
    margin-top: 3.2rem;
    font-size: 2.4rem;
    font-weight: 700;

    @media screen and (max-width: $xs-tablet) {
      text-align: center;
      margin-top: 2rem;
    }
  }

  ul {
    padding-left: 0;
    list-style: none;
    margin: 1.6rem 0 2.6rem;

    @media screen and (max-width: $xs-tablet) {
      margin-top: .2rem;
      display: flex;
      flex-wrap: wrap;
    }

    li {
      display: inline-block;
      margin-right: 2.2rem;
      margin-top: 1.4rem;

      @media screen and (max-width: $xs-tablet) {
        width: 50%;
        margin-right: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      a {
        display: flex;
        align-items: center;
        font-size: 1.8rem;
        color: $black;
        font-weight: 700;

        @media screen and (max-width: $xs-tablet) {
          display: inline-flex;
        }

        &:hover,
        &:focus {
          text-shadow: none;
        }
        &.disabled {
          pointer-events: none;

          img {
            opacity: .4;
          }

          span {
            color: $blue800;
          }
        }

        .image-wrapper {
          margin-right: .6rem;

          @media screen and (max-width: $xs-tablet) {
            width: 3.4rem;
          }

          img {
            max-height: 2.7rem;
            max-width: 3.4rem;
          }
        }
      }
    }
  }
}

.col-xs-12,
.col-md-10,
.col-lg-8 {
  & > div:first-child {
    &:before {
      display: none;
    }

    .title {
      margin-top: 0;
    }
  }
}