.card-error-component {
  max-width: 32rem;
  margin: 0 auto;

  .modal-header {
    max-width: none;
  }

  .img-wrapper {
    margin: 4.8rem 0;
  }

  p {
    color: $black;
    margin-bottom: 2.4rem;
  }
}