.location-list-component {
  margin-top: 1.5rem;
  margin-bottom: 3rem;
  font-family: $primaryType;
  width: 100%;

  .location-item {
    position: relative;
    padding-top: 2.7rem;
    padding-bottom: 1.7rem;

    &:before,
    &:last-child:after {
      content: '';
      background-image: url(../img/divider-gray.svg);
      overflow: hidden;
      background-size: cover;
      height: 3px;
      width: 100%;
      display: inline-block;
      position: absolute;
      top: 0;
    }

    &:last-child:after {
      top: auto;
      bottom: 0;
    }
    
    .main-row {
      display: flex;
      justify-content: space-between;

      @media screen and (max-width: $xs-tablet) {
        flex-direction: column;
        justify-content: flex-start;
      }
    }
  }

  .info {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
  }

  .location-data-wrapper {
    display: flex;
    flex-direction: column;

    @media screen and (max-width: $xs-tablet) {
      width: calc(100% - 5.5rem);
    }
  }
  
  .location-icon {
    text-align: center;
    display: flex;
    flex-direction: column;
    margin-right: 2rem;
  }

  .icon-wrapper {
    border-radius: 50%;
    display: inline-block;
    width: 3.5rem;
    background: $green1300;
    height: 3.5rem;

    @media screen and (max-width: $xs-tablet) {
      width: 2.8rem;
      height: 2.8rem;
    }
    
    img {
      position: relative;
      top: 50%;
      transform: translateY(-50%);
      margin: 0 auto;
    }
  }
  
  .icon-info {
    font-size: .9rem;
    font-family: $primaryType !important;
    line-height: 1;
    font-weight: bold;
    margin-top: .2rem;
    color: $gray900;
    max-width: 3.5rem;
  }
  
  .result-name {
    font-size: 2.2rem;
    color: $black;
    font-weight: bolder;
    line-height: 1;
    text-decoration: none;
    margin-bottom: .6rem;

    &::after {
      content: "";
      background-image: url("../img/triangle.svg");
      background-size: contain;
      background-repeat: no-repeat;
      display: inline-block;
      width: 0.7rem;
      height: 0.9rem;
      margin-left: 1rem;
      vertical-align: middle;
    }
  }

  .property-list {
    list-style-type: none;
    padding: 0;

    li {
      display: inline-block;
      font-size: 1.3rem;
      font-family: $secondaryType;
      line-height: 1;
      color: $gray500;

      &:not(:last-of-type)::after {
        content: "•";
        padding: 0 .8rem;
      }
    }
  }

  .location-hours {
    line-height: 1.25;
    font-size: 1.2rem;
    font-weight: 500;
    margin-bottom: 1rem;

    .status {
      &.open {
        color: $green400;
      }

      &.closed {
        color: $black;
      }
    }

    .open-until {
      color: $gray700;

      &::before {
        content: "•";
        padding-right: .4rem;
      }
    }
  }

  .hours {
    color: $black;
    font-weight: 500;
    margin-bottom: 1rem;
  }

  .location-adress {
    font-size: 1.5rem;
    line-height: 1.33;
    color: $black;
    margin-bottom: .6rem;
    max-width: 20rem;
  }

  .location-phone {
    font-size: 1.5rem;
    line-height: 1.33;
    color: $black;
    max-width: 20rem;
    .desktop-phone {
      @media screen and (max-width: $xs-tablet) {
        display: none;
      }
    }
    .mobile-phone {
      display: none;
      @media screen and (max-width: $xs-tablet) {
        display: inline;
        font-weight: 400;
      }
    }
  }

  .location-delivery {
    list-style-type: none;
    display: flex;
    flex-wrap: wrap;
    margin-top: 2rem;
    
    li {
      margin-left: 1.8rem;
      display: flex;

      a {
        text-decoration: none;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        img {
          max-width: 3.4rem;
          max-height: 2.7rem;
        }

        span {
          font-size: 1.8rem;
          color: $black;
          font-weight: bold;

          &::after {
            content: "";
            background-image: url("../img/triangle.svg");
            background-size: contain;
            background-repeat: no-repeat;
            display: inline-block;
            width: 0.7rem;
            height: 0.9rem;
            margin-left: .5rem;
            vertical-align: middle;
          }
        }
      }
    }

    &.show-mobile {
      flex-basis: 100%;

      @media screen and (max-width: $xs-tablet) {
        flex-basis: auto;
        display: flex;
        flex-flow: column;
        flex-wrap: wrap;
        padding: 0;
        align-content: space-between;
      }

      li {
        @media screen and (max-width: $xs-tablet) {
          margin: 0 0 2.4rem;

          &:nth-child(1) {
            order: 1;
          }

          &:nth-child(2) {
            order: 3;
          }

          &:nth-child(3) {
            order: 2;
          }
          
          &:nth-child(4) {
            order: 4;
          }
        }

        .service-logo {
          @media screen and (max-width: $xs-tablet) {
            width: 3.4rem;
            display: inline-block;
            margin-right: .4rem;
            text-align: center;
          }
        }
      }
    }
  }

  .cta-wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-self: flex-start;
    margin-top: 1.8rem;

    a {
      margin-bottom: 1.5rem;
    }

    @media screen and (max-width: $xs-tablet) {
      width: 100%;

      a {
        display: block;
        width: 100%;
        padding: 1.95rem 3.4rem;
        margin-bottom: 2.5rem;
      }
    }
  }
}
