.darken-body {
  background: #3d3d3d;
  width: 100%;
  height: 0;
  z-index: 6;
  position: fixed;
  top: 0;
  left: 0;
  display: none;
  transition: all 4000ms ease;
  clear: both;
  opacity: 0;
  animation: .2s ease darkenFade forwards;
  @keyframes darkenFade {
    0% {
      opacity: 0;
    }
    100% {
      opacity: .4;
    }
  }
}

.shown {
  display: block;
  height: 100%;
  transition: all 4000ms ease;
}

.chrome {
  .shown {
    transition: initial !important;
  }

  .darken-body {
    transition: initial !important;
  }
}