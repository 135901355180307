.item-slider {
  display: flex;
  width: 100%;
  margin-right: -1.5rem;
  margin-left: -1.5rem;

  .item-slider-wrapper {
    max-width: 100%;
    max-height: 20rem;
    padding: 0;
    margin: 2.5rem auto 2.5rem auto;
    display: flex;
    text-align: center;
    -webkit-overflow-scrolling: touch;
    min-height: 140px;
    justify-content: center;
    &.has-scroll {
      justify-content: flex-start;
    }
    @media(max-width: $xs-tablet) {
      padding-left: 2.6rem;
      margin-bottom: 0;
      justify-content: flex-start;
    }
  
    @media(max-width: 1300px){
      overflow-x: scroll;
      overflow-y: hidden;
      min-height: 170px;
    }
  
    .android & {
      @media (max-width: $xs-tablet) {
        justify-content: left;
        & a:first-child {
          margin-left: 3.5rem !important;
        }
      }
    }
    a {
      flex-shrink: 0;
      border-bottom: none !important;
    
      @media(max-width: $small-tablet){
        margin-right: 8vw;
      }
  
      &:hover,
      &:focus {
        cursor: pointer; 
        .text-descriptor { 
          color: #000;
          text-decoration: underline; 
        }
        .mask {
          img {
            display: block;
            margin-top: -10rem;
          }
        }
      }
  
      &:focus-visible {
        outline: .2rem solid $green300;
        outline-offset: .3rem;
  
        @media(max-width: $small-tablet){
          outline-offset: -0.3rem;
        }
      }
    }
  
  
  .mask {
    display: inline;
    width: 120px;
    height: 120px;
    float: left;
    -webkit-clip-path: inset(5% 20% 15% 10%);
    clip-path: inset(5% 20% 15% 10%);
  }
  
  .category {
    background-image: none;
    background-repeat: no-repeat;
    display: block;
    min-width: 100px;
    height: 100px;
    text-align: center;
    background-position-x: -14px;
  }

  .icon-Iced {
    .mask {
      img {
        display: block;
        margin-left: 3.4rem;
      }
    }
  }

  .icon-Smoothies {
    .mask {
      img {
        display: block;
        margin-left: -6rem;
      }
    } 
  }
  
  .icon-Bowls {  
    .mask {
      img {
        display: block;
        margin-left: -25.4rem;
      }
    } 
  }
  
  .icon-Shots {  
    .mask {
      img {
        display: block;
        margin-left: -45.2rem;
      }
    } 
  }
  
  .icon-Juices {  
    .mask {
      img {
        display: block;
        margin-left: -56.2rem;
      }
    } 
  }
  
  .icon-Bites { 
    .mask {
      img {
        display: block;
        margin-left: -35.2rem;
      }
    } 
  }
  
  .icon-Kids {  
    .mask {
      img {
        display: block;
        margin-left: -66.2rem;
      }
    }  
  }

  .icon-Bundles { 
    .mask {
      img {
        display: block;
        margin-left: -15.5rem;
      }
    } 
  }
    .text-descriptor { 
        display: block;
        width: 110px;
        font-family: $primaryType;
        font-size: 1.6rem;
        color: $black;
        letter-spacing: 0.2px;
    }
  }
}

.section-component {
  .fcs-container {
    .item-slider {
      .item-slider-wrapper {
        a {
          &:active:focus {
            outline: none;
          }
        }
      }
    }
  }
}

.edge {
  .item-slider {
    .item-slider-wrapper {
      width: 100%;
      padding-top: 1rem;
      padding-bottom: 1.5rem;
      .mask {
        width: 110px;
      }
    }
  }

  .mask,
  .category {
    overflow: hidden;
  }

}

.item-slider {
  &.right-gradient {
    &::after {
      content: "";
      transition: opacity 0.2s;
      opacity: 1;
      position: absolute;
      width: 25%;
      height: 90%;
      bottom: 0;
      right: 0;
      z-index: 1;
      pointer-events: none;
      background: -moz-linear-gradient(left, rgba($white,0) 20%, rgba($white,1) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba($white,0) 20%,rgba($white,1) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba($white,0) 20%,rgba($white,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
  
      @media(min-width: $small-tablet) {
        display: none;
      }
    }
  }
  
  &.left-gradient {
    &::before {
      content: "";
      transition: opacity 0.2s;
      opacity: 1;
      position: absolute;
      width: 25%;
      height: 90%;
      bottom: 0;
      right: auto;
      left: 0;
      z-index: 1;
      pointer-events: none;
      background: -moz-linear-gradient(left, rgba($white,1)6%, rgba($white,0) 100%); /* FF3.6-15 */
      background: -webkit-linear-gradient(left, rgba($white,1) 6%, rgba($white,0) 100%); /* Chrome10-25,Safari5.1-6 */
      background: linear-gradient(to right, rgba($white,1) 6%, rgba($white,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
      filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
  
      @media(min-width: $small-tablet) {
        display: none;
      }
    }
  }
}
