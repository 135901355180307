.promo-banner-component, .alert-wrapper {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  z-index: 400;
  background: $white;
  color: $black;
  font-family: $primaryType;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 1.2rem 1.2rem;
  box-shadow: 0 .1rem .3rem 0 $blue600;

  @media screen and (max-width: $xs-tablet) {
    padding: 1.1rem 1.2rem;
  }

  .reward-image {
    width: 100%;
    max-width: 2.4rem;

    @media screen and (max-width: $xs-tablet) {
      max-width: 1.7rem;
    }
  }

  .promo-info {
    color: $black;
    font-size: 2rem;
    font-weight: 700;
    margin-left: 2.16rem;
    text-align: center;

    @media screen and (max-width: $xs-tablet) {
      font-size: 1.5rem;
      line-height: 1.07;
      margin-left: 1.2rem;
    }
  }

  .code-wrapper {
    margin-left: 2rem;
    border: dotted .2rem $green300;
    border-radius: 1.8rem;
    padding: 0 2rem;
    font-size: 2rem;
    color: $green300;
    display: flex;

    @media screen and (max-width: $xs-tablet) {
      font-size: 1.6rem;
      padding: 0 .8rem;
      margin-left: 1.2rem;
    }

    .code {
      font-weight: 700;
    }
  }
}
