.gift-card-component {
  max-width: 33rem;
  margin: 0 auto;

  .input-wrapper {
    display: inline-block;
    width: 100%;
    margin-bottom: 2rem;

    &:last-of-type {
      margin-bottom: 0;
    }

    &.valid,
    &.error {
      &::after {
        content: "";
        position: absolute;
        background-size: cover;
        display: inline-block;
        width: 2.4rem;
        height: 2.4rem;
        margin-right: 0;
        right: 0;
      }
    }

    &.valid {
      &::after {
        background-image: url('../img/icons/input-valid.svg');
        width: 2.4rem;
        height: 2.4rem;
        top: 2rem;
      }
    }

    &.error {
      &::after {
        background-image: url('../img/icons/input-invalid.svg');
        width: 1.8rem;
        height: 1.8rem;
        top: 2.3rem;
      }
    }
  }

  .generic-form {
    input[type="text"] {
      background-image: none;
      margin-bottom: 0;
    }

    .input-row {
      .error-label {
        margin-top: 0;
      }
    }
  }
}