.welcome-modal-component {
  .modal-title {
    font-size: 2.6rem;
    color: $black;
    margin-top: 6.5rem;
    line-height: 3rem;

    @media screen and (max-width: $xs-tablet) {
      padding: 0 3rem;
      margin-top: 5.2rem; 
    }
  }

  .modal-body {
    padding: 2.4rem 6rem 4.2rem;
    @media screen and (max-width: $xs-tablet) {
      padding: 1.4rem 2rem 2.2rem; 
    }

    p {
      font-size: 1.6rem;
      font-weight: 500;
      max-width: 29rem;
      margin: 0 auto;
    }
  }  
}