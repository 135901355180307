.beta-banner-component {
  position: relative;
  left: 0;
  right: 0;
  top: 0;
  z-index: 400;
  background: $gray1200;
  color: $black;
  font-family: $primaryType;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1.7rem 1rem ;
  width: 100%;

  @media screen and (max-width: $xs-tablet) {
    padding: 0;
  }

  span {
    font-size: 1.6rem;
    line-height: 1;
    @media screen and (max-width: $xs-tablet) {
      display: none;
    }
  }

  a {
    margin-left: .6rem;
    line-height: 1;
    
    @media screen and (max-width: $xs-tablet) {
      display: block;
      width: 100%;
      text-align: center;
      margin: 0;
      padding: 1.4rem 0;

      &:after {
        display: none;
      }
    }
  }
}

.highcontrast {
  .beta-banner-component {
    border: 1px solid $white;
  }
}
