.receipt-component {
  text-align: center;

  .info {
    margin-bottom: 2rem;
    font-weight: 500;
    font-size: 1.6rem;
    color: $blue100;
  }

  .btn-receipt {
    margin-bottom: 4rem;
    padding: 1rem 2.2rem;
    font-size: 1.6rem;
    min-width: 21.2rem;

    @media screen and (max-width: $small-tablet) {
      margin-bottom: 2rem;
    }
  }

  .btn-receipt svg {
    display: inline-block;
    vertical-align: middle;
    margin-right: 1rem;
    max-width: 2.4rem;
    max-height: 2.4rem;
  }

  .btn-receipt:hover,
  .btn-receipt:focus {
    color: $white;
    background: $green300;

    & svg path {
      fill: $white;
    }
  }

  .cta-text {
    vertical-align: middle;
  }

  .description {
    margin-bottom: 4rem;
    font-weight: 700;
    color: $blue100;
  }

  .arrow-link {
    font-weight: 700;
    font-size: 1.8rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
}