.max-width-image-component {
   	max-width: 100%;
   	margin: 0 auto 3rem auto;
   	padding: 0 1.5rem 6rem 1.5rem;

   	@media (max-width: $xs-tablet) {
   		padding: 0 0 6rem 0;
      width: 100vw;
      margin-left: -1.5rem;
   	}

   	img {
   		width: 100%;
   		height: auto;
      @media (max-width: $xs-tablet) {
        padding: 0;
        width: 100vw;
      }
   	}
}