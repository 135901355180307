.app-spot-component {
  text-align: center;
  margin-top: 4.2rem;
  margin-bottom: 4.4rem;

  @media screen and (max-width: $xs-tablet) {
    margin-top: -0.8rem;
    margin-bottom: 4.5rem;

  }

  .script-text {
    font-size: 1.6rem;
    color: $green300;
    line-height: 1;
    display: block;
  }

  h4 {
    line-height: 1;
    margin-bottom: 2.8rem;
    font-size: 2.4rem;
  }

  .app-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      margin: 0 1.4rem 2.2rem;

      a {
        img {
          @media screen and (max-width: 230px) { 
            max-width: 100%;
          }
        }
      }
    }
  }

  p {
    a {
      border-bottom: .1rem solid;
      display: inline-block;

      &:hover,
      &:focus {
        text-decoration: none;
      }
    }
  }
}

.edge {
  .app-spot-component {
    @media screen and (max-width: $xs-tablet) {
     flex-basis: 14.5rem;
    }
  }
}