.faq-component {
  padding-left: 1.4rem;
  .column {
    margin: 0 auto;
    overflow: hidden;
  }

  &:nth-of-type(1) {
      margin-top: 5rem;
  }

  &:last-of-type {
      margin-bottom: 3rem;

    &:after {
      content: none !important; 
    }
  }
  
  &:after {
    content: '';
    background-image: url("../img/divider-orange.svg");
    //background-repeat-: no-repeat;
    overflow: hidden;
    background-size: cover;
    height: 2.8px;
    width: 100%;
    display: inline-block;
    
    @media screen and (max-width: $xs-tablet) {
      width: calc(100% - 4rem);
      margin-left: 1.5rem;
    }
  }

  .fcs-third-party {
    padding-right: 0.3rem;
    &:after {
      content: " \e900" !important;
      /* use !important to prevent issues with browser extensions that change fonts */
      font-family: 'icomoon' !important;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;
      background-image: none !important;
      font-size: 1rem;
      transform: translateY(-50%);
      white-space: nowrap;
  
      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;
    }
  }

	.faq-wrapper {
    margin: 0 auto;

    @media screen and (max-width: $xs-tablet) {
      padding-right: 2rem;
      margin-left: 1.5rem;
    }

		ul {
			text-align: left;
      list-style-type: none;
      padding: 0 0 0 2rem;

      li {
        position: relative;
        font-family: $primaryType;
        font-size: 1.6rem;
        padding-top: 1.2rem;
        text-indent: -20px;

          span {
          padding-left: 2rem;

          span {
            padding-left: 0;
          }
        }
      }

      li.faq-q {
        margin-bottom: 1.2rem;
        
        &::before {
          color: $green300;
          content: '';
          background-image: url('../img/icons/q-letter-v4.svg');
          background-repeat: no-repeat;
          width: 2rem;
          height: 2rem;
          position: absolute;
          left: -2.2rem;
          top: 1.6rem;
        }
      }

      li.faq-a {
        &::before {
          color: $green300;
          content: '';
          background-image: url('../img/icons/a-letter-v4.svg');
          background-repeat: no-repeat;
          width: 2rem;
          height: 2rem;
          font-size: 1.6rem;
          position: absolute;
          left: -2.2rem;
          top: 1.6rem;
        }
      }
		}

    a::focus {
      display: inline;
    }
	}
}