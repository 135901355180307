.delivery-options-component {
white-space: nowrap;
margin-left: 2rem;
@media screen and (max-width: $xs-tablet) {
    display: none;
}
.primary-button {
    &:not(:last-of-type) {
      margin-right: 3rem;
    }
  }
}