.card-selection-component {
  max-width: 37.5rem;
  margin: 0 auto;
  
  .card-wrapper {
    position: relative;
    background: $white;

    &:last-of-type {
      margin-bottom: 4.8rem;
    }
  }

  .card-details,
  label {
    display: flex;
    align-items: center;
    width: 100%;
    
    &:hover {
      cursor: pointer;
    }
  }

  label {
    z-index: 1;
    padding: 2rem 0;

    &::after {
      content: '';
      background: url("../img/divider-pale-blue.svg") center center repeat-x;
      background-size: cover;
      display: block;
      width: 100%;
      height: .3rem;
      left: 0;
      right: 0;
      bottom: 0;
      position: absolute;
    }
  }

  .primary-card {
    margin-left: 2rem;

    + .card-logo {
      margin-left: 1.4rem;
    }
  }

  .card-logo {
    margin-left: 5rem;
    margin-right: 1rem;
    max-width: 2.7rem;
  }

  .card-number {
    font-family: $primaryType;
    font-size: 1.8rem;
    font-weight: 500;
    line-height: 1;
  }

  input[type=radio] {
    appearance: none;

    &:checked {
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      background: $green1300;
      top: 0;
    }
  }

  .primary-button {
    @media screen and (max-width: $xs-tablet) {
      width: 100%;
      font-size: 1.8rem;
    }
  }
}
