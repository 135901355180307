.confirm-details-component {
  width: 100%;
  max-width: 38.6rem;
  margin: 0 auto;

  .primary-button {
    max-width: 31.5rem;
    margin-top: 4.2rem;
  }
}
