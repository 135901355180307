.option-selection-component {
  .modal-content {
    background: $green1300;
    border-radius: .6rem;
    box-shadow: 0 2px 5px 0 rgba($blue500, 0.6);
  }

  .modal-title {
    font-size: 2.6rem;
    margin-bottom: 1.5rem;
  }

  .modal-body,
  .modal-footer {
    display: flex;
  }

  .modal-body {
    padding-bottom: 4.4rem;
    justify-content: space-evenly;

  }

  .modal-footer {
    background: $white;
    justify-content: space-between;
    padding: 1.5rem 0;
    
    a,
    button {
      font-size: 1.8rem;
    }
    
    a {
      margin-left: 4rem;
    }
    
    .primary-button {
      max-width: 16rem;
      margin-right: 2rem;
    }
  }

  .product-name {
    display: inline-block;
    font-family: $primaryType;
    font-size: 1.6rem;
    font-weight: 500;
    margin-bottom: 3rem;
  }

  label {
    display: inline-block;
    text-align: center;
    cursor: pointer;
  }

  img,
  svg {
    width: 9.3rem;
    height: 9.3rem;
    padding: 0.6rem;
  }

  input {
    margin: 0;
    padding: 0;
    opacity: 0;
    position: absolute;
    
    &+label {
      .icon {
        display: block;
        margin: 0 auto .4rem;

        .st0,
        .st1,
        .st2 {
          stroke: $green300;
          fill: none;
        }
        
        .st2 {
          fill: $green300;
        }

        g {
          path {
            &.st0 {
              fill: $green300;
            }
          }
        }

        &.active {
          display: none;
        }
      }
    }

    &.true,
    &.false {
      &:checked {
        &+label {
          .icon {
            display: none;
            &.active {
              display: block;
              background: $green300;
              .st0,
              .st1,
              .st2 {
                stroke: $white;
              }
              
              .st2 {
                fill: $white;
              }

              g {
                path {
                  &.st0 {
                    fill: $white;
                  }
                } 
              }
            }
          }
          
          .text {
            color: $green300;
          }
        }
      }
    }
  }

  .text {
    display: inline-block;
    font-family: $primaryType;
    font-size: 1.8rem;
    font-weight: 700;
    color: $black;
    line-height: .9;
    max-width: 12rem;
  }
}

.edge {
  .option-selection-component {
    .modal-body {
      label {
        &:first-of-type {
          margin-right: auto;
        }
      }
    }
  }
}