.bottom-gradient {
    &::before {
      content: "";
      position: absolute;
      width: 100%;
      height: 6rem;
      bottom: 4rem;
      right: 0;
      left: 0;
      z-index: 1;
      background-image: none;
    }
  }