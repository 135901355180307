.customize-modal-component,
.customize-active-modal-component {
  .modal-header,
  .modal-body {
    max-width: 55rem;
    overflow-x: hidden;
  }

  .modal-body {
    margin: 0 auto;
  }

  .modal-content {
    @media screen and (max-width: $xs-tablet) {
      padding: 4rem 0;
    }

    .modal-body {
      @media screen and (max-width: $xs-tablet) {
        padding: 0 1.5rem;
      }
    }

    .modal-header {
      .modal-title {
        font-size: 2.6rem;
        margin-bottom: 3rem !important;
    
        @media screen and (max-width: $xs-tablet) {
          padding: 0 1.5rem;
          margin-bottom: 2.2rem !important;
        }
      }
    }
  }

  .submit {
    max-width: 32rem;
    margin-bottom: 3rem;
    margin-top: 3rem;
  }

  a {
    font-size: 1.8rem;
    margin-bottom: 4rem;
    display: inline-block;
  }

  .ingredient-list {
    margin: 0;
    padding: 1rem 0 0;

    li {
      list-style: none;

      &:first-child {
        button {
          &:before {
            content: url("../img/divider-gray.svg");
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            line-height: .2rem;
          }
        }
      }

      .item {
        font-family: $primaryType;
        background: transparent;
        position: relative;
        margin: 0;
        padding: .8rem 1.2rem;
        text-align: left;

        &:hover,
        &:focus {
          background: $blue900;
          outline: none;
        }

        &:after {
          content:  url("../img/divider-gray.svg");
          position: absolute;
          left: 0;
          right: 0;
          bottom: 0;
          line-height: .2rem;
        }

        &.selected,
        &.swaped {
          .icon {
            &:after {
             content: url("../img/icons/swap-red.svg") !important;
            }
          }
        }

        &.swaped {
          background: $blue900;
        }

        .allergen {
          color: $red500;
          font-size: 1.1rem;
          font-weight: 500;
          margin-left: 6.2rem;

          &:before {
            content: url("../img/icons/alert.svg");
            display: inline-block;
            width: 1.4rem;
            height: 1.4rem;
            margin-right: .4rem;
            vertical-align: middle;
          }
        }

        .content-wrapper {
          display: flex;
          justify-content: space-between;
          align-items: center;
        }
  
        .img-wrapper {
          width: 5rem;
          margin-right: 2.2rem;

          img {
            max-width: 100%;
          }
        }
        
        .ingredient-wrapper {
          flex-basis: 100%;
          text-align: left;
          span,
          small {
            line-height: 1;
            display: block;
            color: #212529;
          }
          span {
            font-size: 1.5rem;
          }
          small {
            font-size: 1.2rem;
            font-weight: 400;
          }
        }
  
        .calories {
          font-size: 1.2rem;
          white-space: nowrap;
          margin-left: 2rem;
        }
  
        .icon {
          margin-left: 1rem;
          &:after {
            content: url("../img/icons/swap.svg");
            width: 2.3rem;
            height: 2.2rem;
            display: inline-block;
          }
        }
      }
    }
  }

  .product-summary {
    display: flex;
    font-family: $primaryType;
    padding: 2rem 7rem;
    margin-top: 2.4rem;
    margin-bottom: 4rem;

    @media screen and (max-width: $xs-tablet) {
      margin-bottom: 2.4rem;
      padding: 2rem 4rem;
    }

    .image-wrapper {
      margin-right: 1.5rem;

      @media screen and (max-width: $xs-tablet) {
        margin-right: .5rem;
      }

      img {
        max-width: 10.6rem;
        max-height: 8rem;
      }
    }

    .content-wrapper {
      text-align: left;
      span {
        display: block;
        font-size: 1.5rem;
        line-height: 1.33;
      }

      .title {
        font-size: 2.2rem;
        line-height: 1.18;
        margin-bottom: 0;
        font-weight: 500;
      }

      .price,
      .calories {
        font-weight: 500;
        display: inline-block;
      }

      .price {
        & + .calories {
          border-left: 1px solid $mandarin500;
          padding-left: 1rem;
          margin-left: 1rem;
        }
      }
    }
  }
}