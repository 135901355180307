.direction-modal-component {
  .modal-content {
    padding: 4rem 3rem;
    .modal-header {
      .modal-title {
        margin: 6.6rem auto 6.4rem;
        max-width: 28rem;
      }
    }
  }
  .modal-body {
    .tertiary-button {
      display: block;
      width: 100%;
      padding: 2rem;
      margin-bottom: 3.4rem;
      font-size: 1.8rem;
    }
    a {
      font-size: 1.8rem;
    }
  }
  .fcs-third-party {
    .fcs-third-party-icon {
      right: auto;
      display: inline;
      margin-left: 1rem;
    }
  }
}