.reward-checkout-component {
  min-height: 12rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  
  .points-count {
    display: flex;
    align-items: center;
    gap: 0.447rem;
  }

  .img-wrapper {
    max-width: 3.2rem;
  }

  .img-reward {
    max-width: 100%;
  }

  .rewards-text {
    margin-top: 0.6rem;
    margin-bottom: 0.3rem;
    text-align: center;
  }

  .faded {
    opacity: 0.5;
  }

  .checkout-component.updated & {
    .label {
      font-family: $primaryType;
      font-size: 1.5rem;
      font-weight: 700;
      color: $green350;
      padding: 0;
      display: flex;
      flex-direction: column;
      align-items: center;
      position: relative;
      background: url('../../../img/reward-border-v2-app.png') center center no-repeat;
      background-size: 100% 100%;
      padding: 1.4rem;
      width: 100%;
      height: 100%;
  
      &::before,
      &::after {
        top: 2.3rem;
        right: 1.3rem;
        left: auto;
        border: .2rem solid $blue300;
        border-radius: .4rem;
      }
    }

    input[type="checkbox"]:checked + label::after {
      background: $white;
    }
  
    input[type="checkbox"]:checked + label {
      background: url('../../../img/reward-border-v2-app-green.png') center center no-repeat;
      background-size: 100% 100%;
    }
  
    input[type="checkbox"]:focus + label {
      outline: none;
    }
  }
}