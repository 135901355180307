.mobile-menu-link-list { 
	display: none;
	background-color: $green1100;
	margin-top: 4rem;
 
  .text-btn {
    padding-right: 0;

    &:after {
      content: none;
    }
  }

	@media (max-width: $xs-tablet) {
    display: block;
    margin-bottom: 2.5rem;

    & > ul { 
      &:last-child {
        &:before { 
          content: '';
          background-image: url(../img/divider-orange.svg);
          overflow: hidden;
          background-size: cover;
          height: .3rem;
          width: 100%;
          left: 0;
          margin-top: -0.3rem;
          display: block;
        }
      }
    }
    
		ul {
			list-style-type: none;
			margin: 0;
      padding: 0;
      
      .text-btn:after {
        position: absolute;
      }

			li.primary-mobile-menu {
				display: block;
				font-size: 2.4rem;

				&:after {
          content: '';
          background-image: url(../img/divider-orange.svg);
          overflow: hidden;
          background-size: cover;
          height: .3rem;
          width: 100%;
          left: 0;
          margin-top: -0.3rem;
        }

        @media screen and (max-width: $xs-tablet) {
          width: calc(100% - 4rem);
          padding-left: 1rem;
        }

        > a {
          color: $black;
          height: 100%;
          width: 100%;
          padding: 2rem 0 0 1.6rem;
          margin-bottom: 1.6rem;
          display: inline-block;
          border-bottom: none !important;

          &:after {
            right: 3rem;
            cursor: pointer;
            content: url("../img/triangle.svg");
            position: absolute;
            transform: scale(1.3);
          }
          &:hover {
            &:after {
              content: url("../img/triangle-green.svg");
            }
          } 
		  	}

				ul.secondary-mobile-list {
					width: 100%;
          padding: 0;
          
				&:before {
          content: '';
          background-image: url(../img/divider-orange.svg);
          overflow: hidden;
          background-size: cover;
          height: 2.8px;
          width: 100%;
          display: inline-block;
          position: absolute;
          left: 0;
          right: 0;
        }

        li.secondary-mobile-menu {
          font-size: 1.6rem;
          padding: .8rem 0 .9rem 3.8rem;
          
            > a {
              color: $green300;
              height: 100%;
              width: 100%;
              display: inline-block;

              &:after {
                right: 3rem;
                cursor: pointer;
                content: url("../img/triangle-green.svg");
                position: absolute;
                transform: scale(1.3);
              }
              &:hover {
                &:after {
                  content: url("../img/triangle.svg");
                }
              } 
            }

            .text-btn {
              width: 100%;
              text-decoration: none !important;

              &:hover,
              &:focus {
              color: $black;
                &:after {
                  margin-left: 1rem;
                }
              }
            }

            &:first-of-type {
              padding-top: 1.6rem;
            }
          }
				}
			}
		}
	}
}