.forgot-password-component {
  max-width: 42rem;
  width: 100%;
  margin: 0 auto;

  .generic-form {
    max-width: 31.5rem;
    margin: 0 auto;
  }

  .modal-title {
    margin-bottom: 1rem;
  }

  .input-wrapper {
    margin-bottom: 3.2rem;
  }

  .primary-button {
    margin-bottom: 2.8rem;
  }

  &.confirmation {
    .modal-header {
      max-width: 42rem;
    }

    .primary-button {
      max-width: 31.5rem;
      margin: 2.4rem 0 auto;
    }
  }

  .resend-wrapper {
    display: inline-block;
    font-family: $primaryType;
    font-size: 1.5rem;
    margin-top: 3rem;

    a {
      font-size: 1.8rem;
    }
  }
  
  #confirmation-form-email {
    display: inline-block;
    word-wrap: break-word;
    word-break: break-all;
  }
}