.rewards-list-component {
    width: 100%;
    background-color: $beige650;
    margin-top: 1.2rem;
    position: relative;

    .redeem-rewards {
        margin-left: 7.2rem;
        margin-top: 1.2rem;
    }
   
    .description {
        max-width: 62.3rem;
        margin-bottom: 3.6rem;
    }

    .rewards {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        row-gap: 4rem;
        max-height: 100%;
        padding: 1rem 0.2rem;
        overflow: clip;

        &.fullHeight {
            max-height: 100%;
        }
    }

    li {
        list-style: none;
    }

    .visible {
        display: block;
    }

    .hidden {
        display: none;
    }

    @media screen and (min-width: $large) {
        .bottom-gradient {
            &::before {
                display: none;
            }
        }
    }

    @media screen and (max-width: $large) {
          .rewards {
            grid-template-columns: repeat(2, 1fr);
            max-width: 40rem;
            max-height: 54.5rem;
            margin: 0 auto;
            column-gap: 3.3rem;
            row-gap: 4rem;
        }

        .bottom-gradient {
            &::before {
              background-image: linear-gradient(to top, $beige650, rgba(246, 244, 238, 0));
              height: 8rem;
            }
          }

          .empty-rewards {
                display: flex;
                justify-content: center;
          }

    }
}

.ios12,
.ios13,
.ios14,
.ios15 {
    .rewards-list-component .rewards {
        overflow: hidden;
    }
}
