.notification-modal-component {
  padding-right: 0;

  .modal-dialog {
    height: auto;
    padding-top: 7rem;

    @media screen and (max-width: $xs-tablet) {
      max-width: 33rem;
      margin: 0 auto;
    }
  }

  .close {
    top: 2.4rem;
    right: 2.4rem;
  }

  .modal-body {
    padding-left: 4.5rem;
    padding-right: 4.5rem;
    text-align: center;
  }

  .modal-header {
    max-width: none;
  }

  .modal-title {
    font-size: 2.6rem;
    margin: 3rem 0 2.2rem;
  }

  p {
    color: $black;
    margin-bottom: 1.6rem;
  }

  .modal-content {
    background-color: $green1300;
    height: auto;
    padding: 0;
    border-radius: .6rem;

    @media screen and (max-width: $xs-tablet) {
      width: 100%;
    }
  }

  .modal-footer {
    background: $white;
    padding: 2.6rem 2rem;
    display: flex;
    justify-content: space-around;

    @media screen and (max-width: $xs-tablet) {
      justify-content: space-between;
    }

    &--single-button{
        justify-content: space-around;
    }
  }

  .primary-button {
    @media screen and (max-width: $xs-tablet) {
      min-width: 15.8rem;
    }
  }

  a,
  .primary-button {
    font-size: 1.8rem;
  }
}

.highcontrast {
  .notification-modal-component {
    .modal-content {
      border: 1px solid $white !important;
    }
  }
}
