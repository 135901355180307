.section-component {
  padding-top: 3rem;
  .flex-container {
    display: flex;

    @media (max-width: $xs-tablet) {
      display: block;
    }
  }
  //menu detail descriptions above nutrition jj-869
  &.full-description {
    padding-top: 5rem;
    padding-bottom: 3rem;

    @media (max-width: $xs-tablet) {
      padding-top: 3rem;
      padding-bottom: 0;
    }
  }

  &.wave-top {
    padding-top: 6.2rem;
    position: relative;

    @media screen and (max-width: $xs-tablet) {
      padding-top: 0;
      margin-top: 3rem;
    }

    @media screen and (min-width: $small-tablet) {
      clip-path: polygon(
        99.93065187239945% 4.714285714285714%,
        99.93065187239945% 100%,
        0.06934812760055478% 100%,
        0.06934812760055478% 1.5714285714285716%,
        1.9417475728155338% 2%,
        1.9417475728155338% 2.142857142857143%,
        2.496532593619972% 2.142857142857143%,
        2.496532593619972% 2.2857142857142856%,
        3.1206657420249653% 2.2857142857142856%,
        3.1206657420249653% 2.4285714285714284%,
        3.6754507628294033% 2.4285714285714284%,
        3.6754507628294033% 2.571428571428571%,
        6.310679611650485% 3%,
        6.310679611650485% 3.1428571428571432%,
        7.073509015256588% 3.1428571428571432%,
        7.073509015256588% 3.2857142857142856%,
        7.905686546463246% 3.2857142857142856%,
        7.905686546463246% 3.428571428571429%,
        8.876560332871012% 3.428571428571429%,
        10.194174757281553% 3.571428571428571%,
        11.71983356449376% 3.7142857142857144%,
        14.21636615811373% 3.571428571428571%,
        16.08876560332871% 3.428571428571429%,
        18.169209431345354% 3.428571428571429%,
        18.169209431345354% 3.2857142857142856%,
        20.041608876560332% 3.2857142857142856%,
        20.041608876560332% 3.1428571428571432%,
        21.63661581137309% 3.1428571428571432%,
        23.231622746185852% 3%,
        24.618585298196948% 2.857142857142857%,
        27.323162274618586% 2.4285714285714284%,
        36.615811373092924% 1.4285714285714286%,
        36.615811373092924% 1.2857142857142856%,
        38.141470180305134% 1.1428571428571428%,
        38.90429958391123% 1.1428571428571428%,
        38.90429958391123% 1%,
        40.01386962552011% 1%,
        40.01386962552011% 0.8571428571428572%,
        41.19278779472954% 0.8571428571428572%,
        41.19278779472954% 0.7142857142857143%,
        42.302357836338416% 0.7142857142857143%,
        42.44105409153953% 0.5714285714285714%,
        43.689320388349515% 0.5714285714285714%,
        43.75866851595007% 0.4285714285714286%,
        45.07628294036061% 0.4285714285714286%,
        46.67128987517337% 0.2857142857142857%,
        46.67128987517337% 0.14285714285714285%,
        48.6130374479889% 0.14285714285714285%,
        51.45631067961165% 0%,
        57.76699029126213% 0%,
        60.124826629680996% 0.14285714285714285%,
        60.81830790568654% 0.14285714285714285%,
        60.81830790568654% 0.2857142857142857%,
        63.176144244105416% 0.2857142857142857%,
        63.176144244105416% 0.4285714285714286%,
        65.18723994452151% 0.4285714285714286%,
        68.7239944521498% 0.8571428571428572%,
        70.31900138696255% 1%,
        73.23162274618585% 1.1428571428571428%,
        74.61858529819695% 1.2857142857142856%,
        75.93619972260748% 1.4285714285714286%,
        77.25381414701803% 1.5714285714285716%,
        77.25381414701803% 1.7142857142857144%,
        78.91816920943134% 1.8571428571428572%,
        79.68099861303745% 1.8571428571428572%,
        79.68099861303745% 2%,
        82.03883495145631% 2.142857142857143%,
        82.03883495145631% 2.2857142857142856%,
        83.14840499306518% 2.2857142857142856%,
        83.14840499306518% 2.4285714285714284%,
        84.25797503467406% 2.4285714285714284%,
        84.25797503467406% 2.571428571428571%,
        85.71428571428571% 2.7142857142857144%,
        86.47711511789181% 2.7142857142857144%,
        86.47711511789181% 2.857142857142857%,
        87.51733703190014% 2.857142857142857%,
        87.51733703190014% 3%,
        88.55755894590847% 3%,
        88.55755894590847% 3.1428571428571432%,
        89.94452149791957% 3.2857142857142856%,
        90.63800277392511% 3.2857142857142856%,
        90.63800277392511% 3.428571428571429%,
        91.60887656033287% 3.428571428571429%,
        91.60887656033287% 3.571428571428571%,
        92.57975034674064% 3.571428571428571%,
        92.57975034674064% 3.7142857142857144%,
        93.5506241331484% 3.7142857142857144%,
        93.5506241331484% 3.8571428571428568%,
        94.52149791955617% 3.8571428571428568%,
        94.52149791955617% 4%,
        95.49237170596395% 4%,
        95.49237170596395% 4.142857142857142%,
        96.46324549237171% 4.142857142857142%,
        96.46324549237171% 4.285714285714286%,
        97.43411927877948% 4.285714285714286%,
        97.43411927877948% 4.428571428571428%,
        98.3356449375867% 4.428571428571428%,
        98.3356449375867% 4.571428571428571%
      );
    }

    &:before {
      @media screen and (max-width: $xs-tablet) {
        content: "";
        background: $blue900;
        display: block;
        width: 100%;
        height: 4.53vw;
        position: absolute;
        top: 0;
        transform: translateY(-100%) translateY(0.2rem);
        z-index: 2;
        left: 0;
        clip-path: polygon(
          0% 100%,
          100% 100%,
          100% 82.35294%,
          74.66667% 35.29412%,
          62.13333% 11.76471%,
          52.53333% 0%,
          32% 0%,
          24% 5.88235%,
          10.93333% 17.64706%,
          0% 29.41176%,
          0% 100%
        );
      }
    }

    &.secondary {
      @media screen and (min-width: $small-tablet) {
        clip-path: polygon(
          0% 9.666203059805285%,
          0.5974166666666667% 9.457579972183588%,
          1.6252% 9.087065368567455%,
          2.485583333333333% 8.806884561891517%,
          3.291316666666667% 8.551390820584144%,
          4.00655% 8.328859527121002%,
          4.813483333333333% 8.08143254520167%,
          5.659883333333333% 7.825799721835883%,
          6.71335% 7.512865090403338%,
          7.578616666666667% 7.2595966620305985%,
          8.473% 7.001321279554938%,
          9.276066666666665% 6.772461752433936%,
          10.092733333333333% 6.542976356050069%,
          10.82795% 6.338664812239221%,
          11.675866666666666% 6.1059805285118225%,
          12.743400000000001% 5.8176634214186365%,
          13.91215% 5.508066759388039%,
          15.038233333333334% 5.215994436717663%,
          16.592533333333336% 4.823018080667594%,
          17.573916666666666% 4.581363004172461%,
          18.399916666666666% 4.3819888734353265%,
          19.09641666666667% 4.216133518776078%,
          21.06908333333333% 3.754798331015299%,
          22.41375% 3.4488178025034775%,
          23.64741666666667% 3.20326842837274%,
          24.742583333333336% 2.9933240611961054%,
          27.04125% 2.4527121001390824%,
          27.83325% 2.2958970792767732%,
          29.39525% 2.0160639777468705%,
          30.82875% 1.7710709318497915%,
          31.82508333333334% 1.6061196105702362%,
          32.894416666666665% 1.4198191933240614%,
          33.538916666666665% 1.3210709318497913%,
          34.53475% 1.1730876216968011%,
          35.86475000000001% 0.9815020862308763%,
          36.83091666666667% 0.8463143254520167%,
          38.278083333333335% 0.6515994436717663%,
          39.63333333333333% 0.48678720445062584%,
          40.07866666666666% 0.44137691237830323%,
          41.65933333333333% 0.2965229485396384%,
          43.341833333333334% 0.1614047287899861%,
          44.68833333333333% 0.06404728789986093%,
          45.65% 0%,
          47.014833333333335% -0.05403337969401947%,
          49.00516666666667% -0.02002781641168289%,
          50.77516666666667% 0.11210013908205842%,
          52.019666666666666% 0.25639777468706537%,
          53.19049999999999% 0.4267037552155772%,
          53.893% 0.5434631432545202%,
          57.226333333333336% 1.2198191933240612%,
          60.21666666666666% 1.9471488178025034%,
          61.89183333333334% 2.368567454798331%,
          64.09516666666667% 2.9174547983310153%,
          66.29350000000001% 3.4777468706536854%,
          67.85% 3.8942976356050067%,
          69.64150000000001% 4.374547983310153%,
          71.21983333333333% 4.775799721835884%,
          72.80866666666668% 5.158275382475661%,
          75.17816666666666% 5.686787204450625%,
          77.1265% 6.080945757997219%,
          78.88233333333334% 6.401877607788594%,
          81.1605% 6.763490959666203%,
          83.81666666666666% 7.09297635605007%,
          85.29533333333333% 7.249304589707927%,
          87.94066666666666% 7.468150208623086%,
          89.91666666666667% 7.440681502086231%,
          91.27116666666667% 7.169471488178026%,
          92.43383333333333% 6.800904033379694%,
          93.5% 6.397566063977747%,
          94.90233333333335% 5.796383866481224%,
          96.98333333333333% 4.798331015299027%,
          98.612% 4.006258692628651%,
          100% 3.2684283727399164%,
          100% 100%,
          0% 100%,
          0% 9.666203059805285%
        );
      }

      &:before {
        @media screen and (max-width: $xs-tablet) {
          clip-path: polygon(
            0% 82%,
            0.862891874600128% 80.434%,
            1.8983365323096608% 78.556%,
            3.2866922584772875% 76.04%,
            4.27575175943698% 74.24%,
            5.787076135636596% 71.504%,
            7.131349968010237% 69.072%,
            8.44293026231606% 66.7%,
            10.697376839411387% 62.634%,
            12.276327575175944% 59.784000000000006%,
            14.173256557901471% 56.362%,
            15.483045425463851% 54%,
            17.180166346769035% 50.93%,
            18.682341650671784% 48.21%,
            19.836212412028154% 46.124%,
            21.535892514395393% 43.07%,
            23.003326935380677% 40.456%,
            24.55195137555982% 37.73%,
            26.495393474088292% 34.37%,
            27.914907229686502% 31.97%,
            29.110684580934098% 30%,
            30.259692898272554% 28.148%,
            31.19980806142035% 26.661999999999995%,
            32.15591810620601% 25.180000000000003%,
            33.029046705054384% 23.854%,
            34.09750479846449% 22.268%,
            35.36225207933461% 20.444%,
            36.824888035828536% 18.412%,
            38.52847088931542% 16.15%,
            40.27172104926424% 13.95%,
            42.29059500959693% 11.57%,
            43.557965451055665% 10.17%,
            45.06551503518874% 8.582%,
            46.56903390914907% 7.092%,
            47.76212412028151% 5.974%,
            49.22629558541267% 4.678%,
            50.03198976327575% 4%,
            50.890403071017275% 3.39%,
            52.35553422904671% 2.826%,
            54.5339091490723% 2.226%,
            54.95841330774153% 2%,
            56.236276391554696% 1.372%,
            57.22795905310301% 1.002%,
            58.9301983365323% 0.588%,
            59.722392834293025% 0.484%,
            60.831733845169545% 0.426%,
            62.65374280230327% 0.54%,
            64.00006397952656% 0.776%,
            65.42616762635957% 1.156%,
            67.01222008957134% 1.72%,
            68.7934101087652% 2.52%,
            69.6744081893794% 2.97%,
            70.23039027511196% 3.272%,
            71.18560460652591% 3.82%,
            71.92712731925783% 4.27%,
            72.35323096609085% 4.538%,
            73.04996801023673% 4.988%,
            73.87594369801663% 5.542%,
            74.46391554702497% 5.942%,
            75.12028150991684% 6.4%,
            75.64747280870121% 6.78%,
            76.34357005758157% 7.290000000000001%,
            76.95073576455535% 7.739999999999999%,
            77.72616762635955% 8.324%,
            78.54318618042228% 8.944%,
            79.468330134357% 9.65%,
            80.81253998720409% 10.672%,
            81.89699296225209% 11.472%,
            82.59756877799104% 11.982%,
            82.8426103646833% 12.158%,
            83.8470889315419% 12.886000000000001%,
            84.63339731285988% 13.462%,
            85.1919385796545% 13.874%,
            85.787587971849% 14.316%,
            86.65387076135637% 14.963999999999999%,
            87.59948816378758% 15.68%,
            88.29622520793346% 16.212%,
            89.2117722328855% 16.918%,
            89.94753678822778% 17.492%,
            90.79398592450416% 18.158%,
            91.68010236724248% 18.864%,
            92.47664747280871% 19.506%,
            93.31285988483685% 20.186%,
            94.04926423544467% 20.786%,
            94.72616762635955% 21.348%,
            95.39283429302623% 21.908%,
            96.149072296865% 22.55%,
            96.95521433141396% 23.244%,
            97.93985924504159% 24.104%,
            99.20409468969929% 25.236000000000004%,
            100% 26%,
            100% 216%,
            0% 216%,
            0% 82%
          );
        }
      }
    }
  }

  &.wave-top-bottom {
    padding-top: 6.2rem;
    position: relative;

    @media screen and (max-width: $xs-tablet) {
      padding-top: 0;
      margin-top: 3rem;
    }

    @media screen and (min-width: $small-tablet) {
      clip-path: polygon(
        0% 4.676258992805756%,
        1.1436333333333333% 4.477852004110996%,
        2.3536333333333332% 4.276464542651593%,
        4.7644166666666665% 3.8929599177800616%,
        6.760083333333333% 3.590441932168551%,
        8.86495% 3.2845323741007197%,
        11.890033333333335% 2.8671634121274407%,
        14.302266666666666% 2.5525693730729704%,
        16.049633333333333% 2.3346351490236383%,
        17.679133333333333% 2.138900308324769%,
        19.5563% 1.9224563206577596%,
        21.241799999999998% 1.736382322713258%,
        22.2748% 1.6262589928057551%,
        23.592966666666666% 1.4901336073997944%,
        25.1078% 1.3397738951695786%,
        26.799633333333333% 1.1797019527235355%,
        27.998466666666666% 1.071377183967112%,
        30.0523% 0.8957862281603288%,
        31.685466666666667% 0.7654162384378211%,
        33.9733% 0.5969167523124357%,
        35.18496666666667% 0.5146968139773894%,
        37.77896666666666% 0.3549845837615622%,
        40.012299999999996% 0.23627954779033913%,
        42.1853% 0.13823227132579652%,
        44.416466666666665% 0.05632065775950668%,
        46.400000000000006% 0%,
        48.823% 0.026104830421377186%,
        50.21183333333333% 0.08843782117163412%,
        52.44666666666667% 0.23083247687564235%,
        54.55783333333333% 0.4004110996916752%,
        56.18783333333334% 0.5499486125385405%,
        57.9965% 0.7327338129496404%,
        60.057% 0.9615107913669063%,
        61.46666666666667% 1.1305241521068858%,
        63.075333333333326% 1.333453237410072%,
        65.30733333333333% 1.6273381294964029%,
        67.65% 1.9527235354573484%,
        68.864% 2.142857142857143%,
        70.38333333333333% 2.3124357656731758%,
        72.00883333333333% 2.437821171634121%,
        73.46666666666667% 2.6207605344295994%,
        74.76916666666666% 2.774306269270298%,
        76.33833333333334% 2.9599691675231243%,
        78.33333333333333% 3.1860739979445016%,
        80.1445% 3.374871531346351%,
        81.94983333333333% 3.5470195272353546%,
        83.04716666666666% 3.6419835560123333%,
        85.15716666666667% 3.7985097636176772%,
        87.02399999999999% 3.900051387461459%,
        88.23166666666667% 3.941161356628982%,
        89.85% 3.956577595066804%,
        92.03933333333333% 3.8108427543679344%,
        93.72749999999999% 3.572302158273381%,
        95.2315% 3.3011305241521063%,
        97.33333333333334% 2.8773895169578623%,
        99.16666666666667% 2.4662898252826313%,
        100% 2.2607399794450154%,
        100% 97.01952723535457%,
        98.48883333333333% 97.17677286742034%,
        96.4975% 97.37512846865364%,
        94.75283333333333% 97.54265159301131%,
        93.1815% 97.68961973278519%,
        91.67399999999999% 97.8268242548818%,
        89.61849999999998% 98.00924974306268%,
        88.71066666666667% 98.08735868448099%,
        86.96516666666666% 98.23535457348407%,
        84.66716666666666% 98.42394655704008%,
        82.49466666666667% 98.59609455292909%,
        81.46933333333332% 98.67471736896198%,
        80.25% 98.7667009249743%,
        78.695% 98.88335046248716%,
        76.943% 99.01438848920863%,
        75.61883333333334% 99.11202466598151%,
        73.38716666666666% 99.27235354573483%,
        71.02933333333333% 99.431654676259%,
        69.09916666666666% 99.5524152106886%,
        67.83116666666666% 99.62589928057554%,
        65.96016666666667% 99.7250770811922%,
        64.81483333333333% 99.77954779033917%,
        63.60816666666666% 99.83144912641315%,
        59.62533333333333% 99.9568345323741%,
        58.23683333333334% 99.98201438848922%,
        56.166666666666664% 100%,
        54.37466666666667% 100.0174717368962%,
        52.98716666666666% 100.03031860226103%,
        51% 100%,
        49.733333333333334% 99.8972250770812%,
        48.222% 99.76978417266187%,
        46.64383333333333% 99.59866392600206%,
        43.45066666666666% 99.15210688591984%,
        41.81933333333333% 98.88591983556012%,
        40.499% 98.66032887975335%,
        38.0255% 98.2338129496403%,
        35.419% 97.81603288797534%,
        32.45% 97.4306269270298%,
        29.935833333333335% 97.25179856115108%,
        27.520333333333337% 97.19321685508736%,
        26.3125% 97.18807810894141%,
        24.5015% 97.19989722507708%,
        22.53333333333333% 97.2250770811922%,
        20.880166666666668% 97.2507708119219%,
        19.008666666666667% 97.28982528263104%,
        15.569616666666667% 97.38951695786228%,
        13.27815% 97.47533401849948%,
        10.921483333333333% 97.58067831449127%,
        8.870983333333333% 97.68550873586845%,
        7.3626499999999995% 97.77081192189105%,
        5.5491% 97.88283658787256%,
        3.9202% 97.99280575539568%,
        2.1120666666666668% 98.12692702980472%,
        0% 98.30421377183967%,
        0% 4.676258992805756%
      );
    }

    &:before {
      @media screen and (max-width: $xs-tablet) {
        content: "";
        background: $blue900;
        display: block;
        width: 100%;
        height: 1.5rem;
        position: absolute;
        top: 0;
        transform: translateY(-100%) translateY(0.2rem);
        z-index: 2;
        left: 0;
        clip-path: polygon(
          0% 38.61%,
          1.61216% 36.55521%,
          3.560893% 34.11428%,
          5.05895% 32.26409%,
          7.09949% 29.7791%,
          8.93551% 27.57837%,
          10.4442% 25.79729%,
          12.06031% 23.9181%,
          13.67142% 22.0787%,
          14.96533% 20.62548%,
          16.15169% 19.31505%,
          18.0856% 17.2301%,
          20.17503% 15.05791%,
          21.84676% 13.35444%,
          23.6824% 11.49652%,
          25.40211% 9.79768%,
          26.9111% 8.36409%,
          28.5274% 6.90501%,
          29.81759% 5.80849%,
          31.43067% 4.53436%,
          33.4771% 3.09884%,
          34.8757% 2.245945%,
          36.0594% 1.612355%,
          37.6754% 0.88803%,
          39.3961% 0.3023166%,
          40.9% -0.0420849%,
          42.62659% -0.2324324%,
          43.91816% -0.22664%,
          45.10225% -0.1050193%,
          47.47013% 0.4853281%,
          49.808% 1.544401%,
          51.554% 2.679922%,
          54.0374% 4.82818%,
          55.6637% 6.5509%,
          58.02226% 9.45752%,
          59.63196% 11.69691%,
          61.3476% 14.29382%,
          63.6118% 18.02162%,
          65.1111% 20.6586%,
          66.633% 23.45752%,
          68.5762% 27.18532%,
          70.8226% 31.67335%,
          72.3282% 34.7621%,
          73.7179% 37.65328%,
          76.105% 42.66138%,
          77.8596% 46.32934%,
          79.7486% 50.2957%,
          81.806% 54.704%,
          83.8358% 59.14208%,
          85.3688% 62.5532%,
          86.8638% 65.9293%,
          88.261% 69.1297%,
          89.1446% 71.176%,
          90.5321% 74.4247%,
          91.81% 77.4621%,
          93.6459% 81.8814%,
          95.4793% 86.3864%,
          96.8862% 89.9%,
          98.0692% 92.9193%,
          100% 97.9625%,
          100% 100%,
          0% 100%,
          0% 38.61%
        );
      }
    }
  }

  &.white-theme {
    background-color: $white;
    &.wave-top,
    &.wave-top-bottom {
      &:before {
        @media screen and (max-width: $xs-tablet) {
          background: $white;
        }
      }
    }
  }

  &.light-gold-theme {
    background-color: $gold700;
    &.wave-top,
    &.wave-top-bottom {
      &:before {
        @media screen and (max-width: $xs-tablet) {
          background: $gold700;
        }
      }
    }
  }

  &.gold-theme {
    background-color: $gold800;
    &.wave-top {
      &:before {
        @media screen and (max-width: $xs-tablet) {
          background: $gold800;
        }
      }
    }
  }

  &.mandarin-theme {
    background-color: $mandarin700;
    &.wave-top {
      &:before {
        @media screen and (max-width: $xs-tablet) {
          background: $mandarin700;
        }
      }
    }
  }

  &.light-mandarin-theme {
    background-color: $mandarin800;
    &.wave-top {
      &:before {
        @media screen and (max-width: $xs-tablet) {
          background: $mandarin800;
        }
      }
    }
  }

  &.mint-theme {
    background-color: $green1000;
    &.wave-top {
      &:before {
        @media screen and (max-width: $xs-tablet) {
          background: $green1000;
        }
      }
    }
  }

  &.blue-theme {
    background-color: $blue600;
    &.wave-top {
      &:before {
        @media screen and (max-width: $xs-tablet) {
          background: $blue600;
        }
      }
    }
  }

  &.light-blue-theme {
    background-color: $blue700;
    &.wave-top {
      &:before {
        @media screen and (max-width: $xs-tablet) {
          background: $blue700;
        }
      }
    }
  }

  &.gray-theme {
    background-color: $blue900;
    &.wave-top {
      &:before {
        @media screen and (max-width: $xs-tablet) {
          background: $blue900;
        }
      }
    }
  }

  &.light-beige-theme {
    background-color: $beige600;
    &.wave-top {
      &:before {
        @media screen and (max-width: $xs-tablet) {
          background: $beige600;
        }
      }
    }
  }

  &.cold-green-theme {
    background-color: $green1300;
    &.wave-top {
      &:before {
        @media screen and (max-width: $xs-tablet) {
          background: $green1300;
        }
      }
    }
  }

  + .fluid-card-set-component {
    @media screen and (max-width: $xs-tablet) {
      padding-top: 2rem;
    }
  }

  .row {
    @media screen and (max-width: $xs-tablet) {
      flex-direction: column;

      &.justify-content-center {
        align-items: center;
      }
    }
  }

  .section-title {
    text-align: center;
    margin-bottom: 4.8rem;
    margin: 0 auto;
    line-height: 1;

    h1,
    h2,
    h3,
    h4,
    h5,
    h6,
    span {
      font-size: 4rem;
      font-family: $primaryType;
      font-weight: 700;
      color: $black;
      margin-top: 1rem;
      margin-bottom: 1rem;

      @media screen and (max-width: $xs-tablet) {
        margin-top: 0;
        margin-bottom: 1rem;
      }
    }

    @media screen and (max-width: $xs-tablet) {
      padding-top: 0;
      max-width: 29.5rem;
    }

    .script-text {
      display: inline-block;
      font-family: $secondaryType;
      font-size: 3.5rem;
      color: $green300;
      margin-right: 0.23em;

      @media screen and (max-width: $xs-tablet) {
        font-size: 3rem;
      }
    }

    &.large-text {
      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        font-size: 5.2rem;
      }
      @media screen and (max-width: $mobile) {
        h2 {
          font-size: 2.8rem;
          font-weight: 500;
          
          .script-text {
            font-size: 2rem;
            font-weight: $font-weight-normal;
            margin-right: 0;
          }
        }
      }
    }

    &.mandarin-theme {
      &::after {
        content: "";
        background-image: url(../img/underline.svg);
        background-repeat: no-repeat;
        background-size: contain;
        display: inline-block;
        width: 100%;
        height: 15px;
        background-position-x: center;
      }
    }
    //END themes
  }

  &[class*="-theme"] {
    + footer {
      margin-top: 0;
    }
  }

  &.section-padding-mobile-top-0 {
    @media (max-width: $xs-tablet) {
      padding-top: 0;
    }
  }

  &.section-padding-mobile-bottom-20 {
    @media (max-width: $xs-tablet) {
      padding-bottom: 2rem;
    }
  }
}
//grid overrides per claudia
.careers .col:not(.app-spot-component):not(.social-spot-component) {
  @media (min-width: $small-tablet) {
    max-width: 28%;
    padding-left: 0.6rem;
    padding-right: 0.6rem;
  }
}
.careers .classic-callout-component {
  padding-top: 0 !important;
}

.highcontrast {
  .section-component {
    .section-title {
      z-index: 5;
      position: relative;
    }
  }

  .item-slider {
    .item-slider-wrapper {
      a {
        &:focus {
          z-index: 3;
          position: relative;
        }
      }

      .text-descriptor {
        display: inline-block;
        z-index: 4;
        position: relative;
      }

      .mask {
        z-index: 4;
        position: relative;
      }
    }
  }
}

.privacy-cookies {
  table {
    width: auto;
  }
}

.android,
.ios {
  .privacy-cookies {
    a {
      word-break: break-word;
    }
  }
}

.section-subtitle {
  color: $blue300;
  font-family: $primaryType;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.048rem;
}
