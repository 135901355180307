.account-locations-component {
  font-family: $primaryType;
  .location-container {
    margin: 0;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media screen and (max-width: $xs-tablet) {
      position: relative;
      padding: 1rem 0;
      &::before,
      &::after {
        content: '';
        background: url("../img/divider-pale-blue.svg") center center repeat-x;
        background-size: auto;
        background-size: cover;
        display: block;
        width: 100%;
        height: .3rem;
        left: 0;
        right: 0;
        top: 0;
        position: absolute;
        z-index: 1;
      }
      &::after {
        bottom: 0;
        top: auto;
      }
    }
  }
  .location-image-wrapper {
    text-align: center;
    .item-favorite {
      cursor: auto;
    }
  }
  .location-image {
    max-width: 3rem;
    display: block;
  }
  .location-range {
    display: block;
    font-weight: 500;
  }
  .location-details-container {
    display: flex;
  }
  .location-detail-wrapper {
    margin-left: 1.6rem;
    @media screen and (max-width: $xs-tablet) {
      max-width: 22rem;
    }
  }
  .location-title {
    display: block;
    font-size: 1.5rem;
    font-weight: 700;
  }
  .location-address {
    font-size: 1.5rem;
  }
  .location-info-wrapper {
    font-size: 1.2rem;
    font-weight: 500;
    padding-right: 4rem;
  }
  .location-info {
    color: $green300;
  }
}