html {
  font-size: 10px;
}

body {
  &.legacy {
    @media screen and (max-width: $xs-tablet) {
      padding-top: 14rem;
    }
    .breadcrumb-component {
      padding-top: 0;
    }
  }

  .grecaptcha-badge {
    visibility: hidden;
  }
}

%fcs-container {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-left: auto;
  margin-right: auto;
}

.fcs-container {
  @extend %fcs-container;
  max-width: 1400px;
  &-slim {
    @extend %fcs-container;
    max-width: 1100px;
  }
  &-xslim {
    @extend %fcs-container;
    width: 900px;
  }
}

.fcs-loading-icon {
  display: none;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba($white,.75);
  z-index: 99999; //this will always be the highest
  &.show-loader {
    display: block;
  }

  .fcs-icon-img {
    display: block;
    width: 100%;
    max-width: 20rem;
    border-radius: 1.6rem;
    overflow: hidden;
    position: relative;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);
  }
}

.edge {
  body {
    overflow-x: hidden;
  }
}