.dispatch-delivery-component {
	max-width: 42rem;
	margin: 0 auto;
	
	.modal-header {
		.modal-title {
			margin-bottom: 2.2rem;
		}
	}
	
	.icon-wrapper {
		display: flex;
		align-items: center;
		justify-content: space-between;
		margin-bottom: 4.6rem;
	}
	
	.delivery-address {
		font-size: 1.5rem;
		font-family: $primaryType;
		font-weight: 400;
		color: $black;
		line-height: 1;
	}
	
	.triangle-link {
		font-weight: 500;
		color: $green300;
		
		&:after {
			background-image: url("../img/triangle-green.svg");
		}
	}
	
	input[type="password"], 
	input[type="email"], 
	input[type="text"], 
	input[type="number"] {
		margin-bottom: 0.8rem;
	}
	
	.btn-group {
		margin-top: 6rem;
		display: flex;
		justify-content: space-between;
	}
	
	button {
		width: 100%;
		
		& + button {
			margin-left: 1rem;
		}
	}
	
	.primary-button,
	.tertiary-button {
		min-width: auto;
		padding: 1.8rem 1rem;
		
		&.thinner {
			font-size: 1.8rem;
			display: flex;
			align-items: center;
			justify-content: center;
			
			&:after {
				content: "";
				display: inline-block;
				width: 2rem;
				height: 2rem;
				background-image: url("../img/icons/search-yellow.svg");
				background-position: center center;
				background-size: contain;
				margin-left: .5rem;
			}
			
			&:focus, 
			&:hover {
				&:after {
					background-image: url("../img/icons/search.svg");
				}
			}
		}
	}
	
	.order-details-confirmation-wrapper {
		font-size: 1.5rem;
		font-family: $primaryType;
		font-weight: 400;
		color: $black;
		display: flex;
		flex-wrap: wrap;
		padding-top: 1.4rem;
		
		dt {
			font-weight: 400;
			width: 8.4rem;
			text-align: left;
			line-height: 1;
		}
		
		dd {
			width: calc(100% - 10rem);
			text-align: left;
			margin-left: 1.6rem;
			margin-bottom: 2.4rem;
			line-height: 1;
		}
	}
	
	.delivery-wrapper {
		margin-top: 2.4rem;
	}
	
	.store-wrapper {
		margin-top: 2.4rem;
	}
	
	.info-text {
		line-height: 1.33;
		text-align: left;
	}
	
	.modal-subtitle {
		font-family: $primaryType;
		font-size: 2.6rem;
		line-height: 1.15;
		font-weight: 700;
		color: $black;
	}
	
	.location-list {
		font-family: $primaryType;
		width: 100%;
		max-width: 65rem;
		margin: 0 auto;
		list-style-type: none;
		padding: 0;
		
		.location-item {
			display: flex;
			justify-content: flex-start;
			position: relative;
			padding-top: 1.6rem;
			padding-bottom: 1rem;
			z-index: 2;
			cursor: pointer;
			
			&::after {
				content: "";
				display: inline-block;
				background-image: url(../img/divider-gray.svg);
				overflow: hidden;
				background-size: 300% 100%;
				height: .3rem;
				width: 100%;
				position: absolute;
				bottom: 0;
				left: 0;
			}
		}
		
		.location-icon {
			display: flex;
			flex-direction: column;
			text-align: center;
		}
		
		.icon-wrapper {
			border-radius: 50%;
			display: inline-block;
			width: 3rem;
			background: $green1300;
			height: 3rem;
			margin-bottom: 0;
			
			img {
				position: relative;
				top: 50%;
				transform: translateY(-50%);
				margin: 0 auto;
				max-width: 1.8rem;
			}
		}
		
		.icon-info {
			font-family: $primaryType !important;
			font-size: 1rem;
			font-weight: 500;
			line-height: 1;
		}
		
		.location-info {
			text-align: left;
			margin-top: .3rem;
			margin-left: 1.5rem;
			font-family: $primaryType;
			font-size: 1.5rem;
		}
		
		.location-name {
			display: inline-block;
			font-weight: 700;
			margin-bottom: .6rem;
		}
		
		.location-address {
			line-height: 1.33;
		}
		
		.location-hours {
			font-size: 1.2rem;
			font-weight: 500;
			line-height: 1.25;
			
			.status {
				color: $green400;
				
				&.closed {
					color: $gray800;
				}
			}
		}
	}
	
	.saved-places {
		margin-top: 4rem;
	}
	
	.secondary-title {
		font-size: 2.6rem;
		line-height: 1.15;
		font-weight: 700;
	}
	
	.saved-locations-wrapper {
		margin: 0;
		padding: 0;
	}
	
	.location-image {
		margin-left: 1rem;
	}
	
	.info-wrapper {
		justify-content: space-between;
	}
	
	.location-image-filled {
		display: none;
		margin-left: 1rem;
	}
	
	.saved-location {
		list-style-type: none;
		display: flex;
		position: relative;
		cursor: pointer;
		
		&::after {
			content: "";
			display: inline-block;
			background-image: url(../img/divider-gray.svg);
			overflow: hidden;
			background-size: 300% 100%;
			height: .3rem;
			width: 100%;
			position: absolute;
			bottom: 0;
			left: 0;
		}
		
		svg {
			display: flex;
			align-self: center;
		}
		
		
		&:focus,
		&:hover {
			svg {
				#locationSelected {
					fill: $green700;
				}
			}
		}
	}
	
	.saved-location-info {
		margin-left: 1rem;
		padding: 1.2rem 0 .8rem;
		display: flex;
		flex-direction: column;
		align-items: flex-start;
	}
	
	.saved-location-title {
		font-size: 1.3rem;
		font-weight: 700;
		line-height: 1.15;
	}
	
	.saved-location-address {
		font-size: 1.5rem;
		font-family: $primaryType;
		line-height: 1;
		color: $black;
		margin-top: .2rem;
		text-align: left;
	}
	
	.search-results {
		background-color: #fffcfc;
		padding: 0;
		list-style-type: none;
		margin: 0;
	}
	
	.autocomplete-result {
		font-family: $primaryType;
		text-align: left;
		line-height: 1;
		overflow: visible;
		padding: 1.2rem 2.1rem;
		cursor: pointer;
		display: block;
		font-size: 1.4rem;
		color: $black;
		font-weight: 400;
		text-decoration: none;
		&:not([data-ref]) {
			pointer-events: none;
		}
		
		&:focus,
		&:hover {
			background: $gray1100;
		}
		
		&:first-of-type {
			margin-top: 1.4rem;
		}
		
		&:last-of-type {
			margin-bottom: 1rem;
		}
	}
	
	.input-row {
		display: flex;
		justify-content: space-between;
		
		.input-wrapper {
			+ .input-wrapper {
				margin-left: 2rem;
			}
		}
	}
}
