.account-favorites-component {
  font-family: $primaryType;
  .account-locations-component {
    max-width: 66rem;
    margin: 0 auto 3.3rem;
    @media screen and (max-width: $xs-tablet) {
      max-width: 100%;
    }
    .title {
      font-size: 1.8rem;
      line-height: 1;
      margin-bottom: 2rem;
    }
    
    &.none-selected {
      max-width: 45.8rem;

      .location-container {
        display: block;
      }
    }
  }
}