.wide-card-component {
  margin-top: 3.5rem;
  max-width: 100%;
  margin-bottom: 2rem;

  @media screen and (max-width: $xs-tablet) {
    margin-top: 1rem;
  }

  .image-wrapper {
    display: block;
    width: 100%;
    max-width: 56.6rem;
    margin: 0 auto;

    img {
      max-width: 100%;
      border-top: 1rem solid $gold500;
      border-radius: 1rem 1rem 0 0;
      overflow: hidden;
    }
  }
  
  .wide-card {
    width: 100%;
    max-width: 51.6rem;
    position: relative;
    left: 50%;
    top: -3.6rem;
    transform: translateX(-50%);
    background-color: rgba($white, 0.95);
    padding: 1rem;
    border-radius: .6rem;
    box-sizing: border-box;
    text-align: center;

    @media screen and (max-width: $mid-mobile) {
      max-width: 32.5rem;
    }

    .card-frame {
      border: 0.2rem solid $gold600;
      border-radius: .6rem;
      padding: 2rem;
      height: inherit;
      display: flex;
      flex-direction: column;
      align-items: center;

    }

    .title {
      line-height: 1;
      max-width: 100%;
    }

    .card-content {
      max-width: 42.6rem;
      width: 100%;
      
      p {
        color: $black;
        margin: 1.6rem 0;
      }

      .primary-button {
        @media screen and (max-width: 900px) {
          padding: 1.2rem 2.3rem;
        }
      }
    }
  }

    //START themes
    &.gold-theme {
      .card-frame {
        border-color: $gold500;

      }

      .image-wrapper {
        img {
          border-color: $gold500;
        }
      }
    }
  
    &.blue-theme {
      .card-frame {
        border-color: $blue600;

      }
      
      .image-wrapper {
        img {
          border-color: $blue600;
        }
      }
    }
  
    &.mandarin-theme {
      .card-frame {
        border-color: $mandarin700;

      }
      
      .image-wrapper {
        img {
          border-color: $mandarin700;
        }
      }
    }
  
    &.mint-theme {
      .card-frame {
        border-color: $green1000;

      }
      
      .image-wrapper {
        img {
          border-color: $green1000;
        }
      }
    }
    //END themes
}