.member-badge-component {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: 15.3rem;
  min-height: 3.4rem; 
  background: url('../../../img/icons/greenvector.svg') center center no-repeat;
  background-size: 100% 100%;
  
  span {
    font-family: $primaryType;
    font-size: 1.4rem;
    font-weight: $font-weight-bold;
    letter-spacing: 0.02rem;
    color: $blue100;
  }
  
  //Modifier for gold member badge
  &.gold {
    min-width: 17.6rem;
    background: url('../../../img/goldmember.svg') center center no-repeat;
    background-size: 100% 100%;
  }
}
