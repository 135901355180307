.account-receipt-success-component {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 56.2rem;
  margin: 0 auto;
  text-align: center;

  .mjr-logo {
    margin-bottom: 4.5rem;
  }

  .title {
    color: $black;
    font-family: $primaryType;
    font-size: 1.8rem;
    font-weight: 700;
    line-height: 1.11;
    margin-bottom: 1rem;
  }

  .description {
    color: $black;
    font-family: $primaryType;
    font-size: 1.5rem;
    line-height: 1.33;
    margin-bottom: 6.8rem;
    text-align: left;
  }

  .primary-button {
    max-width: 30rem;
    width: 100%;
    margin: 0 auto 3rem;
    padding: 1.04rem 3.4rem;
  }
}