.fcs-loading {
  position: relative;
  cursor: default !important;

  * {
    opacity: .5;
    user-select: none;
    pointer-events: none;
  }

  &::after {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    max-height: 20rem;
    max-width: 20rem;
    background-image: url("../img/icons/jamba-swirl.gif");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: contain; 
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    border-radius: .6rem;
    overflow: hidden;
  }
}