.ada-notice-component {
  font-size: .8rem;
  font-family: $primaryType;
  color: $black;

  @media screen and (max-width: $xs-tablet) {
    left: 0%;
    width: 100%;
    padding-bottom: 4.2rem;
  }

  .icon-external {
    margin-right: .7rem;
  }
}