.generic-modal-component {
  position: fixed;
  margin: auto;
  font-family: $primaryType;
  color: $black;
  top: 0rem;
  left: 50%;
  width: 100%;
  transform: translateX(-50%);

  .modal-open .modal {
    overflow: visible;
  }

  @media screen and (max-width: $xs-tablet) {
    max-width: 34rem;
    margin: 0 auto;
  }
  
  &.right {
    .modal-dialog {
      right: 1.5rem;
      margin: 0 0 0 auto;
      @media screen and (max-width: $xs-tablet) {
        right: initial;
      }
    }
  }

  .modal-dialog {
    width: 100%;
    position: absolute;
    z-index: 2000;
    height: auto;
    text-align: center;
    left: 0;
    right: 0;
    top: 50%;
    transform: translateY(-50%)!important;
    @media screen and (max-width: $xs-tablet) {
      max-width: 34rem;
    }
    &.top {
      top: 0;
      transform: none!important;
    }
  }
  .modal-content {
    background: $green1300;
    padding: 0;
    border-radius: .6rem;
    
    @media screen and (max-width: $xs-tablet) {
     width: 100%;
    }
    .highcontrast &  {
      border: 1px solid $white;
    }
  }
  .modal-title {
    margin-top: 4.6rem;
    
    @media screen and (max-width: $xs-tablet) {
      font-size: 2.6rem;
      margin-top: 5.6rem;
    }
  }

  .modal-header {
    max-width: none;
  }

  .modal-body {
    padding: 2.4rem 6rem 2.2rem;
    margin: 0;
    max-width: none;
    
    @media screen and (max-width: $xs-tablet) {
     padding: 1.4rem 4rem 2.2rem
    }
  }

  .info {
    font-size: 1.8rem;
    line-height: 1.3;
    margin-bottom: 1.4rem;
    display: block;
    
    @media screen and (max-width: $xs-tablet) {
     font-size: 1.6rem;
    }
  }

  .close-wrapper {
    height: 0;
  }

  .close {
    top: 2.4rem;
    right: 2.4rem;
    border: none;
    z-index: 1;
  }

  .modal-footer {
    display: flex;
    background: $white;
    justify-content: space-between;
    padding: 1.5rem 0;

    a,
    button {
      font-size: 1.8rem;
    }

    a {
      margin-left: 4rem;

      @media screen and (max-width: $xs-tablet) {
        max-width: 11rem;
      }
    }

    .primary-button {
      max-width: 18rem;
      margin-right: 2rem;

      @media screen and (max-width: $xs-tablet) {
        min-width: 14rem;
      }
    }
  }

  &.promo {
    max-width: none;

    .modal-dialog {
      max-height: 100%;
      max-width: 90rem;
    }

    .modal-content {
      border-radius: 2rem;
      background: $white;
    }
  }
}

.edge {
  .generic-modal-component {
    left: 0;
    transform: none !important;

    &.promo {
      .modal-dialog {
        max-height: none;
        top: 0;
        transform: none !important;
      }
    }
  }
}

.highcontrast {
  .generic-modal-component {
    .modal-content {
      border: 1px solid $white;
    }
  }
}