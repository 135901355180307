@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?vb849f');
  src:  url('../fonts/icomoon.eot?vb849f#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?vb849f') format('truetype'),
    url('../fonts/icomoon.woff?vb849f') format('woff'),
    url('../fonts/icomoon.svg?vb849f#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-menu:before {
  content: "\e90b";
}
.icon-hamburger:before {
  content: "\e90a";
}
.icon-location:before {
  content: "\e901";
}
.icon-mail:before {
  content: "\e902";
}
.icon-reward:before {
  content: "\e903";
}
.icon-smoothie:before {
  content: "\e904";
}
.icon-arrow:before {
  content: "\e905";
}
.icon-bites:before {
  content: "\e906";
}
.icon-boosts:before {
  content: "\e907";
}
.icon-bowls:before {
  content: "\e908";
}
.icon-giftcard:before {
  content: "\e909";
}
.icon-external:before {
  content: "\e900";
}
