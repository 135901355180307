.location-services-component {
  margin-bottom: 2.4rem;

  @media screen and (max-width: $xs-tablet) {
    padding: 0;
  }

  &:before {
    content: "";
    background-image: url("../img/divider-orange.svg");
    overflow: hidden;
    height: 0.3rem;
    display: block;
    margin: 0 -4.4rem;

    @media screen and (max-width: $xs-tablet) {
      margin: 0 .6rem;
    }
  }

  .title {
    font-size: 2.4rem;
    font-weight: 700;
    margin-bottom: 0;
    margin-top: 3.2rem;

    @media screen and (max-width: $xs-tablet) {
      text-align: center;
      margin-bottom: 1rem;
      margin-top: 2.2rem;
    }
  }

  ul {
    padding-left: 1rem;
    list-style: none;
    margin: 0 0 2rem;

    @media screen and (max-width: $xs-tablet) {
      margin-bottom: 1rem;
      padding: 0 1rem;
    }

    li {
      display: inline-block;
      margin-right: 3.4rem;
      text-align: center;
      font-size: 1rem;
      letter-spacing: 0.5px;
      color: $black;
      font-weight: 700;
      font-family: $primaryType;
      margin-bottom: 1.4rem;

      @media screen and (max-width: $xs-tablet) {
        margin-right: 3rem;
      }

      &:last-child {
        margin-right: 0;
      }

      span {
        margin-top: .8rem;
        display: inline-block;
      }

      .image-wrapper {
        margin-right: .6rem;

        @media screen and (max-width: $xs-tablet) {
          margin: 0 auto;
        }

        img {
          max-height: 5.3rem;
          max-width: 5.3rem;
        }
      }
    }
  }
}