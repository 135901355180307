.news-footer-component {
  font-size: 1.3rem;
  font-family: $primaryType;

  h4 {
    font-family: $primaryType;
    font-weight: 500;
    font-size: 1.3rem;
  }

  .text-row {
    margin: 0 auto;
  }

  .info-wrapper {
    display: flex;
    flex-direction: column;
    
    &:before {
      content: '';
      background-image: url("../img/divider-orange.svg");
      background-repeat: no-repeat;
      margin: 2rem auto 0 0;
      overflow: hidden;
      background-size: 100%;
      height: 2rem;
      width: 100%;
      display: inline-block;
      
      @media screen and (max-width: $xs-tablet) {
        background-size: auto;
      }
    }
    &:last-of-type {
      margin-bottom: 5rem;

      @media screen and (max-width: $xs-tablet) {
        margin-bottom: 3rem;
      }

      &:after {
        content: none !important; 
      }
    }

    p {
      font-size: 1.3rem;
    }
  }

  a {
    text-decoration: underline;
    font-weight: 500;
  }
  
  sup {
    color: $green300
  }
}