.primary-footer-structure {
  .fcs-container {
    display: flex;
    margin-top: 4.2rem;
    margin-bottom: 4.6rem;
    align-items: center;
    justify-content: space-between;

    @media screen and (max-width: $xs-tablet) {
      flex-direction: column;
      margin-top: 3.4rem;
      margin-bottom: 2.6rem;
    }

    .site-logo-component {
      margin-right: 1.5rem;
      .show-scrolled {
        display: none;
      }
    }
  }
}