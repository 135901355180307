.account-rewards-offers-structure {
  background: $beige650;
  padding: 0 7.2rem 1.5rem;

  @media screen and (max-width: $large) {
    padding: 0 1.6rem 1.5rem;

    .empty-rewards {
      display: flex;
      justify-content: center;
    }
  }

  &.extra-padding-bottom {
    padding-bottom: 5.5rem;
    margin-bottom: -4rem;

    @media screen and (max-width: $xs-tablet) {
      padding-bottom: 1.5rem;
    }
  }

  .cta-rewards-component {
    margin: 4rem auto;

    &.bottom-divider {
      margin-bottom: 8.6rem;
    }

    .quaternary-button:hover,
    .quaternary-button:focus {
      color: $white;
      background: $green300;
    }

    .h6-like.title {
      margin-bottom: 0;
    }
  }

  .offers-component {
    padding: 0;

    .show-more-offers-link,
    .show-fewer-offers-link {
      background: none;
      border: none;
      font-size: 1.6rem;
      font-weight: 700;
      color: $green300 !important;
      text-align: center;
      margin: 2rem auto;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
      
      &::after {
        content: "";
        background-image: url("../img/green-arrow.png");
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        width: 1.4rem;
        height: 0.8rem;
        margin-left: 0.6rem;
      }

    }
    .show-fewer-offers-link {
      &::after {
        transform: translateY(-50%) rotateX(180deg);
      }
    }

    .offers-list {
      display: grid;
      grid-template-columns: 1fr 1fr;
      justify-content: center;
      position: relative;
      overflow: clip;
      max-height: 37rem;
      flex-wrap: wrap;
      gap: 1.6rem;
      margin: 0 auto;
      padding: 2rem;
      

      &.bottom-gradient {
        &::before {
          content: "";
          position: absolute;
          width: auto;
          height: 6rem;
          bottom: 0;
          right: 0;
          left: 0;
          z-index: 1;
          background-image: linear-gradient(to top, $beige650, rgba(246, 244, 238, 0));
        }
      }

    @media screen and (max-width: 1280px) {
      grid-template-columns: 1fr;
      max-height: 70rem;
      padding: 2rem 0;
    }

    &.fullHeight {
      max-height: 100%;
    }
  }

  .cta-rewards-component {
    margin-top: 6rem;
  }

  .empty-rewards {
    padding-top: 2rem;
    padding-bottom: 3rem;
  }
}

.visible {
  display: block;
}

.hidden {
  display: none;
}

.ios12,
.ios13,
.ios14,
.ios15 {
  .account-rewards-offers-structure .offers-component .offers-list {
    overflow: hidden;
  }
}
}