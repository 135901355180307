.basic-spot-component {
  text-align: center;
  padding-bottom: 1.5rem;
  width: 100%;

  .careers & {
    h4 {
      font-size: 3.0rem;
    }
  }
  
  @media screen and (max-width: $xs-tablet) {
    padding: 0 1.5rem 4.5rem;
    z-index: 1;
    position: relative;
  }

  &.constrained {
    max-width: 50%;
    margin: 0 auto;
    @media screen and (max-width: $xs-tablet) {
      max-width: 100%;
    }
  }

  &.padded {
    margin-bottom: 5rem;
  }

  @media screen and (max-width: $xs-tablet) {
    margin-top: 0 !important;
    margin-bottom: 0 !important;

    img {
      @media screen and (max-width: $xs-tablet) {
        margin-bottom: 2.2rem;
      }

      @media screen and (max-width: $xs-small-tablet) {
        width: 100vw;
        display: block;
        margin: 0 -3rem 2.2rem;
        max-width: none;
      }
    }

    img + .script-text {
      padding-top: 50vw;
      display: block
    }

    .fee-icon {
      margin: 0;
      width: auto;
    }
  }

  h4 {
    font-size: 4rem;
    
    @media screen and (max-width: $xs-tablet) {
      font-size: 3.2rem;
      max-width: 27.6rem;
      margin: 0 auto;
    }
  }

  .script-text {
    font-size: 3rem;
    color: $green300;
    line-height: 1;
    display: block;
  }

  h4 {
    line-height: 1;
    margin-bottom: 2.8rem;
  }

  a {
    &:hover {
      text-decoration: none;

      &:after {
        background-image: url("../img/triangle-green.svg");
      }
      &.primary-button:after, &.secondary-button:after {
        background-image: none;
      }
    }

    &.secondary-button {
      @media(max-width: $mid-mobile) {
        width: 80vw;
      }
      &:hover {
        color: $white;
      }
    }
    .fcs-third-party-icon {
      position: relative;
      display: inline;
      right: 0 !important;
      &:after {
        position: relative;
      }
    }
  }

  + .city-list-component {
    margin-top: 0;
  } 
}
.spot-header {
  font-size: 2.4rem;
  font-weight: 500;
  line-height: 1;
  margin-bottom: 2.8rem;
  
  @media screen and (max-width: 230px) { 
    word-wrap: break-word;
  }
}
