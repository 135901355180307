.size-modal-component {
  .modal-header {
    text-align: left;
    padding: 0 2.4rem;
  }
  .modal-body {
    text-align: center;
  }

  .modal-title {
    font-size: 2.6rem;
  }

  .modal-footer {
    background: $white;
    padding: 3.4rem 2.4rem;
    display: flex;
    @media screen and (max-width: $xs-tablet) {
      padding: 1.6rem 2rem;
    }
    .primary-button {
      width: 50%;
      font-weight: 700;
      font-size: 1.8rem;
      @media screen and (max-width: $xs-tablet) {
        min-width: 0;
      }
    }
    a {
      width: 50%;
      font-size: 1.8rem;
    }
  }

  .item-title {
    font-size: 1.6rem;
    font-weight: 500;
  }
 
  .size-selection-component {
    span {
      display: block;
      font-size: 1.4rem;
      font-weight: 500;
      margin-bottom: 1rem;
    }
    label {
      margin-right: 10.6rem;
      @media screen and (max-width: $xs-tablet) {
        margin-right: 4.2rem
      }
    }
    .text {
      margin: 0 auto 1rem;
    }
    .price,
    .cal {
      line-height: 1;
    } 
    .price {
      margin-bottom: .5rem;
      &::after {
        content:"";
        border-bottom: 1px solid $gold500;
        width: 1rem;
        display: block;
        text-align: center;
        margin: .5rem auto;
      }
    }
  }

}