.rte-component {  
  font-family: $primaryType;
  font-size: 1.5rem;
  line-height: 1.33;
  font-weight: 300;
  color: $gray500;

  .embed-wrapper {
    //this wrapper simplifies padding without ruining the embed-container ratio
    max-width: 100%;
    margin: 0 auto;
    
    .embed-container {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      max-width: 100%;
      height: auto;
      margin: 0 auto;
      z-index: 2;
      border-radius: 0.8rem;

      &:hover {
        .video-control {
          opacity: 1;
        }
      }
      
      iframe,
      object,
      embed {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: .8rem;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: black;
      }
    }
  }
  
  a {
    color: $green300;
    font-weight: 500;
    border-bottom: 1px solid;

    &:hover {
      text-decoration: none;
      text-shadow: -.25px -.25px 0 $green300, 
    .25px .25px $green300;
    }
    &.fcs-third-party {
      padding-right: 0.3rem;
      &:after {
          content: " \e900" !important;
          /* use !important to prevent issues with browser extensions that change fonts */
          font-family: 'icomoon' !important;
          speak: none;
          font-style: normal;
          font-weight: normal;
          font-variant: normal;
          text-transform: none;
          line-height: 1;
          background-image: none !important;
          font-size: 1rem;
          transform: translateY(-50%);
      
          /* Better Font Rendering =========== */
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
        }
    }
  }

  hr {
    border: none;
    background-image: url(../img/divider-orange.svg);
    background-repeat: no-repeat;
    margin: 2rem auto 0 0;
    overflow: hidden;
    background-size: 100%;
    height: 2rem;
    width: 100%;
    display: inline-block;
    
    @media screen and (max-width: $xs-tablet) {
      background-size: auto;
    }
  }

  img {
    width: auto;
    max-width: 100%;

    @media screen and (max-width: $xs-small-tablet) {
      max-width: 100vw;
      margin-left: -2rem;
      margin-right: -2rem;
    }
  }

  blockquote {
    font-size: 3.5rem;
    line-height: 1.14;
    font-family: $primaryType;
    
    &::before {
      content: '"';
    }

    &::after {
      content: '”';
    }
  }

  ul, ol {
    padding-left: 0;
    margin: 0 auto;
    max-width: 68.4rem;
    list-style: none;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
    display: inline-block;
    margin-bottom: 4rem;
    
    @media screen and (max-width: $xs-tablet) {
      padding-left: 2rem;
      margin-bottom: 2rem;
    }

    li {
      font-size: 1.5rem;
      line-height: 1.33;
      color: $black;
      font-family: $primaryType;
      font-weight: 300;

      &:before {
        content: "•";
        padding-right: .8rem;
        color: $green300;
      }
      
      & + li {
        margin-top: 2rem;
      }
    }
    strong {
      font-weight: 700;
    }
  }

  ol {
    list-style: none;
    counter-reset: my-awesome-counter;
  }
  ol li {
    counter-increment: my-awesome-counter;
  }
  ol li::before {
    content: counter(my-awesome-counter) ". ";
    color: $green300;
    font-weight: bold;
  }
}

.allergen-disclaimer {
  width: 100%;

  &.max-width-desktop {
    max-width: 116rem;
  }

  &.dropdown-menu {
    padding: 0 3rem 5rem 1.5rem;
  }
}
