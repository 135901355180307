.offer-checkout-component {
  min-height: 9.2rem;
  height: 100%;
  
  .info-text-wrapper {
    display: flex;
    flex-direction: column;
    padding-right: 3.6rem;
  }

  .offers-text {
    font-weight: 700;
    font-size: 1.5rem;
    margin-bottom: 0.4rem;
  }

  .expiry-info {
    color: $gray750;
    font-weight: 700;
    font-size: 1.2rem;
  }

  .img-wrapper {
    max-width: 3.2rem;
    margin-left: 0.7rem;

    @media screen and (max-width: $mobile) {
     margin-left: 2.7rem;
    }
  }

  .img-offer {
    min-width: 3.2rem;
    width: 100%;
  }

  .faded {
    opacity: 0.5;
  }

  .checkout-component.updated & {
    .label {
      font-family: $primaryType;
      color: $green350;
      position: relative;
      width: 100%;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 1.3rem;
      column-gap: 2rem;
      background: url('../../../img/offer-border.png') center center no-repeat;
      background-size: 100% 100%;
  
      @media screen and (max-width: $mobile) {
        column-gap: 3rem;
      }
  
      &::before,
      &::after {
        top: 2.3rem;
        right: 1.3rem;
        left: auto;
        border: .2rem solid $blue300;
        border-radius: .4rem;
      }
  
      &::before {
        @media screen and (max-width: $mobile) {
          top: 50%;
          right: 1.8rem;
        }
      }
  
      &::after {
        @media screen and (max-width: $mobile) {
          top: 50%;
          right: 1.8rem;
        }
      }
    }

    input[type="checkbox"]:checked + label::after {
      background: $white;
    }
  
    input[type="checkbox"]:checked + label {
      background: url('../../../img/offer-border-green.png') center center no-repeat;
      background-size: 100% 100%;
    }
  
    input[type="checkbox"]:focus + label {
      outline: none;
    }
  }
}