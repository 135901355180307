@font-face {
  font-family: 'Charleston';
  src: url('../fonts/charleston-regular-webfont.woff2') format('woff2'),
       url('../fonts/charleston-regular-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}

@font-face{
    font-family:"Filson Soft W03";
    src:url("../fonts/fc2a1dd0-9e0b-4771-9ad1-c44d9f4a89ab.eot?#iefix");
    src:url("../fonts/fc2a1dd0-9e0b-4771-9ad1-c44d9f4a89ab.eot?#iefix") format("eot"),url("../fonts/67243edf-db55-4c1d-92e2-ee4c33fa633b.woff2") format("woff2"),url("../fonts/de0124a9-743a-4757-bfff-c509c5970ee7.woff") format("woff"),url("../fonts/7dc9ee0c-f8e4-4631-843b-6153bccac5e5.ttf") format("truetype");
    font-weight: 400;
}
@font-face{
    font-family:"Filson Soft W03";
    src:url("../fonts/652ef45f-6fb7-4751-8028-76107d36a0e9.eot?#iefix");
    src:url("../fonts/652ef45f-6fb7-4751-8028-76107d36a0e9.eot?#iefix") format("eot"),url("../fonts/6d6a8331-b28c-4421-89df-2b3d63d5dfd6.woff2") format("woff2"),url("../fonts/70f59124-bb45-41a8-946b-425b647ccfb0.woff") format("woff"),url("../fonts/8ff2297c-392c-4f7e-8c0c-a02071488115.ttf") format("truetype");
    font-weight: 500;
}
@font-face{
    font-family:"Filson Soft W03";
    src:url("../fonts/84329372-da16-46bb-ba35-2f269957af88.eot?#iefix");
    src:url("../fonts/84329372-da16-46bb-ba35-2f269957af88.eot?#iefix") format("eot"),url("../fonts/2357f2d2-9956-4320-a9f2-b8e9d0919130.woff2") format("woff2"),url("../fonts/43d726ff-b2d5-42d5-8a94-f3f70b12625f.woff") format("woff"),url("../fonts/29f28e26-af2b-4163-b0c4-b61c2d7e54f4.ttf") format("truetype");
    font-weight: 700;
}
@font-face{
    font-family:"Filson W05 Medium";
    src:url("../fonts/5f050c4c-57dc-425c-b488-38f1cd19e750.eot?#iefix");
    src:url("../fonts/5f050c4c-57dc-425c-b488-38f1cd19e750.eot?#iefix") format("eot"),url("../fonts/b3cef5ac-3eac-4e9d-9113-5167b4222659.woff2") format("woff2"),url("../fonts/9b263d1f-d2f7-4c9a-985e-62af35b4f39d.woff") format("woff"),url("../fonts/f6047d54-723b-48cb-bea4-d14197125603.ttf") format("truetype");
}
@font-face{
    font-family:"Frontage Condensed W05 Outline";
    src:url("../fonts/16a30adb-3184-40c6-8cb1-1cf4d1a5b531.eot?#iefix");
    src:url("../fonts/16a30adb-3184-40c6-8cb1-1cf4d1a5b531.eot?#iefix") format("eot"),url("../fonts/7e8d5137-4192-4395-96d3-eb3648f41ec9.woff2") format("woff2"),url("../fonts/8bf29436-9817-4b37-856d-b12a72a817ba.woff") format("woff"),url("../fonts/9d18719b-c126-4f52-9b42-5812880d34ed.ttf") format("truetype");
}
@font-face{
  font-family:"Filson Soft W03";
  src:url("../fonts/FilsonSoftHeavy");
  src:url("../fonts/FilsonSoftHeavy") format("otf");
  font-weight: 900;
}



h1,
h2,
h3,
h4,
h5,
h6,
.h1-like,
.h2-like,
.h3-like,
.h4-like,
.h5-like,
.h6-like {
  font-family: $primaryType;
  font-weight: 500;
  font-size: 6.5rem;
  color: $black;
}

h2,
.h2-like {
  font-size: 5.2rem;
}

h3,
.h3-like {
  font-size: 4.0rem;
}

h4,
.h4-like {
  font-size: 3.6rem;
  font-family: $primaryType;
  font-weight: 500;
}

h5,
.h5-like {
  font-size: 3.0rem;
}

h6,
.h6-like {
  font-size: 2.4rem;
}

.iconlink {
  span {
    vertical-align: middle;
  }
}

a,
.generic-link {
  font-family: $primaryType;
  font-weight: 500;
  font-size: 1.5rem;
  line-height: 1.33;
  color: $green300;
  
  text-decoration: none;
  text-shadow: -.25px -.25px 0 transparent, 
                .25px .25px transparent;

  &:hover,
  &:focus {
    color: $green300;
    text-shadow: -.25px -.25px 0 $green300, 
    .25px .25px $green300;
  }
  
  &::after,
  &::before {
    text-shadow: none !important;
  }

  &.arrow {
    text-shadow: none !important;
  }
}

p,
.p {
  font-family: $primaryType;
  font-size: 1.5rem;
  line-height: 1.33;
  font-weight: 300;
  color: $gray500;
}

.triangle-link {
  font-size: 1.6rem;
  font-weight: bold;
  color: $black;
  &.green {
    color:  $green300;
    &:after {
      background-image: url("../img/triangle-green.svg");
    }
  }

  &.overflow-ellipsis {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    /*! autoprefixer: off */
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    word-break: break-word;
    padding-right: 1.5rem;

    &::after {
      position: absolute;
      right: 0;
      top: 0.6rem;
    }
  }

  &:after {
    content: "";
    background-image: url("../img/triangle.svg");
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    width: 0.7rem;
    height: 0.9rem;
    margin-left: 1rem;
  }

  &.disabled {
    pointer-events: none;
    opacity: .3;
  }
}

.script-text {
  font-family: $secondaryType;
}

sup {
  top: -0.5em;
  right: -0.3rem;
  display: inline-block !important;
  font-size: 0.6em !important;
}

.strikethrough {
  text-decoration: line-through;
}

.section-subtitle {
  color: $blue300;
  font-family: $primaryType;
  font-size: 1.2rem;
  text-transform: uppercase;
  font-weight: 700;
  letter-spacing: 0.048rem;
}

.arrow-link {
  &::after {
    content: "";
    background-image: url("../img/arrow-link.svg");
    background-size: contain;
    background-repeat: no-repeat;
    display: inline-block;
    width: 2rem;
    height: .8rem;
    margin-left: .6rem;
  }
}