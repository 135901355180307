.app-nav-component {
  text-align: center;
  margin-top: 5rem;
  margin-bottom: 4.4rem;

  @media screen and (max-width: $xs-tablet) {
      margin-top: 1rem;
  }

  .script-text {
    font-size: 1.6rem;
    color: $green300;
    line-height: 1;
    margin-right: 0.23em;
  }

  h4 {
    line-height: 1;
    margin-bottom: 2.8rem;
    font-size: 2.4rem;
  }

  .app-list {
    list-style-type: none;
    padding: 0;
    margin: 0;
    li {
      display: inline-block;
      margin: 2.2rem;
    }
  }

  p {
    a {
      border-bottom: .1rem solid;
      text-decoration: none;
      text-shadow: -.25px -.25px 0 transparent, 
                    .25px .25px transparent;

      &:hover,
      &:focus {
        color: $green300;
        text-shadow: -.25px -.25px 0 $green300, 
                      .25px .25px $green300;
      }
    }
  }
}
