.news-list-component {
	max-width: 975px;
	width: 100%;
	margin: 0 auto;
	padding-bottom: 2rem;

	@media screen and (max-width: $mid-mobile) {
		text-align: center;
	}

	ul {
		list-style-type: none;
		padding-left: 0;

		li {
			margin: 0 0 2rem 0;
			position: relative;
			@media screen and (max-width: $mid-mobile) {
				text-align: left;
      	}
					&:after {
						content: '';
						background-image: url("../img/divider-pale-blue.svg");
						background-repeat: no-repeat;
						overflow: hidden;
						background-size: 110%;
						height: 6px;
						width: 105%;
						display: inline-block;	 

						@media screen and (max-width: $xs-tablet) {
							background-size: 300%;
							margin-left: -2%;
						}
			}
      
      a {
        display: inline-block;
        width: 100%;
      }

      a:hover,
      a:focus {
				background-color: $blue600;
        text-decoration: none;
        outline: none;
			}

			.news-image {
				display: block;
				float: left;
        margin-right: 5rem;
        background: $white;

				-moz-box-shadow:   	5px 1px 2px -2px $gray1200;
  				-webkit-box-shadow: 5px 1px 2px -2px $gray1200;
  				box-shadow:         5px 1px 2px -2px $gray1200

				& img {
					width: auto;
					height: 150px;
				}
				@media screen and (max-width: $mid-mobile) {
					width: 80px;
					height: 204px;
					margin-right: 1.8rem;
						& img {
							width: auto;
							height: 80px;
							margin-top: calc(70%);
						}
				}
			}

			.news-list-text-wrap {
        padding-top: 2.4rem;
        overflow: hidden;
				& p {
					font-size: 3.2rem;

					@media screen and (max-width: $xs-tablet) {
						font-size: 4vw;
					}
					@media screen and (max-width: $mid-mobile) {
						font-size: 2.2rem;
						text-align: left;
					}
				}
			}

			.news-date {
				color: $black;
				font-size: 1.4rem;
				font-family: $primaryType;
				font-weight: 500;
			}
		}
	}
	.button-box {
		margin: 4rem auto 0 auto;
		display: flex;
		justify-content: center;
    	a {
	    	width: 190px;
	    	font-size: 1.8rem;
    	}

    	@media (max-width: $xs-tablet) {
	    	.primary-button {
	    		width: 80vw;
	    	}
    	}
	}
}