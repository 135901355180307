.error-component {
  display: flex;
  max-width: 100rem;
  margin: 0 auto;
  margin-top: 1rem;
  margin-bottom: 7.2rem;
  width: 100%;

  @media screen and (max-width: $xs-small-tablet) {
    flex-direction: column;
    margin-top: 0;
    margin-bottom: 4rem;
  }

  .image-wrapper {
    margin-right: 4.8rem;
    text-align: center;

    @media screen and (max-width: $xs-small-tablet) {
      margin-right: 0;
      margin-bottom: 3rem;
    }

    img {
      @media screen and (max-width: $xs-small-tablet) {
        max-width: 22.6rem;
      }
    }
  }

  .content-wrapper {
    max-width: 54rem;
    text-align: center;
    align-self: center;
  }

  .subtitle {
    font-size: 2.5rem;
    color: $black;
    line-height: 1.2;
    margin-bottom: 3rem;

    @media screen and (max-width: $xs-small-tablet) {
      font-size: 2.2rem;
      max-width: 29.6rem;
      margin: 0 auto;
      margin-bottom: 2.3rem;
    }
  }

  .h1-like {
    font-size: 4.5rem;
    line-height: 0.93;
    font-weight: bold;
    display: block;
    margin: 0 auto;
    margin-bottom: 4rem;
    max-width: 46rem;

    @media screen and (max-width: $xs-small-tablet) {
      font-size: 3.5rem;
      max-width: 32rem;
      margin-bottom: 2.3rem;
    }
  }

  .info {
    display: block;
    font-size: 2rem;
    line-height: 1.5;
    color: $black;
    margin-bottom: 4.7rem;

    @media screen and (max-width: $xs-small-tablet) {
      font-size: 1.8rem;
      margin-bottom: 2rem;
    }

    .back-to-home {
      font-size: 2rem;
    }
  }

  .btn-wrapper {
    a + a {
      margin-left: 4rem;

      @media screen and (max-width: $sml-desktop) {
        margin-left: 1rem;
      }

      @media screen and (max-width: $xs-small-tablet) {
        margin-left: 0;
        margin-top: 2rem;
      }
    }

    a {
      @media screen and (max-width: $xs-small-tablet) {
        width: 100%;
        padding: 1.8rem 0;
        max-width: 31.5rem;
        font-size: 1.8rem;
      }
    }
  }
}