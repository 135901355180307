.fcs-news-article {
  p {
    color: $black;
  }

  .newsroom-header-component + .rte-component {
    p {
      &:first-child {
        &:first-letter {
          font-size: 9.5rem;
          line-height: 1;
          font-weight: bold;
          initial-letter: 4;
          float: left;
          padding-right: 1rem; 
        }
      }
    }
  }
}

//image helpers
.fcs-center-image {
  margin: 0 auto; }

@media (min-width: $small-tablet) {
  .fcs-desk-img-sm {
    height: 190px;
    display: block;
    margin: 0 auto; } }

@media (min-width: $small-tablet) {
  .fcs-desk-img-med {
    height: 390px;
    display: block;
    margin: 0 auto; } }

@media (min-width: $small-tablet) {
  .fcs-desk-img-lg {
    height: 590px;
    display: block;
    margin: 0 auto; } }
