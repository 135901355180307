.generic-form {
	.input-wrapper {
		input[type="password"],
		input[type="email"],
		input[type="text"],
		input[type="number"] {
			font-weight: 400;
			margin-bottom: 2rem;
			
			&::placeholder {
				font-weight: 400;
			}
		}
	}
	
	.input-group {
		margin-bottom: 2rem;
		
		@media screen and (max-width: $xs-tablet) {
			margin-bottom: 3rem;
		}
	}
	
	.input-row {
		display: flex;
		
		.input-wrapper {
			&:first-child {
				width: 65%;
			}
			
			&:last-child {
				width: 35%;
				width: calc(35% - 1.5rem);
				margin-left: 1.5rem;
				text-align: left;
				
				.error-label {
					white-space: nowrap;
				}
			}
		}
		
		.error-label {
			margin-top: -2rem;
		}
	}
	
	.form-helpers {
		margin-top: .6rem;
		
		& + .submit {
			margin-top: 3.6rem;
		}
		
		.checkbox {
			text-align: left;
			
			label {
				display: inline-block;
			}
		}
	}
	
	.submit {
		margin-top: 2rem;
		display: block;
		
		@media screen and (max-width: $xs-tablet) {
			margin-top: 2.6rem;
		}
	}
	
	.input-wrapper {
		&.disabled {
			label,
			input {
				opacity: 0.5;
			}
			
			input {
				background-image: none;
			}
		}
	}
}

input[type="password"].valid,
input[type="email"].valid,
input[type="text"].valid,
input[type="number"].valid {
	background-image: url("../img/icons/input-valid.svg");
	background-repeat: no-repeat;
	background-position: 100%;
	padding-right: 2.4rem;
}

input[type="password"].error,
input[type="email"].error,
input[type="text"].error,
input[type="number"].error {
	background-image: url("../img/icons/input-invalid.svg");
	background-repeat: no-repeat;
	background-position: 100%;
	padding-right: 2rem;
}


input[type="password"],
input[type="password"]:hover,
input[type="password"]:focus,
input[type="email"],
input[type="email"]:hover,
input[type="email"]:focus,
input[type="text"],
input[type="text"]:hover,
input[type="text"]:focus,
input[type="number"],
input[type="number"]:hover,
input[type="number"]:focus {
	-webkit-text-fill-color: $black;
	-webkit-box-shadow: 0 0 0px 1000px $beige700 inset;
}

label {
	display: block;
	text-align: left;
	color: $gray700;
	font-size: 1rem;
	margin-bottom: .3rem !important;
}


.checkbox {
	position: relative;
	
	@media screen and (max-width: $xs-tablet) {
		padding: 0;      
	}
	
	input[type="checkbox"] {
		opacity: 0;
		position: absolute;
		
		&.error {
			& + label {
				margin-bottom: 2.9rem !important;
				padding-bottom: 0.3rem;
			}
		}
		
		&:invalid + label {
			background-image: url("../img/icons/input-invalid.svg");
			background-repeat: no-repeat;
			background-position: 100%;
			padding-right: 3rem;
		}
	}
	
	label {
		position: relative;
		padding-left: 3rem;
		cursor: pointer;
		font-size: 1.3rem;
		user-select: none;
		
		&::before,
		&::after {
			content: "";
			display: inline-block;
			cursor: pointer;
			height: 2.4rem;
			width: 2.4rem;
			border: .2rem solid $green300;
			background: $white;
			border-radius: .4rem; 
			top: 50%;
			transform: translateY(-50%);
			left: 0;
			position: absolute;
		}
		
		&::after {
			background-color: $green700;
			transform: translateY(-50%) scale(0);
			opacity: 0;
			background-image: none;
			content: url("../img/icons/checkmark-green.svg");
			padding: 0 0.4rem;
			line-height: 1.5rem;
		}
	}
	
	/*Adding focus styles on the outer-box of the fake checkbox*/
	input[type="checkbox"]:focus + label {
		outline: 2px solid $green300;
		outline-offset: 8px;
	}
	
	input[type="checkbox"]:checked + label {
		&::before {
			display: none;
		}
		&::after {
			animation: .2s scaleIn ease forwards;
			.safari & {
				animation: none;
				transform: translateY(-50%) scale(1);
				opacity: 1;
			}
		}
	}

	@keyframes scaleIn {
		0% {
			transform: translateY(-50%) scale(0);
			opacity: 0;
		}
		
		50% {
			transform: translateY(-50%) scale(1.1);
		}
		
		80% {
			transform: translateY(-50%) scale(.89);
		}
		
		100% {
			transform: translateY(-50%) scale(1);
			opacity: 1;
		}
	}
}

.error-list {
	padding: 0;
	list-style: none;
	text-align: left;
	margin: 2rem 0;
	
	li {
		font-family: $primaryType;
		font-size: 1.3rem;
		font-weight: 500;
		color: $red500;
		&::before {
			content: url("../img/icons/input-invalid.svg");
			display: inline-block;
			width: 1.3rem;
			height: 1.3rem;
			margin-right: .4rem;
			position: relative;
			top: .2rem;
		}
	}
}

.error-label {
	display: none;
	opacity: 0;
	position: absolute;
	color: transparent;
	top: 100%;
	left: 0;
	color: $red600;
	font-weight: 500;
	font-family: $primaryType;
	
	&[style*="opacity: 1;"] {
		display: block;
	}
}

.ui-datepicker {
	width: 21em;
	.ui-datepicker-title {
		select {
			margin: .1rem .1rem;
		}
	}
}

.backend-validation-block {
	text-align: left;
	display: none;
	
	.input-wrapper{
		margin-bottom: 0 !important;
	} 
	.backend-validation {
		opacity: 1;
		position: inherit;
		.error {
			text-align: left;
		}
	}
}

.log-in-helpers {
	margin-bottom: 2rem;
	padding-top: 2rem;
	
	.col:first-child {
		text-align: left;
	}
	
	.col:last-child {
		text-align: right;
	}
	
	a {
		font-weight: 500;
		text-decoration: underline;
		color: $black;
	}
	
	label {
		display: inline;
	}
}

.input-wrapper {
	position: relative;
	
	.placeholder {
		font-weight: 400;
		font-size: 1rem;
	}
}

input[type="password"],
input[type="email"],
input[type="text"],
input[type="number"]  {
	position: relative;
	pointer-events: all;
	height: 3rem;
	margin-bottom: 1.6rem;
	display: block;
	background-color: transparent;
	border-bottom: .2rem solid $green300;
	width: 100%;
	border-left: 0;
	border-right: 0;
	border-top: 0;
	font-family: $primaryType;
	font-size: 1.5rem;
	color: $black;
	@media screen and (max-width: $xs-tablet) {
		font-size: 1.6rem;
	}
	
	&.error {
		border-color: $red600;
	}
	
	&::-webkit-input-placeholder { /* Chrome/Opera/Safari */
		font-size: 1.4rem;
		color: $gray700;
		opacity: 1;
		font-weight: 300;
	}
	
	&::-moz-placeholder { /* Firefox 19+ */
		font-size: 1.4rem;
		color: $gray700;
		opacity: 1;
		font-weight: 300;
	}
	
	&:-ms-input-placeholder { /* IE 10+ */
		font-size: 1.4rem;
		color: $gray700;
		opacity: 1;
		font-weight: 300;
	}
	
	&:-moz-placeholder { /* Firefox 18- */
		font-size: 1.4rem;
		color: $gray700;
		opacity: 1;
		font-weight: 300;
	}
	
	&::-ms-clear {
		display: none;
	}
}

.placeholder {
	opacity: 1;
	transform: translateY(10%);
	transition: .2s ease all;
	
	&.active {
		opacity: 1;
		transform: translateY(0);
	}
}

.selectric-wrapper {
	width: 100%;
	
	.selectric {
		border: none !important;
		background: $green700;
		border-radius: 35px;
		padding: 1.1rem;
		
		.label {
			font-size: 1.8rem;
			font-weight: 700;
			color: $black;
			font-family: $primaryType;
			margin: 0;
			
			&:after {
				content: "";
				background-image: url("../img/triangle-green.svg");
				background-size: contain;
				background-repeat: no-repeat;
				display: inline-block;
				width: 0.7rem;
				height: 0.9rem;
				margin-left: 1rem;
				transform: rotateZ(90deg);
			}
		}
		
		.button {
			display: none;
		}
	}
	
	.selectric-items {
		border: 0;
		box-shadow: none;
		margin-top: 1.4rem;
		background: transparent;
		
		&:before {
			content: "";
			width: 0;
			height: 0;
			border-left: .9rem solid transparent;
			border-right: .9rem solid transparent;
			border-bottom: 1rem solid $green900;
			top: -1rem;
			position: absolute;
			left: 0;
			right: 0;
			margin: 0 auto;
		}
		
		.selectric-scroll {
			border-radius: 1rem;
			max-height: 32rem;
			
			li {
				background: $green900;
				color: $black;
				font-size: 1.5rem;
				font-weight: 400;
				border: 0;
				
				&.highlighted,
				&:hover,
				&:focus {
					background: $green300;
					color: $white;
					font-weight: 700;
				}
			}
		}
	}
	
	&.selectric-open {
		.label {
			&:after {
				transform: rotateZ(-90deg);
			}
		}
	}
}

.checkbox-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	
	.checkbox-wrapper {
		width: 15.6rem;
		margin: 1rem;
		
		input {
			margin: 0;
			padding: 0;
			opacity: 0;
			width: 0;
			height: 0;
			position: absolute;
			
			&:checked {
				+ label {
					background: $green600;
					
					.icon {
						display: inline-block;
						background-color: $green1000;
						position: relative;
						width: 1.6rem;
						height: 1.6rem;
						border-radius: 500px;
						vertical-align: middle;
						margin-right: .4rem;
						&::after {
							content: url("../img/icons/checkmark-green.svg"); 
							display: block;
							position: absolute;
							top: 0;
							left: .3rem;
							width: 1.1rem;
							line-height: 1rem;
						}
					}
				}
			}
		}
		
		label {
			font-size: 1.8rem;
			font-weight: 500;
			color: $black;
			text-align: center;
			border-radius: 1rem;
			padding: 1.5rem 1rem;
			background: $green1000;
			user-select: none;
			margin-bottom: 0 !important;
			cursor: pointer;
			
			.icon {
				display: none;
			}
		}
	}
}

.error-list {
	padding: 0;
	list-style: none;
	text-align: left;
	margin: 2rem 0;
	
	li {
		font-family: $primaryType;
		font-size: 1.3rem;
		font-weight: 500;
		color: $red500;
		
		&::before {
			content: url("../img/icons/input-invalid.svg");
			display: inline-block;
			width: 1.3rem;
			height: 1.3rem;
			margin-right: .6rem;
			position: relative;
			top: .4rem;
			transform: scale(.85);
		}
	}
}
