.fcs-cookie-component {
  position: fixed;
  top: auto;
  bottom: 1.8rem;
  left: 0;
  right: 0;
  z-index: 1000;
  background: rgba($white, 0.9);
  padding: 1.4rem 2.4rem;
  max-width: 137rem;
  width: calc(100% - 4.8rem);
  margin: 0 auto;
  display: none;
  justify-content: space-between;
  box-shadow: .2rem .2rem .6rem .2rem rgba(0,0,0,.3);
  align-items: center;

  @media screen and (max-width: $xs-tablet) {
    padding: 1.8rem 1.4rem;
    width: calc(100% - 1.4rem);
    flex-direction: column;
    text-align: center;
  }
 
  .rte-component {
    p {
      margin-bottom: 0;
      font-weight: 400;
      font-size: 1.5rem;

      @media screen and (max-width: $xs-tablet) {
        font-size: 1.4rem;
        line-height: 1.2;
      }

      a {
        font-size: 1.5rem;
        display: inline-block;

        @media screen and (max-width: $xs-tablet) {
          font-size: 1.4rem;
          line-height: 1.2;
        }
      }
    }
  }
 
  button {
    max-width: 11.4rem;
    width: 100%;
    text-transform: uppercase;
    font-size: 1.6rem;
    font-family: $primaryType;
    font-weight: 500;
    border-radius: 0;
    min-width: 0;
    margin-left: 2rem;
    padding: 1rem 3.4rem;
    transition: .2s ease background;
    flex-shrink: 0;

    @media screen and (max-width: $xs-tablet) {
      margin-top: 1.6rem;
      padding: 1rem;
      margin-left: 0;
    }
  }
}

.highcontrast {
  .fcs-cookie-component {
    border: 1px solid $white;
  }
}
