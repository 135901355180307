.start-order-component {
  .modal-body {
    p {
      font-size: 1.5rem;
      max-width: 27.6rem;
      width: 100%;
      margin: 0 auto;
    }
  }

  .start-order-form-wrapper {
    padding-top: 0;
    max-width: 31.5rem;
    margin: 0 auto;
    margin-bottom: 2.5rem;

    @media screen and (max-width: $xs-tablet) {
      margin-top: 1.5rem;
    }
  }
  
	.primary-button {
    display: block;
    width: 100%;
    cursor: pointer;
    border: 0;
    
    &:first-child {
      margin-top: 3.5rem;
    }

    &.facebook {
      @media screen and (max-width: $xs-tablet) {
        padding: 1.55rem 4rem 1.55rem 3.2rem;
        margin-top: 3rem;
      }
    }

    &.guest {
      background: $green700;
      color: $black;
      margin-top: 2rem;
    }
  }
  
  .separator-wrapper {
    max-width: 34.6rem;
    margin: 2rem auto;

    span {
      font-size: 1.5rem;
      font-size: 1.5rem;
      line-height: 1.33;
      font-family: $primaryType;
    }
  }
}
