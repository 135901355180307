.remove-card-component {
  .modal-body {
    padding: 2rem 3rem;
  }

  .modal-title {
    font-size: 2.6rem;
    margin-bottom: 2rem;
  }  

  .card-wrapper {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    &::after,
    &::before {
      content: '';
      background: url("../img/divider-pale-blue.svg") center center repeat-x;
      background-size: cover;
      display: block;
      width: 100%;
      height: .3rem;
      left: 0;
      right: 0;
      position: absolute;
    }

    &::after {
      bottom: 0;
    }

    &::before {
      top: 0;
    }
  }

  .card-logo {
    max-width: 2.7rem;
    margin-right: 2.4rem;

    @media screen and (max-width: $xs-tablet) {
      margin-right: 1rem;
    }
  }

  .card-number {
    font-size: 1.8rem;
    font-weight: 500;
  }

  .modal-footer {
    justify-content: center;

    .primary-button {
      min-width: 16rem;
      margin-right: 3rem;
    }

    a {
      margin-left: 0;
    }
  }
}