.order-timing-date-time-component {
	.order-timing-form-wrapper {
		padding-top: 0;
		margin-bottom: 2.5rem;
		max-width: 31.5rem;
		margin: 0 auto;
		
		@media screen and (max-width: $xs-tablet) {
			margin-top: 1.5rem;
		}
		
		.date-time-wrapper {
			display: flex;
			justify-content: center;
			align-items: center;
			margin: 2.4rem 0 1.2rem;

			.date-label {
				text-align: center;
			}
			
			.time-label {
				text-align: center;
			}
			
			.separator {
				font-size: 1.5rem;
				color: $black;
				line-height: 1.33;
				margin: 0 1rem;
			}
			
			.selectric {
				text-align: center;
				width: 100%;
				height: 6rem;
				background: $green700;
				background-image: url("../img/triangle-green-down.svg");
				background-size: .8rem 1rem;
				background-repeat: no-repeat;
				background-position: 93% center;
				border-radius: 35px;
				padding: 1.1rem 2rem 1.1rem 1.1rem;
				font-size: 1.8rem;
				font-weight: 700;
				color: $black;
				font-family: $primaryType;
				margin: 0;
				border: none !important;
				appearance: none;
				-webkit-appearance: none;
				-moz-appearance: none;
				-ms-appearance: none;
				-o-appearance: none;
				@media screen and (max-width: $xs-tablet) {
					padding: 1.1rem 1.8rem 1.1rem 0.6rem;
					font-size: 1.6;
					white-space: nowrap;
					overflow: hidden;
					text-overflow: ellipsis;
					padding-left: 3rem;
				}
				&::-ms-expand {
					display: none;
				}
				.edge & {
					&:hover,
					&:focus,
					&:active { 
						overflow: hidden;
						&::-ms-value {
							background: none;
						}
					}
				}
			}
		}
	}
	
	.primary-button {
		display: block;
		width: 100%;
		cursor: pointer;
		border: 0;
		margin-top: 3rem;
		margin-bottom: 1.5rem;
		
		&:first-child {
			margin-top: 2.6rem;
		}
		
		@media screen and (max-width: $xs-tablet) {
			padding: 1.8rem 0;
		} 
		
		&.facebook {
			@media screen and (max-width: $xs-tablet) {
				padding: 1.55rem 4rem 1.55rem 3.2rem;
				margin-top: 3rem;
			}
		}
		
		&.guest {
			background: $green700;
			color: $black;
			margin-top: 2rem;
		}
	}
	
	.separator-wrapper {
		max-width: 34.6rem;
		margin: 2rem auto;
		
		span {
			font-size: 1.5rem;
			font-size: 1.5rem;
			line-height: 1.33;
			font-family: $primaryType;
		}
	}
	.selectric-wrapper .selectric option {
		font-size: 1.6rem;
	}
}
