.account-highlight-component {
	background: $gold700;
	margin-bottom: 2rem;
	width: 100%;
	position: relative;
	margin-top: 1.6rem;
	padding: 2.1rem;
	text-align: center;
	&::before {
		content: '';
		display: block;
		height: 1.6rem;
		position: absolute;
		top: -1.6rem;
		background: $gold700;
		left: 0;
		width: 100%;
		clip-path: polygon(0% 0%, 1.3694% 1.0833%, 3.2733% 2.595%, 6.069% 4.833%, 8.413% 6.7166%, 10.6169% 8.4958%, 13.4025% 10.7583%, 15.7564% 12.6791%, 17.6635% 14.2416%, 19.4938% 15.7458%, 21.1192% 17.0833%, 22.5102% 18.2333%, 24.2671% 19.6916%, 25.641% 20.8333%, 28.516% 23.233%, 31.0169% 25.3166%, 32.935% 26.9208%, 34.89% 28.5625%, 36.681% 30.14583%, 37.9907% 31.15%, 40.7069% 33.3666%, 42.25564% 34.6583%, 43.27666% 35.49166%, 45.4728% 37.32916%, 48.1079% 39.5416%, 50.46923% 41.525%, 52.60717% 43.3208%, 55.38897% 45.6625%, 58.1010% 47.945%, 60.370% 49.858%, 62.739% 51.854%, 64.788% 53.583%, 67.073% 55.508%, 69.4005% 57.4708%, 71.530% 59.266%, 73.7343% 61.124%, 75.718% 62.791%, 78.064% 64.7703%, 81.227% 67.4416%, 83.7930% 69.608%, 86.5784% 71.9625%, 89.5176% 74.4624%, 91.2828% 75.966%, 93.185% 77.574%, 94.79564% 78.9375%, 96.6333% 80.4916%, 97.885% 81.55%, 98.5441% 82.1083%, 100% 83.3333%, 100% 100%, 0% 100%, 0% 0%);
	}
	strong,
	b {
		font-weight: bold;
	}
	
	.script-text {
		font-family: $secondaryType;
		font-size: 2.2rem;
		display: block;
		line-height: 0.82;
	}
	.title {
		font-family: $primaryType;
		font-weight: bold;
		line-height: 1.07;
		font-size: 2.8rem;
		margin-bottom: 0;
	}
	.product-zone {
		display: flex;
		flex-wrap: nowrap;
		text-align: right;
		align-items: flex-end;
		margin-bottom: 1.4rem;
		justify-content: center;
		img {
			width:100%;
			max-width: 50%;
		}
		.text-wrapper {
			padding-bottom: .5rem;
			padding-right: .5rem;
			@media screen and (max-width: $xs-tablet) {
				margin-right: 3rem;
			}
		}
	}
	hr {
		background: url('../img/divider-orange.svg') center center repeat-x;
		background-size: cover;
		height: .3rem;
		border: 0;
		margin: 2rem 0 2.4rem;
		width: 100%;
	}
	.highlight-footer {
		display: flex;
		justify-content: center;
		align-items: center;
		@media screen and (max-width: $xs-tablet) {
			flex-direction: column;
		}
		a {
			flex-basis: 100%;
			font-size: 1.8rem;
			max-width: 20rem;
			
			@media screen and (max-width: $xs-tablet) {
				display: block;
				&:first-child {
					margin-bottom: 3rem;
				}
			}
			
			&[data-ref="add-product"] {
				display: flex;
				justify-content: space-evenly;
			}
		}
	}
	.product-column {
		margin-right: -6rem;
		max-width: 34rem;
		@media screen and (max-width: $xs-tablet) {
			margin: 0;
			margin-left: 3rem;
		}
	}
	.content-column {
		margin-top: 4rem;
		span[class*="product"]  {
			font-size: 1.8rem;
		}
		@media screen and (max-width: $xs-tablet) {
			padding: 0;
		}
		p {
			@media screen and (max-width: $xs-tablet) {
				font-size: 1.8rem;
			}
		}
	}
	span[class*="product"] {
		font-family: $primaryType;
		font-size: 1.6rem;
		font-weight: 500;
	}
	.product-price {
		&+.product-info {
			position: relative;
			padding-left: 1.7rem;
			margin-left: 1.6rem;
			&:before {
				content: "";
				display: inline-block;
				border: 1px solid $gold500;
				height: 100%;
				vertical-align: middle;
				position: absolute;
				left: 0;
			}
		}
	}
	&.mint-theme {
		background: $green1000;
		&::before {
			background: $green1000;
		}
	}
	&.light-green-theme {
		background: $green1300;
		&::before {
			background: $green1300;
		}
	}
	&.mandarin-theme {
		background: $mandarin700;
		&::before {
			background: $mandarin700;
		}
	}
	&.gold-theme {
		background: $gold700;
		&::before {
			background: $gold700;
		}
	}
	&.blue-theme {
		background: $blue600;
		&::before {
			background: $blue600;
		}
	}
	
	&.wave-top {
		&:before {
			content: '';
			display: block;
			height: 1.6rem;
			position: absolute;
			top: -1.6rem;
			left: 0;
			-webkit-clip-path: polygon(0% 56.25%, 0.69085% 59.05%, 1.82342% 63.15625%, 3.313% 67.95625%, 4.65% 71.7875%, 5.981% 75.20625%, 6.87% 77.2875%, 7.9% 79.5%, 9.309% 82.19%, 10.279% 83.84375%, 11.691% 85.9%, 12.9523% 87.5%, 14.5028% 88.9%, 15.764% 89.5874%, 17.5441% 90.01875%, 19.101% 90.01875%, 20.436% 89.7625%, 22.734% 88.94375%, 24.5883% 88.0375%, 25.5238% 87.5%, 26.5904% 86.875%, 28.07% 85.83125%, 29.55% 84.64375%, 31.555% 82.83%, 33.332% 81.0625%, 34.7472% 79.55%, 36.8996% 77.10625%, 38.82209% 74.8%, 40.6047% 72.575%, 42.458% 70.1875%, 44.01% 68.13%, 45.86% 65.6375%, 47.868% 62.925%, 49.7832% 60.3062%, 51.359% 58.1437%, 52.987% 55.91874%, 54.85% 53.41875%, 56.173% 51.6375%, 57.798% 49.4819%, 60.0312% 46.59375%, 62.2857% 43.75%, 63.734% 41.949%, 66.106% 38.824%, 68.25% 35.80625%, 69.97% 33.3%, 71.968% 30.337%, 73.447% 28.125%, 75.149% 25.6249%, 76.195% 24.1375%, 78.57% 20.9375%, 80.3809% 18.75%, 81.9758% 16.875%, 83.9758% 14.4625%, 85.76% 12.2937%, 86.87% 10.9625%, 88.352% 9.24375%, 89.465% 7.9937%, 91.238% 6.25%, 92.5062% 5.1125%, 93.7659% 4.0625%, 94.6533% 3.3687%, 95.768% 2.55%, 97.1015% 1.64375%, 98.2899% 0.9125%, 100% 0%, 100% 100%, 0% 100%, 0% 56.25%);
			clip-path: polygon(0% 56.25%, 0.69085% 59.05%, 1.82342% 63.15625%, 3.313% 67.95625%, 4.65% 71.7875%, 5.981% 75.20625%, 6.87% 77.2875%, 7.9% 79.5%, 9.309% 82.19%, 10.279% 83.84375%, 11.691% 85.9%, 12.9523% 87.5%, 14.5028% 88.9%, 15.764% 89.5874%, 17.5441% 90.01875%, 19.101% 90.01875%, 20.436% 89.7625%, 22.734% 88.94375%, 24.5883% 88.0375%, 25.5238% 87.5%, 26.5904% 86.875%, 28.07% 85.83125%, 29.55% 84.64375%, 31.555% 82.83%, 33.332% 81.0625%, 34.7472% 79.55%, 36.8996% 77.10625%, 38.82209% 74.8%, 40.6047% 72.575%, 42.458% 70.1875%, 44.01% 68.13%, 45.86% 65.6375%, 47.868% 62.925%, 49.7832% 60.3062%, 51.359% 58.1437%, 52.987% 55.91874%, 54.85% 53.41875%, 56.173% 51.6375%, 57.798% 49.4819%, 60.0312% 46.59375%, 62.2857% 43.75%, 63.734% 41.949%, 66.106% 38.824%, 68.25% 35.80625%, 69.97% 33.3%, 71.968% 30.337%, 73.447% 28.125%, 75.149% 25.6249%, 76.195% 24.1375%, 78.57% 20.9375%, 80.3809% 18.75%, 81.9758% 16.875%, 83.9758% 14.4625%, 85.76% 12.2937%, 86.87% 10.9625%, 88.352% 9.24375%, 89.465% 7.9937%, 91.238% 6.25%, 92.5062% 5.1125%, 93.7659% 4.0625%, 94.6533% 3.3687%, 95.768% 2.55%, 97.1015% 1.64375%, 98.2899% 0.9125%, 100% 0%, 100% 100%, 0% 100%, 0% 56.25%);
			width: 100%;
		}
	}
	
	&.product {
		max-width: 37.4rem;
		width: 100%;
		padding: 2rem 1.5rem 2rem;
		margin: 0;
		margin-top: 1rem;
		margin-left: 6.6rem;
		
		@media screen and (max-width: $xs-tablet) {
			margin-left: 0;
			margin-top: 3rem;
		}
		
		&:not(.col) {
			margin-left: auto;
			margin-right: auto;
		}
		
		.product-zone {
			margin-bottom: 1.2rem;
			
			img {
				max-width: 13.2rem;
				margin-left: 1.2rem;
			}
		}
		
		hr {
			margin: 1rem 0 1.4rem;
		}
	}
	
	&.order-history {
		max-width: 37.4rem;
		width: 100%;
		padding: 2rem 2rem 2rem;
		margin: 0;
		margin-top: 1rem;
		margin-left: 1.5rem;
		
		@media screen and (max-width: $xs-tablet) {
			margin-left: 0;
			margin-top: 3rem;
			max-width: none;
		}
		
		&:not(.col) {
			margin-left: auto;
			margin-right: auto;
		}
		
		.order-item {
			.text-wrapper {
				display: flex;
				justify-content: center;
				align-items: center;
				margin-bottom: 2.38rem;
				
				.title {
					font-size: 2.8rem;
				}
			}
			.script-text {
				color: $green300;
				margin-top: 0.6rem;
				
				+ .title {
					margin-left: .4rem;
				}
			}
			
			.title {
				font-size: 1.5rem;
				line-height: 1;
			}
			
			.item-content {
				display: flex;
				position: relative;
				padding-top: 1.2rem;
				padding-bottom: 1.2rem;
				margin-bottom: 1.0rem;
				
				&:before {
					content: '';
					background: url("../img/divider-pale-blue.svg") center center repeat-x;
					background-size: cover;
					display: block;
					width: 100%;
					height: .3rem;
					left: 0;
					right: 0;
					top: 0;
					position: absolute;
					z-index: 1;
				}
				
				&:after {
					content: '';
					background: url("../img/divider-pale-blue.svg") center center repeat-x;
					background-size: cover;
					display: block;
					width: 100%;
					height: .3rem;
					left: 0;
					right: 0;
					bottom: 0;
					position: absolute;
					z-index: 1;
				}
				
				.triangle-link {
					display: flex;
					align-items: center;
					justify-content: center;
					color: $green300;
					font-size: 1.8rem;
					line-height: 0.89;
					
					&:after {
						margin-left: 0;
						background-image: url("../img/triangle-green.svg");
					}
				}
			}
			
			.item-details {
				font-size: 1.5rem;
				font-family: $primaryType;
				width: 100%;
				text-align: left;
				display: flex;
				flex-direction: column;
				
				@media screen and (max-width: $xs-tablet) {
					align-items: center;
					margin-left: 8rem;
				}
				
				.date {
					font-size: 1.2rem;
					line-height: 1.25;
					color: $black;
					margin-left: 2rem;
				}
				
				.location {
					font-size: 1rem;
					line-height: 1.5;
				}
				
				.product-list {
					list-style-type: none;
					padding: 0;
					margin: 1.0rem 0;
					li {
						line-height: 1.25;
						margin-bottom: 0.5rem;
					}
				}
				
				li {
					line-height: 1;
				}
				
				.item-extra {
					font-size: 1.2rem;
					line-height: 1.25;
				}
				
				.total-price {
					font-weight: 500;
					font-size: 1.4rem;
					line-height: 1.07;
				}
			}
			
			.card-footer {
				margin-top: 1rem;
				
				a {
					color: $green300;
				}
			}
		}
	}
}

.edge {
	.account-highlight-component {
		.highlight-footer {
			a {
				&[data-ref="add-product"] {
					justify-content: space-around;
				}
			}
		}
	}
}