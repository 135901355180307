.recent-orders-component {
	.modal-title {
		font-size: 3rem;
		margin-bottom: 2rem;
	}	
	.separator-wrapper,
	.title,
	.date,
	.quantity,
	.total-price,
	.pricing-info {
		font-family: $primaryType;
	}
	
	.separator-wrapper {
		margin: 1rem auto;
		font-size: 1.5rem;
		line-height: 1.33;
		color: $black;
		background-image: none;
	}
	
	.buttons-wrapper {
		max-width: 31.5rem;
		width: 100%;
		margin: 0 auto;
		margin-bottom: 3rem;
	}
	
	.item-info {
		display: flex;
		flex-direction: column;
		text-align: left;
	}
	
	.item-header {
		@media screen and (max-width: 414px) {
			display: flex;
			flex-direction: column;
		}
	}
	
	.pricing-info {
		display: inline-block;
		font-size: 1.5rem;
		color: $black;
		margin-bottom: 3rem;
		line-height: 1.33;
	}
	
	.recent-orders-list {
		margin-bottom: 2rem;
	}
	
	.recent-order-item {
		display: flex;
		justify-content: space-between; 
		align-items: center;
		position: relative;
		padding: 1rem 0;
		
		@media screen and (max-width: $xs-tablet) {
			align-items: flex-start;
		}
		
		&:before {
			content: "";
			display: inline-block;
			background-image: url("../img/divider-gray.svg");
			overflow: hidden;
			background-size: 150% 100%;
			height: 3px;
			width: 100%;
			position: absolute;
			top: 0;
		}
		
		&:last-of-type {
			&:after {
				content: "";
				display: inline-block;
				background-image: url("../img/divider-gray.svg");
				overflow: hidden;
				background-size: 150% 100%;
				height: 3px;
				width: 100%;
				position: absolute;
				bottom: 0;
				left: 0;
			}
		}
	}
	
	.modal-footer {
		max-width: 65rem;
		margin: 4rem auto 0;
		
		@media screen and (max-width: $xs-tablet) {
			margin: 2rem auto 0;
		}
	}
	
	.subtitle {
		font-weight: 700;
	}
	
	.title {
		font-size: 1.4rem;
		line-height: 1;
		color: $black;
		font-weight: 700;
		margin-bottom: .5rem;
		margin-right: 2rem;
		
		@media screen and (max-width: $xs-tablet) {
			margin-right: 1rem;
		}
	}
	
	.modal-body {
		padding: 0;
		margin-top: 3.4rem;
	}
	
	.date {
		display: inline-block;
		font-size: 1.2em;
		line-height: 1.15;
		color: $black;
	}
	
	.location {
		display: inline-block;
		font-family: $primaryType;
		font-size: 1rem;
		margin-bottom: .4rem;
	}
	
	.product-list {
		padding: 0;
		margin-bottom: .4rem;
		list-style: none;
		font-family: $primaryType;
		font-size: 1.5rem;
		
		@media screen and (max-width: $xs-tablet) {
			font-size: 1.4rem;
		}
	}
	
	.quantity {
		font-size: 1.2rem;
		line-height: 1.25;
		color: $black;
		font-weight: normal;
		margin-bottom: 0.4rem;
	}
	
	.total-price {
		font-family: $primaryType;
		font-size: 1.4rem;
		font-weight: 700;
	}
	
	.load-more {
		font-size: 1.8rem;
		line-height: 1;
		font-weight: 500;
		padding-top: 3rem;

		& a {
			font-size: 1.8rem;
			line-height: 1;
			font-weight: 500;
		}
	}
	
	.buttons-wrapper {
		text-align: center;
		max-width: 31.5rem;
		
		@media screen and (max-width: $xs-tablet) {
			margin-bottom: 3.8rem;
		}
	}
}
