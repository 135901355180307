.video-embed-component {
	width: 100%; 
	text-align: center;
  padding-bottom: 3rem;

  &.gold-theme {
    .organic-shape {
      background: $gold500;
    }
  }

  &.blue-theme {
    .organic-shape {
      background: $blue600;
    }
  }

  &.mandarin-theme {
    .organic-shape {
      background: $mandarin700;
    }
  }

  &.mint-theme {
    .organic-shape {
      background: $green1000;
    }
  }
  //END themes

  .video-control {
    position: absolute;
    background: url('../img/icons/pause-white.svg') center center no-repeat;
    background-size: contain;
    width: 7.8rem;
    height: 7.8rem;
    left: 50%;
    top: 50%;
    z-index: 3;
    transform: translate(-50%, -50%);
    cursor: pointer;
    transition: .2s ease all;
    opacity: 0;
    outline: none;

    a {
      font-size: 0;
    }

    &.video-paused {
      background-image: url('../img/icons/play-white.svg');
      opacity: 1;
    }

  }

  .organic-container {
    width: 100%;
    max-width: 74.5rem;
    margin: 0 auto;
    position: relative;
  }

  .organic-shape {
    background: $mandarin700;
    position: absolute;
    top: 4rem;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
    clip-path: polygon(4.2723631508678235% 8.620689655172415%, 45.52736982643525% 3.7356321839080464%, 88.25100133511349% 0.28735632183908044%, 99.19893190921229% 0.28735632183908044%, 99.86648865153538% 2.0114942528735633%, 99.86648865153538% 87.64367816091954%, 99.73297730307075% 98.85057471264368%, 99.06542056074767% 100%, 87.44993324432578% 99.42528735632183%, 82.51001335113484% 98.85057471264368%, 75.9679572763685% 97.98850574712644%, 73.69826435246995% 97.98850574712644%, 66.48865153538051% 96.83908045977012%, 57.409879839786385% 96.26436781609196%, 54.47263017356475% 96.26436781609196%, 46.86248331108144% 95.97701149425288%, 38.31775700934579% 95.6896551724138%, 24.699599465954606% 95.11494252873564%, 6.408544726301736% 94.82758620689656%, 0.26702269692923897% 93.10344827586206%, 0.13351134846461948% 87.64367816091954%, 0.13351134846461948% 15.804597701149426%, 0.26702269692923897% 10.919540229885058%, 1.335113484646195% 9.195402298850574%, 4.2723631508678235% 8.620689655172415%);
  }

  .embed-wrapper {
    //this wrapper simplifies padding without ruining the embed-container ratio
    max-width: calc(100% - 6rem);
    margin: 0 auto;
    
    @media screen and (max-width: $xs-tablet) {
      max-width: calc(100% - 3.2rem);
    }
    
    .embed-container {
      position: relative;
      padding-bottom: 56.25%;
      height: 0;
      overflow: hidden;
      max-width: 100%;
      height: auto;
      margin: 0 auto;
      transform: translateY(-4rem);
      z-index: 2;
      border-radius: 0.8rem;

      @media screen and (max-width: $xs-tablet) {
        transform: translateY(-5.2083vw);
      }

      &:hover {
        .video-control {
          opacity: 1;
        }
      }
      
      iframe,
      object,
      embed {
        position: absolute;
        top: 0;
        left: 0;
        border-radius: .8rem;
        width: 100%;
        height: 100%;
        overflow: hidden;
        background: black;
      }
    }
  }
}

.ios,
.android {
  .video-embed-component {
    .video-control {
      &:not(.video-paused) {
        &:hover,
        & {
          opacity: 0;
        }
      }
    }
  }
}