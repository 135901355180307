.rewards-offers-checkout-structure {
  position: relative;
  background: $beige650;
  max-width: 55rem;
  margin: 0 auto;
  padding: 2rem 1.5rem;
  
  &::before {
    content: "";
    display: block;
    position: absolute;
    background: $beige650;
    top: -2rem;
    left: 0;
    right: 0;
    width: 100%;
    height: 2.1rem;
    clip-path: polygon(0% 51.2%, 1.644% 43.292%, 2.2954% 40.376%, 3.54948% 35.047200000000004%, 4.28104% 32.1064%, 7.2522% 21.44%, 12.76% 8%, 23.28% 0%, 25.668000000000003% 1.9440000000000002%, 26.112800000000004% 2.5328%, 27.796% 5.3136%, 28.6976% 7.12%, 30.2142% 10.590399999999999%, 35.45344% 24.416%, 37.06144% 29.504799999999996%, 39.165440000000004% 36.287200000000006%, 46.16544% 56.9944%, 52.92% 68%, 58.2192% 69.6704%, 63.4972% 69.04560000000001%, 69.30239999999999% 66.3064%, 76.9552% 60.763999999999996%, 80.4% 58.39919999999999%, 87.83999999999999% 56.79919999999999%, 95.96000000000001% 68.7992%, 98.4% 78.3992%, 100% 89.5992%, 100% 99.9992%, 0% 99.9992%, 0% 51.2%);

    @media screen and (max-width: $xs-tablet) {
      height: 2.6rem;
      top: -2.5rem;
      clip-path: polygon(8.533333333333333% 7.6923076923076925%, 11.270133333333334% 8.75%, 14.319200000000002% 12.3%, 16.6072% 16.7%, 18.933333333333334% 23.076923076923077%, 21.329600000000003% 30.611538461538462%, 26.29333333333333% 52.35384615384615%, 29.78906666666667% 63.38076923076923%, 33.55493333333334% 73.32307692307693%, 38.8224% 83.62692307692308%, 43.09440000000001% 88.41538461538462%, 46.61226666666666% 89.43846153846154%, 51.44773333333333% 85.3%, 54.93333333333334% 76.92307692307693%, 58.13333333333334% 66.32692307692308%, 66.49440000000001% 40.17307692307692%, 73.49946666666666% 22.284615384615382%, 79.2% 11.538461538461538%, 82.14666666666666% 7.557692307692308%, 84.42133333333332% 5.130769230769231%, 87.6776% 2.4923076923076923%, 100% 0%, 100% 100%, 0% 100%, 0% 26.923076923076923%, 2.0138666666666665% 16.430769230769233%, 6.330933333333332% 9.684615384615384%, 8.533333333333333% 7.6923076923076925%);
    }
  }
  
  &::after {
    content: "";
    display: block;
    position: absolute;
    background: $beige650;
    bottom: -1.9rem;
    left: 0;
    right: 0;
    width: 100%;
    height: 2rem;
    clip-path: polygon(0% 0%, 100% 0%, 100% 61.904761904761905%, 98.66666666666667% 52.38095238095239%, 96.09833333333334% 46.03809523809523%, 92.78566666666667% 39.304761904761904%, 88.569% 31.91428571428571%, 83.62833333333333% 24.700000000000003%, 80.599% 21.023809523809526%, 78.54283333333333% 18.86190476190476%, 74.80266666666667% 15.633333333333333%, 70.39116666666666% 13.071428571428573%, 66.011% 11.990476190476189%, 61.69783333333334% 12.528571428571428%, 61.2035% 12.7%, 56.339666666666666% 15.771428571428569%, 53.18966666666666% 19.247619047619047%, 49.33433333333333% 25.3952380952381%, 46.451% 31.60952380952381%, 40.898% 48.61428571428571%, 37.59366666666667% 62.96190476190476%, 36% 71.42857142857143%, 31.229999999999997% 90.49999999999999%, 29.241833333333332% 94.78571428571429%, 27.58% 97.1952380952381%, 20.616666666666667% 100%, 13.414666666666667% 95.6857142857143%, 12.452833333333334% 94.3809523809524%, 8.799999999999999% 87.4904761904762%, 7.418333333333332% 83.96190476190478%, 5.819833333333333% 79.10000000000001%, 4.953166666666667% 76.04285714285714%, 3.6365000000000003% 70.65714285714286%, 2.833333333333333% 66.66666666666666%, 2.566666666666667% 65.4047619047619%, 1.9166666666666665% 61.27619047619047%, 1.3556666666666666% 56.36190476190477%, 0.4556666666666666% 43.028571428571425%, 0.0125% 25.71428571428572%, 0% 0%);
  
    @media screen and (max-width: $xs-tablet) {
      height: 3rem;
      bottom: -3rem;
      clip-path: polygon(0% 0%, 100% 0%, 100% 76.66666666666667%, 94.81973333333333% 70.97333333333334%, 89.20506666666667% 65.74666666666667%, 82.90053333333334% 60.88%, 73.87706666666666% 55.82999999999999%, 64.26666666666667% 53.33%, 57.06213333333333% 54.06333333333334%, 53.36266666666667% 55.559999999999995%, 47.6544% 59.55999999999999%, 41.3344% 66.65333333333334%, 37.15813333333333% 72.48333333333333%, 32.266666666666666% 83.33333333333334%, 29.66026666666667% 91.26666666666667%, 26.78586666666667% 97.30666666666666%, 24.738666666666663% 99.43%, 22.933333333333334% 100.00333333333333%, 19.284266666666667% 100.08666666666666%, 16% 100%, 13.146666666666665% 99%, 10.1408% 96.06666666666666%, 7.885866666666666% 92.71%, 5.374133333333333% 87.86333333333334%, 2.1058666666666666% 79.80000000000001%, 0% 73.33333333333333%, 0% 0%);
    }
  }

  .disclaimer {
    color: $gray750;
    font-size: 1.2rem;
    text-align: center;
    font-weight: 400;
    letter-spacing: 0.02rem;
    line-height: 1.5;
  }
}