.radial-progress-component {
  position: relative;
  font-size: 21.5rem;
  flex-shrink: 0;

  @media screen and (max-width: $xs-tablet) {
    font-size: 19.4rem;
    max-width: 19.3rem;
  }

  .radial-progress {
    $circle-size: 1em;
    $circle-background: $blue800;
    $circle-color: $gold500;
    $inset-size: 0.8372em;
    $inset-color: $white;
    $transition-length: 1s;
    width: $circle-size;
    height: $circle-size;
    transform: rotate(-180deg);
    position: relative;

    &:before {
      content: "";
      background: $circle-background;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-radius: 50%;
      overflow: hidden;
    }

    .highcontrast & {
      background: transparent;
      
      &:before {
        content: url('../img/account/gray-square.svg');
        background: transparent;
        line-height: 100%;
      }
    }
 
    .circle {
      .progress-mask,
      .fill {
        width: $circle-size;
        height: $circle-size;
        position: absolute;
        display: block;
        background: transparent;
        border-radius: 500px;
        width: 100%;
        height: 100%;
        overflow: hidden;
      }
     
      .progress-mask,
      .fill {
        -webkit-backface-visibility: hidden;
        transition: -webkit-transform $transition-length;
        transition: -ms-transform $transition-length;
        transition: transform $transition-length;
      }
      
      .progress-mask {
        clip: rect(0px, $circle-size, $circle-size, $circle-size/2);
        overflow: hidden;
        background: transparent;
    
        .fill {
          clip: rect(0px, $circle-size/2, $circle-size, 0px);

         
          &::after {
            content: "";
            background: $circle-color;
            display: block;
            height: 100%;
            width: 100%;
            
            .highcontrast & {
              content: url('../img/account/yellow-square.svg');
              line-height: 100%;
              position: relative;
              background: transparent;
              transform: scale(1.4);
            }
            .edge & {
              height: 40rem;
              width: 40rem;
            }
          }
        }
      }
    }
    .inset {
      width: $inset-size;
      height: $inset-size;
      position: absolute;
      margin-left: ($circle-size - $inset-size)/2;
      margin-top: ($circle-size - $inset-size)/2;
      background-color: $inset-color;
      border-radius: 50%;
    }
    $i: 0;
    $increment: 180deg / 100;
    @while($i <=100) {
      &[data-progress="#{$i}"] {
        .circle {
          .progress-mask.full,
          .fill {
            -webkit-transform: rotate($increment * $i);
            -ms-transform: rotate($increment * $i);
            transform: rotate($increment * $i);
          }
          .fill.fix {
            -webkit-transform: rotate($increment * $i * 2);
            -ms-transform: rotate($increment * $i * 2);
            transform: rotate($increment * $i * 2);
          }
        }
      }
      $i: $i+1;
    }
    $i: 0;
    @while($i <=8) {
      &[data-progress="#{$i}"] {
        & + .progress-icon {
          &:after {
            content: url('../img/account/cup-state-0.svg');
            position: absolute;
            line-height:0;
            display: block;
            top: 50%;
            left: 50%;
            transform: translateY(-43%) translateX(-50%);
            width: 0.26em;
          }
        }
      }
      $i: $i+1;
    }
    $i: 9;
    @while($i <=20) {
      &[data-progress="#{$i}"] {
        & + .progress-icon {
          &:after {
            content:  url('../img/account/cup-state-1.svg');
            position: absolute;
            line-height:0;
            display: block;
            top: 50%;
            transform: translateY(-42%) translateX(-58%);
            left: 50%;
            width: 0.33em;
          }
        }
      }
      $i: $i+1;
    }
    $i: 21;
    @while($i <=41) {
      &[data-progress="#{$i}"] {
        & + .progress-icon {
          &:after {
            content:  url('../img/account/cup-state-2.svg') ;
            position: absolute;
            line-height:0;
            display: block;
            top: 50%;
            transform: translateY(-39%) translateX(-62%);
            left: 50%;
            width: 0.34em;
          }
        }
      }
      $i: $i+1;
    }
    $i: 42;
    @while($i <=62) {
      &[data-progress="#{$i}"] {
        & + .progress-icon {
          &:after {
            content: url('../img/account/cup-state-3.svg');
            position: absolute;
            line-height:0;
            display: block;
            top: 50%;
            transform: translateY(-50%) translateX(-63%);
            left: 50%;
            width: 0.34em;
          }
        }
      }
      $i: $i+1;
    }
    $i: 63;
    @while($i <=83) {
      &[data-progress="#{$i}"] {
        & + .progress-icon {
          &:after {
            content:  url('../img/account/cup-state-4.svg') ;
            position: absolute;
            line-height:0;
            display: block;
            top: 50%;
            transform: translateY(-50%) translateX(-54%);
            left: 50%;
            width: 0.4em;
          }
        }
      }
      $i: $i+1;
    }
    $i: 84;
    @while($i <=100) {
      &[data-progress="#{$i}"] {
        & + .progress-icon {
          &:after {
            content: url('../img/account/cup-state-5.svg');
            position: absolute;
            line-height:0;
            display: block;
            top: 50%;
            transform: translateY(-50%) translateX(-48%);
            left: 50%;
            width: 0.44em;
          }
        }
      }
      $i: $i+1;
    }
  }
  .progress-icon {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;
  }
  .points-wrapper {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
    bottom: 0;
    -webkit-perspective: 100px;
    /* Safari 4-8 */
    perspective: 100px;
    font-size: 1em;
    line-height: 0.13em;
    white-space: nowrap;
    display: flex;
    align-items: baseline;
    .bg-helper {
      position: absolute;
      top: -0.08em;
      left: -.08em;
      bottom: -0.08em;
      right: -.08em;
      background: $white;
      transform: rotateX(45deg);
      z-index: 1;
    
    }
    .current-points {
      color: $black;
      font-family: $primaryType;
      font-weight: 500;
      font-size: 0.09em;
      position: relative;
      z-index: 1;
      &::after {
        content: '/';
      }
      @media screen and (max-width: $xs-tablet) {
        font-size: 0.1116em;
      }
    }
    .target-points {
      color: $black;
      font-family: $primaryType;
      font-weight: 500;
      font-size: 0.08em;
      position: relative;
      z-index: 1;
      &::after {
        content: ' pts';
      }
      @media screen and (max-width: $xs-tablet) {
        font-size: 0.074em;
      }
    }
  }
}

.ios,
.safari {
  .radial-progress-component {
    .points-wrapper{
      bottom: 1rem;
      padding:1rem;
      .bg-helper {
        transform: rotateX(45deg) translateZ(-20px);
      }
      span{
        top: 1.6rem;
      }
    }
  }
}
