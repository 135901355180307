.account-qr-component {
  padding-right: 2rem;
  margin-bottom: 6.6rem;
  position: relative;
  padding-top: 1.5rem;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 0.3rem;
    background-image: url(/img/account/divider-horizontal.svg);
    background-repeat: no-repeat;
    background-size: contain;
    background-position: center center;
    top: 0;
    left: 0;
    right: 0;
  }

  .title {
    font-size: 1.8rem;
    color: $black;
    line-height: 1;
    margin-bottom: 2rem;
  }
  
  .qr-wrapper {
    max-width: 14.6rem;
  }

  p {
    font-size: 1.5rem;
  }
}