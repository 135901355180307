.fee-component {
  position: relative;
  display: flex;
  align-items: flex-start;
  font-size: 1.4rem;
  font-weight: normal;

  &.center {
    justify-content: center;
  }

  &.mt-12 {
    margin-top: 1.2rem;
  }

  &.mt-20 {
    margin-top: 2rem;
  }

  &.mt-30 {
    margin-top: 3rem;
  }

  &.mt-40 {
    margin-top: 4rem;
  }

  &.ml-20 {
    margin-left: 2rem;
  }

  &.mb-30 {
    margin-bottom: 3rem;
  }

  &.mb-60 {
    margin-bottom: 6rem;
    
    @media screen and (max-width: $xs-tablet){
      margin-bottom: 3rem;
    }
  }

  &.white-bg {
    background: $white;
  }

  &.mlr-auto {
    margin-left: auto;
    margin-right: auto;
  }

  &.mw-550 {
    max-width: 55rem;

    @media screen and (max-width: $xs-tablet){
      max-width: 32rem;
    }
  }

  &.m-ml-0 {
    @media screen and (max-width: $xs-tablet){
      margin-left: 0;
    }
  }

  &.pb-24 {
    padding-bottom: 2.4rem;
  }

  .pb-50 {
    padding-bottom: 5rem;
  }

  &.plr-15 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  &.plr-20 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .fee-text {
    position: relative;
    padding-left: 0.2rem;
    padding-right: 0 !important;
    margin-bottom: 0;
    font-size: 1.5rem !important;
    line-height: 1.2 !important;
    margin-top: 0 !important;
    text-align: left;
  }

  .fee-icon {
    width: 1.8rem;
    height: 1.8rem;
    margin-top: -1px;
  }

  &.spot {
    align-items: flex-start;

    @media screen and (max-width: $xs-tablet) {
      justify-content: center;
    }
  }
}

.info-fee-wrapper {
  display: flex;
  align-items: center;

  @media screen and (max-width: $xs-tablet){
    flex-direction: column;
    margin-bottom: 2.6rem;
  }
}

.sticky-button-wrapper {
  &.fee {
    @media screen and (max-width: $xs-tablet){
      padding-bottom: 4rem;
      
      .fee-component {
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 1rem;
        left: 0;
        right: 0;
        margin: 0 !important;
        padding: 1rem 1.5rem 0;
      }
    }

    @media screen and (max-width: 372px){
      padding-bottom: 6rem;
    }
  }
}

.basket-modal-component {
  &.fee {
    .modal-footer {
      display: flex;
      flex-direction: column;
    }
  }
}

.basic-spot-component {
  &:has(+ .fee-component) {
    @media screen and (max-width: $xs-tablet){
      padding: 1rem;
    }
  }
}

.customize-modal-component,
.customize-active-modal-component {
  .modal-body {
    &:has(+ .fee-component) {
      padding-bottom: 0;
    }
  }
}

.customize-active-modal-component {
  .modal-body {
    &:has(+ .fee-component) {
      a {
        margin-bottom: 2rem;
      }
    }
  }
}

.account-highlight-component {
  .fee-component {
    margin-bottom: 1rem;
  }
}

.account-interior-structure {
  .fee-component {
    @media screen and (max-width: $xs-tablet){
      padding-top: 2rem;
    }
  }
}

.account-card-component {
  .fee-component {
    margin-bottom: 1rem;
  }
}

.dispatch-delivery-component {
    .fee-component {
        & + .btn-group{
            margin-top: 0rem;
        }
    }
}