.account-menu-component {
  &.wired-mobile{
    display: block;
     @media screen and (min-width: $small-tablet) {
       display: none;
     }
     &+.account-menu-component {
        ul {
          .item {
            a {
              &::before {
                display: block;
              }
            }
          }
        }
        @media screen and (max-width: $xs-tablet) {
          display: none;
        }
     }
  }
  .account-progress-component {
    .radial-progress-component {
      @media screen and (max-width: $xs-tablet) {
        display: block;
      }
    }
  }
  .title {
    font-size: 2.6rem;
    padding-left: 2.5rem;
    font-weight: bold;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin: 2rem 0 4rem;

    @media screen and (max-width: $xs-tablet) {
      margin-bottom: 1.1rem;
    }

    .item {
      position: relative;

      &:first-child {
        &::before {
          content: '';
          background: url('../img/divider-pale-blue.svg') center center repeat-x $white;
          background-size: cover;
          display: block;
          width: 100%;
          height: .3rem;
          left: 0;
          right: 0;
          top: 0;
          position: absolute;
          z-index: 1;
        }
      }

      &::after {
        content: '';
        background: url('../img/divider-pale-blue.svg') center center repeat-x;
        background-size: cover;
        display: block;
        width: 100%;
        height: .3rem;
        left: 0;
        right: 0;
        bottom: 0;
        position: absolute;
        z-index: 1;
      }

      & > a {
        display: flex;
        align-items: center;
        text-decoration: none;
        transition: .2s ease background;
        background: $white;
        padding: 1.2rem 1.2rem 1.7rem;
        position: relative;
        min-height: 6rem;
        &.has-notification {
          background: $mandarin600;
        }

        &.triangle-link {
          &::after {
            position: absolute;
            right: 2rem;
          }
        }
      }

      &.active {
        &::before {
          content: '';
          background: url('../img/divider-orange.svg') center center repeat-x $white;
          background-size: cover;
          display: block;
          width: 100%;
          height: .3rem;
          left: 0;
          right: 0;
          top: -0.2rem;
          position: absolute;
          z-index: 1;
        }
        &:first-child {
          &::before {
            background-color: $green1300;
          }
        }
        &::after {
          background: url('../img/divider-orange.svg') center center repeat-x $green1300;
        }

        a {
          background-color: $green1300;
        }

        .item-title {
          color: $green300;
        }

        .triangle-link {
          &::after {
            background-image: url('../img/triangle-green.svg');
          }          
        }
      }

      .item-title {
        color: $black;
        display: block;
        font-size: 1.8rem;
        font-weight: 500;
      }

      .item-description {
        color: $black;
        display: block;
        font-size: 1.3rem;
        font-weight: normal;
        margin-top: -0.2rem;
        display: block;
      }

      .item-notification {
        background: $mandarin500;
        color: $black;
        font-size: .9rem;
        font-weight: bold;
        border-radius: 500px;
        display: inline-block;
        width: 1.8rem;
        height: 1.8rem;
        text-align: center;
        line-height: 1.8rem;
        vertical-align: middle;
        align-self: flex-start;
        margin-top: .4rem;
        margin-right: .5rem;
      }

      .item-icon {
        padding-right: .8rem;
        align-self: flex-start;
      }
    }
       ul {
      .item {
        &:first-child {
          &::before {
            display: none;
          }
          a {
            padding-top: .9rem;
            &::before {
              content: "";
              clip-path: polygon(0% 44.444%, 0.274% 45.55%, 0.6933% 47.1777777%, 1.0864% 48.611111%, 1.47493333% 50%, 1.85866666% 51.266666%, 2.3090666% 52.733333%, 2.8157333% 54.299999%, 3.24186666% 55.555555%, 3.61306666% 56.6666666%, 4.1562666% 58.177777%, 4.6864% 59.5555555%, 5.2312% 60.933333%, 5.8741333% 62.4777777%, 6.5368000% 63.9888888%, 7.2178666% 65.444444%, 7.8797333% 66.777777%, 8.6584% 68.244444%, 9.3805333% 69.5%, 10.1805333% 70.777777%, 10.9208000% 71.888888%, 11.5637333% 72.755555%, 12.3045333% 73.433333%, 13.1200000% 74.144444%, 13.880266% 74.788888%, 14.7386666% 75.477777%, 15.8133333% 76.255555%, 16.5266666% 76.666666%, 18.2493% 77.499%, 19.4666% 77.777%, 20.0408% 77.777%, 21.689% 77.466%, 22.764% 77.088%, 24.412% 76.266%, 26.2722% 75.022%, 27.8477% 73.755%, 29.1416% 72.577%, 31.0754% 70.611%, 33.0168% 68.388%, 34.731% 66.288%, 36.521% 63.9555%, 38.3112% 61.5%, 40.606% 58.1666%, 42.5445% 55.2666%, 44.0557% 52.9555%, 45.6304% 50.5%, 47.7088% 47.166%, 49.4981% 44.288%, 51.221% 41.5%, 53.522% 37.777%, 55.455% 34.655%, 57.095% 32.033%, 58.8136% 29.311%, 60.8181% 26.2000%, 62.398% 23.7999%, 64.402% 20.844%, 65.98% 18.62222%, 67.34559% 16.766666%, 68.55626% 15.177777%, 70.42533% 12.855555%, 72% 11.11111%, 72.79386% 10.277777%, 75.23226% 8.4%, 76.66666% 7.777777%, 78.53093% 7.4444444%, 80.61093% 7.722222%, 82.76453% 8.733333%, 85.20346% 10.755555%, 86.92426% 12.744444%, 88.14266% 14.433333%, 89.50506% 17.6%, 90.86213% 21%, 92.2192% 24.444444%, 93.58906% 27.944444%, 94.81173% 31.077777%, 95.5928% 33.08888%, 96.3256% 34.97777%, 97.02106% 36.76666%, 97.5688% 38.17777%, 98.10666% 39.54444%, 98.74933% 41.21111%, 99.20666% 42.4%, 99.86133% 44.1%, 100% 44.44444%, 100% 100%, 0% 100%, 0% 44.4444%);
              height: .9rem;
              width: 100;
              display: block;
              background: $white;
              bottom: 100%;
              transition: .2s ease background;
              left: 0;
              right: 0;
              position: absolute;
              z-index: 1;
            }

            &.has-notification {
              &::before {
                background: $mandarin600;
              }
            }
          }
        }

        &.active {
          a {
            &::before {
              background: $green1300;
            }
          }
        }
      }
    }
  }
    ul {
      .item {
        &:first-child {
          &::before {
            display: none;
          }
          a {
            padding-top: .9rem;
            &::before {
              content: "";
              clip-path: polygon(0% 44.444%, 0.274% 45.55%, 0.6933% 47.1777777%, 1.0864% 48.611111%, 1.47493333% 50%, 1.85866666% 51.266666%, 2.3090666% 52.733333%, 2.8157333% 54.299999%, 3.24186666% 55.555555%, 3.61306666% 56.6666666%, 4.1562666% 58.177777%, 4.6864% 59.5555555%, 5.2312% 60.933333%, 5.8741333% 62.4777777%, 6.5368000% 63.9888888%, 7.2178666% 65.444444%, 7.8797333% 66.777777%, 8.6584% 68.244444%, 9.3805333% 69.5%, 10.1805333% 70.777777%, 10.9208000% 71.888888%, 11.5637333% 72.755555%, 12.3045333% 73.433333%, 13.1200000% 74.144444%, 13.880266% 74.788888%, 14.7386666% 75.477777%, 15.8133333% 76.255555%, 16.5266666% 76.666666%, 18.2493% 77.499%, 19.4666% 77.777%, 20.0408% 77.777%, 21.689% 77.466%, 22.764% 77.088%, 24.412% 76.266%, 26.2722% 75.022%, 27.8477% 73.755%, 29.1416% 72.577%, 31.0754% 70.611%, 33.0168% 68.388%, 34.731% 66.288%, 36.521% 63.9555%, 38.3112% 61.5%, 40.606% 58.1666%, 42.5445% 55.2666%, 44.0557% 52.9555%, 45.6304% 50.5%, 47.7088% 47.166%, 49.4981% 44.288%, 51.221% 41.5%, 53.522% 37.777%, 55.455% 34.655%, 57.095% 32.033%, 58.8136% 29.311%, 60.8181% 26.2000%, 62.398% 23.7999%, 64.402% 20.844%, 65.98% 18.62222%, 67.34559% 16.766666%, 68.55626% 15.177777%, 70.42533% 12.855555%, 72% 11.11111%, 72.79386% 10.277777%, 75.23226% 8.4%, 76.66666% 7.777777%, 78.53093% 7.4444444%, 80.61093% 7.722222%, 82.76453% 8.733333%, 85.20346% 10.755555%, 86.92426% 12.744444%, 88.14266% 14.433333%, 89.50506% 17.6%, 90.86213% 21%, 92.2192% 24.444444%, 93.58906% 27.944444%, 94.81173% 31.077777%, 95.5928% 33.08888%, 96.3256% 34.97777%, 97.02106% 36.76666%, 97.5688% 38.17777%, 98.10666% 39.54444%, 98.74933% 41.21111%, 99.20666% 42.4%, 99.86133% 44.1%, 100% 44.44444%, 100% 100%, 0% 100%, 0% 44.4444%);
              height: .9rem;
              width: 100;
              display: block;
              background: $white;
              bottom: 100%;
              transition: .2s ease background;
              left: 0;
              right: 0;
              position: absolute;
              z-index: 1;
            }
            &.has-notification {
              &::before {
                background: $mandarin600;
              }
            }
          }
        }
        &.active {
          a {
            &::before {
              background: $green1300;
            }
          }
        }
      }
    }
 
  &+.account-menu-component {
    margin-top: 2rem;
    ul {
      margin-top: 1rem;
      .item{
        a {
          &::before {
            display: none;
          }
        }
      }
    }
  }
}