.account-earned-component {
  position: relative;
  font-family: $primaryType;
  max-width: 34.5rem;
  width: 100%;

  &:before {
    content: '';
    background: url("../img/divider-pale-blue.svg") center center repeat-x;
    background-size: auto;
    background-size: cover;
    display: block;
    width: 100%;
    height: .3rem;
    left: 0;
    right: 0;
    top: -1.8rem;
    position: absolute;
    z-index: 1;
  }

  .title {
    font-size: 1.8rem;
    color: $black;
    line-height: 1;
    margin-bottom: 1.6rem;
  }

  ul {
    list-style-type: none;
    padding: 0;
    margin-bottom: 4rem;
    li {
      background: url('../img/account/headline-bg.svg') center center no-repeat;
      background-size: 100% 100%;
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      margin-bottom: 2.5rem;
      min-height: 6rem;
      align-items: center;
      background-size: 100% 100%;
      padding: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  a {
    font-size: 1.8rem;
  }

  .text-wrapper {
    flex-basis: 100%;
  }

  .item-icon {
    padding-right: .4rem;
    width: 4.3rem;
    flex-shrink: 0;
  }

  .item-title {
    font-size: 1.5rem;
    display: block;
    line-height: 1;
  }

  .info {
    display: block;
    font-size: 1.5rem;
    margin-bottom: 2rem;
  }

  .item-info {
    font-size: 1.2rem;
    display: block;
  }
}