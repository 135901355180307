.log-in-component {
  .log-in-form-wrapper {
    padding-top: 0;
    max-width: 31.5rem;
    margin: 0 auto;

    @media screen and (max-width: $xs-tablet) {
      margin-top: 1.5rem;
    }

    input {
      font-weight: 400;
      margin-bottom: 1rem;

      &::placeholder {
        font-weight: 400;
      }
    }

    label {
      font-family: $primaryType;
    }
  }

  .recaptcha-disclaimer {
    padding-top: 3rem;
    font-size: 1.2rem;
    font-family: $primaryType;
    font-weight: 500;
    line-height: 1.17;
    color: $gray900;

    .note {
      display: block;
      margin: 0;
    }

    a {
      font-size: 1.2rem;
      font-weight: 600;
      white-space: nowrap;

      .fcs-third-party-icon {
        right: .4rem;
      }
    }
  }
  
	.primary-button {
    font-family: $primaryType;
    margin-top: 2rem;
    display: block;
    width: 100%;
    cursor: pointer;
    border: 0;

    @media screen and (max-width: $xs-tablet) {
      margin-top: 4rem;
      font-size: 1.6rem;
    } 

    &.facebook {
      @media screen and (max-width: $xs-tablet) {
        padding: 1.55rem 4rem 1.55rem 1rem;
        margin-top: 3rem;
      }
    }

    &.guest {
      background: $green700;
      color: $black;
      margin-top: 2rem;

      &:hover {
        background: $gold500;
      }

      &:focus {
          background: $gold500;
        }
    }
  }
  
  .separator-wrapper {
    max-width: 34.6rem;
    margin: 2rem auto;
    background-image: url("../img/divider-gray.svg");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 350%;

    span {
      font-size: 1.5rem;
      font-size: 1.5rem;
      line-height: 1.33;
      font-family: $primaryType;
    }
  }

  .info {
    font-family: $primaryType;
    font-size: 1.5rem;
    color: $black;
    line-height: 1;
    padding: 0 3rem;
    display: inline-block;
    margin-top: .6rem;

    @media screen and (max-width: $xs-tablet) {
      padding: 0 2rem;
    }

    a {
      font-size: 1.8rem;
      margin-left: 1rem;
      line-height: 1;
    }
  }

  .log-in-helpers {
    padding-top: 1rem;
    margin-bottom: 1rem;
  }
}

.success-show, .fail-show, .already-in-show {
  display: none;
  background-color: $cream;

  @media screen and (max-width: $xs-tablet) {
    width: 100%;
    height: auto;
  }

  img {
    max-height: 15.9rem;
    margin-top: 1rem;
    margin-bottom: 1rem;

    @media screen and (max-width: $xs-tablet) {
      margin-bottom: 5rem;
      margin-top: 2rem;
    }
  }

  .message-title {
    font-family: $primaryType;
    font-weight: 500;
    font-size: 3.2rem;
    color: $green300;
  }

  .primary-button {
    @media screen and (max-width: $xs-tablet) {
      margin-top: 3.8rem;
    }
  }
}
