
// Styles for the feature flag

.disable-loyalty {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 3rem;
}

.section-disabled-loyalty {
  .section-title {
    margin-bottom: 6rem;
  }
}