.taxes-fees-component {
    .modal-title,
    .fee-title,
    .primary-button {
        text-transform: lowercase;
    }

    .modal-body {
        text-align: left;
        font-size: 1.5rem;
    } 
    
    .fee-wrapper {
        display: flex;
        justify-content: space-between;
        width: 100%;
        margin-bottom: .8rem;
    }

    .info {
        text-align: center;
    }

    .modal-footer {
        justify-content: center;
    }
}