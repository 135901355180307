.location-rte-component {
  margin-bottom: 2.4rem;

  @media screen and (max-width: $xs-tablet) {
    padding: 0 1rem;
  }

  &:before {
    content: "";
    background-image: url("../img/divider-orange.svg");
    overflow: hidden;
    height: 0.3rem;
    display: block;
    margin: 0 -4.4rem;

    @media screen and (max-width: $xs-tablet) {
      margin: 0 0 0 -0.6rem;
    }
  }

  .rte-component {
    margin-top: 3.4rem;
    margin: 3.4rem 0;
    text-align: center;
  }
}