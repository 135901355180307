.qr-code-component {
    margin-bottom: 3rem;

    .img-wrapper {
        width: 20rem;
        height: 20rem;
        border-radius: 10%;
        background-color: $white;
        border: 1px solid $gray1150;
        margin: 0 auto 2rem;
        padding: 1.5rem;
    }

    .qr-img {
        min-width: 100%;
        max-width: 100%;
    }

    .info {
        max-width: 33.4rem;
        text-align: center;
        font-size: 1.6rem;
        color: $blue100;
        margin: 0 auto;
        font-weight: 500;
        letter-spacing: 0.02rem;
    }
}