.breadcrumb-component {
  margin: 1.2rem;
  text-transform: lowercase;
  ol {
    padding: 0;
    margin: 0;
    display: flex;
    li {
      list-style-type: none;
      margin-right: .5rem;
      text-decoration: none;
      font-size: 1.2rem;
      line-height: 1.33;
      color: $black;
      font-weight: 300;
      font-family: $primaryType;
      vertical-align: middle;
      @media (max-width: $xs-tablet) {
        display: none;
        &:nth-last-child(2) {
          display: inline-block;
          &:before {
            content: "";
            background-image: url("../img/icons/arrow-left.svg");
            background-size: contain;
            background-position: center center;
            background-repeat: no-repeat;
            margin-right: 0.5rem;
            margin-left: -1.5rem;
            vertical-align: middle;
          }
        }
      }
      &+li {
        &:before {
          content: "";
          background-image: url("../img/icons/arrow-right.svg");
          background-size: contain;
          background-position: center center;
          background-repeat: no-repeat;
          margin-right: .5rem;
          width: .5rem;
          height: 1.6rem;
          display: inline-block;
          cursor: initial;
          vertical-align: middle;
        }
      }
      a {
        text-decoration: none;
        font-size: 1.2rem;
        line-height: 1.33;
        color: $black;
        font-weight: 300;
        padding-left: .6rem;
        &:hover{
          color: $green300;
          text-decoration: underline;
        }
      }
    }
  }
}