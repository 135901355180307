.info-modal-component {
  .modal-header,
  .modal-body {
    max-width: 50rem;
    margin: 0 auto;
  }

  .modal-content {
    .modal-body {
      p {
        font-size: 1.5rem;
        line-height: 1.33;
        color: $black;
      }
    }
  }

  .modal-title {
    margin-bottom: 1rem;

    @media screen and (max-width: $xs-tablet) {
      margin-top: 5.4rem;
    }
  }

  .details-wrapper {
    font-size: 0;
    font-family: $primaryType;
    margin-bottom: 2.4rem;

    span {
      font-size: 1.5rem;
      line-height: 1.33;
      color: $black;
      display: inline-block;

      & + span {
        margin-left: 1.1rem;
        padding-left: 1.1rem;
        border-left: 1px solid $gold500;
      }
    }
  }

  .image-section {
    padding: 1rem;
    margin-bottom: 2.4rem;

    @media screen and (max-width: $xs-tablet) {
      margin: 4.4rem -2.8rem 2.4rem;
    }

    .image-wrapper {
      img {
        max-height: 11rem;
      }
    }
  }

  ul {
    text-align: left;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
    max-width: 26rem;

    &:last-of-type {
      margin-bottom: 2.5rem;
    }
  }

  button {
    max-width: 31.4rem;
    margin: 2.6rem 0;
  }

  .custom-list {
    margin: 0 auto 2.4rem;
    text-align: left;
    display: inline-block;
  }
}

[data-open="info-modal-component"] {
  &:hover {
    cursor: pointer;
  }
}