.intro-component {
    .title {
        font-size: 2.4rem;
        font-weight: 700;
        color: $blue100;
        margin-bottom: 1.2rem;
    }

    .description {
        height: 100%;
        font-size: 1.6rem;
        color: $blue100;
        letter-spacing: 0.02rem;
    }

    &.green-theme {
        .title {
            font-size: 2.4rem;
            color: $green350;
            letter-spacing: 0.05rem;
            margin-bottom: 0.8rem;

            @media screen and (max-width: $mobile) {
                font-size: 2.2rem;
            }
        }

        .description {
            font-size: 1.5rem;
            font-weight: $font-weight-normal;
            letter-spacing: 0.02rem;
            color: $gray750;

            @media screen and (max-width: $mobile) {
                font-size: 1.4rem;
            }
        }
    }

    &.text-center {
        text-align: center;
    }
}

