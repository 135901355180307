.location-form-component {
  @media screen and (max-width: $xs-tablet) {
    margin-left: -1.5rem;
    margin-right: -1.5rem;
  }
    
  .search-bar {
    display: flex;
    align-content: center;
    width: 100%;
    margin-bottom: 1.4rem;
    padding-right: 3rem;

    @media screen and (max-width: 1160px) {
      flex-wrap: wrap;
      padding-right:0;
    }
    
    @media screen and (max-width: $xs-tablet) {
      flex-wrap: wrap;
    }
  }
  .location-form-wrapper {
    width: 100%;
    max-width: 77rem;
    position: relative;


    &.error {
      input[type="text"] {
        border-color: $red600;
        padding-right: 5rem;
      }
      small {
        display: inline-block;
        
      }
      .location-form-search{
        &:before  {
          content : url("../img/icons/input-invalid.svg"); 
          display: block;
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          right: 20.5rem;
          z-index: 1;
          line-height: 1;
          @media screen and (max-width: $xs-tablet) {
            right: 12.5rem;
          }
        }
      }
    }
    small {
      color: $red600;
      padding-left: 1.8rem;
      font-size: 1rem;
      position: absolute;
      bottom: -.2rem;
      display: none;
      font-family: $primaryType;
      font-weight: 500;
      @media screen and (max-width: $xs-tablet) {
        bottom: 2rem;
      }
    }
  }
  .location-form-search {
    width: 100%;
    max-width: 77rem;
    display: flex;
    position: relative;
    margin-bottom: 1.5rem;
    &:after {
      content: "";
      position: absolute;
      pointer-events: none;
      right: 4.8rem;
      top: 50%;
      background: url("../img/drop.svg") center center no-repeat;
      display: inline-block;
      width: 2rem;
      height: 2rem;
      z-index: 2;
      -ms-transform: translateY(-50%);
      transform: translateY(-50%);
      margin-top: 0.2rem;
    }
    @media screen and (max-width: $xs-tablet) {
      margin-bottom: 3.5rem;
      &:after {
        right: .3rem;
      }
    }

    .placeholder {
      position: absolute;
      top: -2rem;
      margin-left: 2.5rem;

      &.active {
        &+input[type="text"]::placeholder {
          color: transparent;
        }
      }
    }
    input[type="text"] {
      padding-left: 2.5rem;
      border-radius: 3.5rem;
      border: solid 2px $green300;
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
      height: 4.5rem;
      width: calc(100% - 17rem);
      background: $white;
      margin: 0;
      @media screen and (max-width: $xs-tablet) {
        height: 6rem;
        width: calc(100% - 8.5rem);
      }
    }
  }
  .custom-select {
    appearance: none;
    text-align: center;
    text-align-last: center;
    border-radius: 3.5rem;
    height: 4.5rem;
    font-size: 1.6rem;
    font-family: $primaryType;
    font-weight: 700;
    border: solid 1px $green300;
    background-color: $green300;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -2.5rem;
    color: $white;
    width: 100%; 
    max-width: 17rem;
    order: 1;
    display: flex;
    align-items: center;
    position: relative;
    @media(max-width: $xs-tablet) {
      width: 11.5rem;
      height: 6rem;
    } 

    &:hover,
    &:focus {
      background: $gold500;
      border-color: $gold500;
      color: $black;
    }
    &::-ms-expand {
      display: none;
    }
    .edge & {
        padding-left: 5rem;
        &:hover,
        &:focus,
        &:active { 
          overflow: hidden;
          &::-ms-value {
            background: none;
         }
       }
    }
  }
  .location-form-split {
    font-family: $primaryType;
    font-size: 1.8rem;
    font-weight: bold;
    padding: 0 1.5rem;
    text-align: center;
    color: $black;
    max-width: 8.5rem;
    align-self: center;
    margin-bottom: 1.5rem;

    @media screen and (max-width: $xs-tablet) {
      max-width: none;
      width: 100%;
      padding: 1.5rem;
      margin-bottom: 0;
      padding: 1rem 1rem 1.4rem;
      line-height: 1;
    }
  }
  .location-form-findme-btn {
    font-size: 1.6rem;
    width: 100%;
    max-width: 30rem;
    height: 4.5rem;
    text-transform: lowercase;
    font-family: $primaryType;
    margin-bottom: 1.5rem;

    &::after {
      content: url("../img/locate.svg");
      display: inline-block;
      width: 1.5rem;
      height: 1.5rem;
      transition: .4s cubic-bezier(0.175, -0.585, 0.32, 1.575) transform;
      margin-top: -0.2rem;
      margin-left: .5rem;
      vertical-align: middle;
    }
    @media screen and (max-width: $xs-tablet) {
      max-width: none;
      font-size: 1.8rem;
      height: 6rem;
      margin-bottom: 0;
    }
    &:hover,
    &:focus {
      &::after {
        animation: .5s findMeAnimation cubic-bezier(0.175, -0.585, 0.32, 1.575) forwards;
      }
    }
  }
  .checkbox {
     label{
      font-size: 1.5rem;
    }
  }
  @keyframes findMeAnimation {
    0% {
      transform: translate(0);
      opacity: 1;
    }
    50% {
      transform: translate(75%, -75%);
      opacity: 0;
    }
    51% {
      transform: translate(-75%, 75%);
      opacity: 0;
    }
    100% {
      transform: translate(0);
      opacity: 1;
    }
  }
  .location-form-search-btn {
    border: 0;
    font-weight: bold;
    text-transform: lowercase;
    width: 100%;
    font-family: $primaryType;
    max-width: 18.9rem;
    height: 4.5rem;
    cursor: pointer;
    order: 4;

    @media screen and (max-width: $xs-tablet) {
      max-width: none;
      height: 6rem;
      font-size: 1.8rem;
    }
  }
  .filter-bar {
    position: relative;
    @media screen and (max-width: $xs-tablet) {
      padding-top: 2.5rem;
      &:before {
        content: "";
        display: block;
        position: absolute;
        top: -0.3rem;
        left: -3rem;
        width: calc(100% + 6rem);
        height: .3rem;
        background: url(../img/divider-pale-blue-medium.svg) center center repeat-x;
      }
    }

    .extend-filters {
      display: none;
      font-family: $primaryType;
      position: absolute;
      right: 0;
      bottom: -2rem;
      font-weight: 500;
      font-size: 1.5rem;
      line-height: 1.33;
      color: $green300;
      background: transparent;
      border: 1px solid transparent;

      &:focus {
        outline: .2rem solid $green300;
        outline-offset: .8rem;
      }
      
      @media(max-width: $xs-tablet) {
        display: inline-block;
      }
    }
    
    .location-form-filters {
      font-family: $primaryType;
      font-size: 1.8rem;
      font-weight: bold;
      text-transform: lowercase;
      border: none;
      width: 100%;
      order: 5;
      position: relative;
      
      .location-form-filters-title {
        display: inline-block;
        margin-bottom: 2rem;
        
        @media(max-width: $xs-tablet) {
          display: block;
        }
      }

      .location-form-filters-list {
        margin: 0;
        list-style: none;
        list-style-type: none;
        display: inline-block;
        font-weight: normal;
        position: static;

        @media(max-width: $xs-tablet) {
          padding-bottom: 1.2rem;
          padding-left: 1rem;
          overflow-x: scroll;
          white-space: nowrap;
          padding-top: 1rem;
          width: 100vw;
        } 

        li {
          display: inline-block;
          padding: 0 3rem;
          font-size: 1.6rem;
          
          @media(max-width: $xs-tablet) {
            padding-left: 0;
          }
        }

        &.fcs-view-all {
          padding-left: 0;
          margin-left: 0;

          li {
            display: block;
            padding: 1rem 3rem;
            margin-bottom: 1rem;
          }

          &.locations-left-gradient,
          &.locations-right-gradient {
            &::after,
            &::before {
              content: none;
            }
          }
        }

        &::after,
        &::before {
          opacity: 0;
        }

        &.locations-right-gradient {
          &::after {
            content: "";
            transition: opacity 0.2s;
            opacity: 1;
            position: absolute;
            width: 30%;
            height: 7rem;
            bottom: 0;
            right: -1.5rem;
            z-index: 1;
            pointer-events: none;
            background: -moz-linear-gradient(left, rgba(237, 244, 243,0) 20%, rgba(237, 244, 243,1) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(237, 244, 243,0) 20%,rgba(237, 244, 243,1) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(237, 244, 243,0) 20%,rgba(237, 244, 243,1) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
        
            @media screen and (min-width: $small-tablet) {
              display: none;
            }
          }
        }
        
        &.locations-left-gradient {
          &::before {
            content: "";
            transition: opacity 0.2s;
            opacity: 1;
            position: absolute;
            width: 30%;
            height: 7rem;
            bottom: 0;
            right: auto;
            left: -1.5rem;
            z-index: 1;
            pointer-events: none;
            background: -moz-linear-gradient(left, rgba(237, 244, 243,1)6%, rgba(237, 244, 243,0) 100%); /* FF3.6-15 */
            background: -webkit-linear-gradient(left, rgba(237, 244, 243,1) 6%, rgba(237, 244, 243,0) 100%); /* Chrome10-25,Safari5.1-6 */
            background: linear-gradient(to right, rgba(237, 244, 243,1) 6%, rgba(237, 244, 243,0) 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
            filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 */
        
            @media screen and (min-width: $small-tablet) {
              display: none;
            }
          }
        }
      }
    }
  }

  .custom-select {
    &.hidden-mobile {
      @media (max-width: $xs-tablet) {
        display: none;
      }
  
      &.location-form-search-distance {
        @media (max-width: $xs-tablet) {
          background-image: none;
        }
      }
    }
  }

  .custom-select-mobile {
    text-align: center;
    text-align-last: center;
    border-radius: 3.5rem;
    font-size: 1.6rem;
    font-family: $primaryType;
    font-weight: 700;
    border: none;
    background-color: $green300;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
    margin-left: -2.5rem;
    color: $white;
    background-image: none;
    cursor: pointer;
    width: 100%;
    max-width: 17.6rem;
    position: relative;
    -webkit-appearance: none;
    -moz-appearance: none;
    -ms-appearance: none;
     -o-appearance: none;
      appearance: none;
    @media (max-width: $small-tablet) {
      max-width: 11.6rem;
    }
    &:hover,
    &:focus,
    &:active { 
      background-color: $gold500;
      color: $black;
    }

    &::-ms-expand {
      display: none;
    }
  }
  #customLabel{
    display: none;
  }

  .edge & {
    .custom-select-mobile {
      padding-left: 5rem;
      &:hover,
      &:focus,
      &:active { 
        overflow: hidden;
        &::-ms-value {
          background: none;
       }
     }
    }
  }
}

.ios {
  .location-form-component {
    .custom-select {
      &.location-form-search-distance {
        @media (max-width: $xs-tablet) {
          text-indent: 15px;
        }
      }
    }

    .custom-select-mobile {
      @media (max-width: $xs-tablet) {
        text-indent: 15px;
      }
    }
    
    .checkbox {
      label {
        &::before {
          display: block;
          animation: none;
        }
        &::after {
          animation: none;
          transform: translateY(-50%) scale(1);
          display: none;
        }
      }
    
      input[type="checkbox"]:checked + label {
        &::before {
          display: none;
          animation: none;
        }
        &::after {
          opacity: 1;
          display: block;
          animation: none;
        }
      }
    }
  }
}