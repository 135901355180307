.account-card-component {
  background-color: $white;
  padding: 2.5rem 3rem;
  width: 100%;
  text-align: center;
  &>.triangle-link {
    display: inline;
  }
  .title {
    font-size: 2.6rem;
    font-weight: bold;
    text-align-last: left;
  }
  p {
    font-size: 1.5rem;
    margin-bottom: 0.5rem;
  }
  .triangle-link {
    color: $green300;
    font-size: 1.8rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
    &::after {
      background-image: url("../img/triangle-green.svg");
    }
  }
  hr {
    background: url('../img/divider-pale-blue.svg') center center repeat-x;
    background-size: cover;
    height: .3rem;
    border: 0;
    margin: 3rem 0;
    width: 100%;
  }
  .card-content {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    flex-direction: column;
    min-height: 32rem;
    .title {
      font-size: 1.5rem;
    }
    .card-item {
      width: 100%;
      margin-bottom: 2rem;
      text-transform: lowercase;
    }
    .item {
      display: flex;
      text-align: left;
      justify-content: space-between;
      align-items: center;
      position: relative;
      padding: 1rem 0;
      &::before {
        content: '';
        background: url("../img/divider-pale-blue.svg") center center repeat-x;
        background-size: auto;
        background-size: cover;
        display: block;
        width: 100%;
        height: .3rem;
        left: 0;
        right: 0;
        top: 0;
        position: absolute;
        z-index: 1;
      }
      &:last-child {
        &::after {
          content: '';
          background: url("../img/divider-pale-blue.svg") center center repeat-x;
          background-size: auto;
          background-size: cover;
          display: block;
          width: 100%;
          height: .3rem;
          left: 0;
          right: 0;
          bottom: 0;
          position: absolute;
          z-index: 1;
        }
      }
    }
    .item-description-wrapper,
    .location-detail-wrapper {
      max-width: 22rem;
    }
    .location-address {
      line-height: 1;
    }
  }
}