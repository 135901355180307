#SiteMap {
    margin: 4rem auto;
    max-width: 1400px;
    display: flex;
    padding-top: 6rem;
    position: relative;
    h1 {
        font-size: 4rem;
        position: absolute;
        left: 0;
        top: 0;
        color: $green300;
    }

    a {
        color: $gray500;

        &:hover,
        &:focus {
            color: $green300;
        }
    }

    ul {
        list-style: none;
        padding: 0;
        margin: 0;
        font-family: $primaryType;

        li {
            margin: .4rem 0 0 2rem;
            font-size: 1.6rem;
            text-transform: capitalize;

            @media (max-width: 380px) {
                margin: .4rem 0 0 .4rem;
            }
        }
    }
}

#SiteMapLeftColumn {
    width: 49%;
    margin-right: 1%;
    align-self: flex-start;
    li {
        list-style: none;
    }
    @media (max-width: $xs-tablet) {
        margin-top: 6rem !important;
    }
}

#SiteMapRightColumn {
    width: 49%;
    margin-top: 3.7rem;
    margin-left: 1%;
    li {
        list-style: none;
    }
    @media (max-width: $xs-tablet) {
        margin-top: 6rem !important;
    }
}

#SiteMap li {
    list-style: none;
}

#SiteMap li.level0 {
    font-weight: bold;
    font-size: 2rem;
    text-transform: uppercase;
}

#SiteMap li.level1 {
    font-weight: bold;
    font-size: 2rem;
    margin-top: 2rem;
    text-transform: capitalize;
    list-style: none;
}

#SiteMap li.level2 {
    font-weight: normal;
    list-style: none;
    margin-top: 1rem;
    text-transform: capitalize;
    font-size: 1.8rem;

}

#SiteMap li.level4 {
    list-style: none;
    font-size: 1.6rem;
}

@media only screen and (max-width: $xs-tablet) {
    #SiteMap {
        #SiteMapLeftColumn,
        #SiteMapRightColumn {
            width: 90%;
            float: none;
            margin: 0;
        }

        #SiteMapRightColumn {
            margin-left: 4rem;
            width: calc(90% - 40px);
        }
    }
}
