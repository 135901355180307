.account-rewards-offers-details-redesign-component {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 8rem 1.5rem 0 1.5rem;

  &::before {
    content: '';
    display: block;
    height: 1.6rem;
    position: absolute;
    top: -1.6rem;
    background: $white;
    left: 0;
    clip-path: polygon(0% 56.25%, 0.69085% 59.0500%, 1.82342% 63.15625%, 3.313% 67.95625%, 4.650% 71.7875%, 5.981% 75.20625%, 6.87% 77.2875%, 7.900% 79.5%, 9.309% 82.19%, 10.2790% 83.84375%, 11.691% 85.9%, 12.9523% 87.5%, 14.5028% 88.9%, 15.764% 89.5874%, 17.5441% 90.01875%, 19.101% 90.01875%, 20.436% 89.7625%, 22.734% 88.94375%, 24.5883% 88.0375%, 25.52380% 87.5%, 26.5904% 86.875%, 28.07% 85.83125%, 29.55% 84.64375%, 31.555% 82.83%, 33.332% 81.0625%, 34.7472% 79.55%, 36.8996% 77.10625%, 38.82209% 74.8%, 40.6047% 72.575%, 42.458% 70.1875%, 44.01% 68.13%, 45.86% 65.6375%, 47.868% 62.925%, 49.7832% 60.3062%, 51.359% 58.1437%, 52.987% 55.91874%, 54.85% 53.41875%, 56.173% 51.6375%, 57.798% 49.4819%, 60.0312% 46.59375%, 62.2857% 43.75%, 63.734% 41.949%, 66.106% 38.824%, 68.25% 35.80625%, 69.970% 33.3%, 71.968% 30.337%, 73.447% 28.125%, 75.149% 25.6249%, 76.195% 24.1375%, 78.57% 20.9375%, 80.3809% 18.75%, 81.9758% 16.875%, 83.9758% 14.4625%, 85.760% 12.2937%, 86.87% 10.9625%, 88.352% 9.24375%, 89.465% 7.9937%, 91.238% 6.25%, 92.5062% 5.1125%, 93.7659% 4.0625%, 94.6533% 3.3687%, 95.768% 2.55%, 97.1015% 1.64375%, 98.2899% 0.9125%, 100% 0%, 100% 100%, 0% 100%, 0% 56.25%);
    width: 100%;
  }

  .info {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    max-width: 50rem;
  }
  
  .image-reward-container {
    max-width: 15rem;
    min-width: 12rem;
    margin-right: 3.2rem;
    margin-bottom: 2rem;
  }

  .img-reward-offer {
    width: 100%;
    height: 100%;
  }
  
  .title {
    margin-bottom: 3rem;
    font-size: 3rem;
    font-weight: 700;
  }
  
  .description {
    margin-bottom: 3rem;
  }

  .primary-button {
    font-size: 1.8rem;         
  }
  
  @media screen and (max-width: 768px) {
      flex-direction: column;
      align-items: center;
  
      .account-rewards-offers-title {
        margin-bottom: 3.6rem;
      }

      .info {
        min-width: 100%;
        align-items: flex-start;
      }

      .image-reward-container {
        margin: 0 0 2rem 0;
      }

      .img-reward-offer {
        margin: 0 auto;
      }

      .primary-button {
        display: none;
      }
  }
}