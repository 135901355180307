.unlock-reward-success-component {
  max-width: 33rem;
  margin: 0 auto;

  .modal-title {
    margin: 0 auto;
  }

  .info-wrapper {
    margin-bottom: 2.2rem;
  }

  .modal-body {
    padding: 0;
    max-width: 31.5rem;

    p {
      font-size: 1.8rem;
      font-weight: 700;
      margin-bottom: 8rem;
    }
    
    .primary-button {
      padding: 1.64rem 3.8rem;
      font-size: 1.8rem;
      margin-bottom: 3.5rem;
      
      @media screen and (max-width: $xs-tablet) {
        padding: 2rem 3.2rem;
      }
    }
    
    .triangle-link {
      font-size: 1.8rem;
      color: $green300;

      &:after {
        background-image: url("../img/triangle-green.svg");
      }
    }
  }
}

