.secondary-bg-hero-component {
  position: relative;
  width: 100%;
  margin-bottom: 4.5rem;
  min-height: 38rem;
  padding-top: 2rem;

  @media screen and (max-width: $small-tablet) {
    width: 100%;
  }
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -ms-flex-align: center;
    align-items: center;
    min-height: 24em;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    @media screen and (max-width: $small-tablet) {
      display: block;
      flex: none;
      margin-bottom: 0;
    }

  .image-wrapper {
    overflow: hidden;
    user-select: none;
    min-height: 38rem;
    max-width: 85%;
    margin-left: 15%;

    @media screen and (max-width: $small-tablet) {
      max-height: 28rem;
      margin-left: -1.5rem;
      max-width: calc(100% + 15px);
    }
  }

  img {
    height: 100%;
    object-fit: cover;
    object-position: center;

    @media screen and (max-width: $small-tablet) {
      width: 100% ;
      object-fit: none;  
    }
  }

  .hero-card {
    position: absolute;
    max-width: 56.6rem;
    min-width: 32%;
    background-color: rgba($white, 0.95);
    padding: 1.2rem;
    border-radius: .6rem;
    box-sizing: border-box;

    @media screen and (max-width: $small-tablet) {
      margin-top: calc(-34%);
      bottom: unset;
      margin-left: auto;
      margin-right: auto;
      position: relative;
    }

    @media screen and (max-width: 600px) {
      margin-left: 1.5rem;
      margin-right: 1.5rem;
    }

    
    .hero-card-frame {
      border: .5rem solid rgba($green600, 0.4);
      border-radius: .6rem;
      padding: 2.4rem;
      height: inherit;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (max-width: $small-tablet) {
        padding: 3rem 2.5rem;
      }
    }

    .title {
      font-size: 5.2rem;
      line-height: 1;
      font-family: $primaryType;
      font-weight: 500;
      
      @media screen and (max-width: $small-tablet) {
        font-size: 4.5rem;
      }

      h1,
      h2,
      h3,
      h4,
      h5,
      h6 {
        line-height: 1;
        font-size: 5.2rem;
        font-family: $primaryType;
        font-weight: 500;

        @media screen and (max-width: $small-tablet) {
          font-size: 4.5rem;
        }
      }

      .script-text {
        display: inline-block;
        font-family: $secondaryType;
        font-size: 4rem;
        color: $green300;
        margin-right: 0.23em;

        @media screen and (max-width: $small-tablet) {
          font-size: 3rem;
        }
      }

      & + .card-content {
        margin-top: 1.5rem;
        max-width: 100%;
        height: 100%;

        @media screen and (max-width: $small-tablet) {
          margin-top: 1rem;
        }
      }
    }

    .card-content {
      text-align: center;
      
      p {
        margin-bottom: 2.5rem;
      }

      .primary-button {
        @media screen and (max-width: $small-tablet) {
          padding: 2.1rem 5.8rem;
          font-size: 1.8rem;
        }
      }
    }
    //JJ-720
    .fcs-third-party-icon {
      position: relative;
      display: inline;
      right: 0;
      &:after {
        position: relative;
      }
    }
  }


  //START themes
  &.gold-theme {
    .hero-card-frame {
      border-color: rgba($gold500, 0.4);
    }
  }

  &.blue-theme {
    .hero-card-frame {
      border-color: rgba($blue600, 0.4);
    }
  }

  &.mandarin-theme {
    .hero-card-frame {
      border-color: rgba($mandarin700, 0.4);
    }
  }

  &.mint-theme {
    .hero-card-frame {
      border-color: rgba($green1000, 0.4);
    }
  }
  //END themes

  //START alignments
  @media screen and (min-width: $small-tablet) {
    &.left-align {
      justify-content: left;
      padding-left: 1.5rem;
      .hero-card {
        right: 0;
        @media screen and (max-width: $small-tablet) {
          left: 1.5rem;
        }
      }
      .image-wrapper {
        margin-left: 0;
      }
    }

    &.right-align {
      .hero-card {
        left: 1.5rem;
      }
      .image-wrapper {
        float: right;
        margin-right: 1.5rem;

        @media screen and (max-width: $small-tablet) {
          float: none;
        }
      }
    }
  }
  //END alignments
}
