.allergens-component {
  max-width: 56rem;
  text-align: center;
  margin: 0 auto;

  .section-component {
    &.wave-top {
      padding: 1.6rem 0 1rem;

      @media screen and (max-width: $xs-tablet) {
        margin-top: 0;
        padding-top: 0;
      }
    }

    &.mint-theme {
      background-color: rgba($green700, 0.49);
    }
  }

  .title {
    font-size: 1.8rem;
    line-height: 1.11;
    font-weight: 500;
    font-family: $primaryType;
    color: $black;
    display: block;
    margin-bottom: .8rem;
  }

  small {
    padding: 0 1rem;
    font-size: .8rem;
    line-height: 1.38;
    color: $black;
    font-family: $primaryType;
    margin-top: 1rem;
    display: inline-block;

    @media screen and (max-width: $xs-tablet) {
      margin-bottom: 2rem;
      padding: 0 1.3rem;
    }
  }

  .custom-list {
    margin: 0 auto;
    text-align: left;
    display: inline-block;
    li {
      font-size: 1.5rem;
      font-weight: 400;
      line-height: 1.33;
    }
  }
}