.accordion-component {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 2rem 0;

    .show-more-link,
    .show-fewer-link {
      background: none;
      border: none;
      font-size: 1.6rem;
      font-weight: 700;
      color: $green300 !important;
      text-align: center;

      &:hover {
        cursor: pointer;
        text-decoration: underline;
      }
      
      &::after {
        content: "";
        background-image: url("../img/green-arrow.png");
        background-size: contain;
        background-repeat: no-repeat;
        display: inline-block;
        width: 1.4rem;
        height: 0.8rem;
        margin-left: 0.6rem;
      }

    }
    .show-fewer-link {
      &::after {
        transform: translateY(-50%) rotateX(180deg);
      }
    }

    .hidden {
        display: none;
    }

    .visible {
        display: block;
    }

    @media screen and (min-width: $large) {
          display: none;
    }

}