.size-selection-component {
  margin-top: 2.2rem;

  &.larger {
    label {
      margin-right: 5.4rem;

      @media screen and (max-width: $xs-tablet) {
        margin-right: 1.6rem;
      }
    }
  }

  label {
    display: inline-block;
    margin-right: 5.4rem;
    text-align: center;
    cursor: pointer;

    &:last-of-type {
      margin-right: 0;
    }

    .text {
      font-size: 1.8rem;
      color: $black;
      font-family: $primaryType;
      font-weight: 700;
      width: 3.5rem;
      height: 3.5rem;
      border: 3px solid $green300;
      border-radius: 500px;
      text-align: center;
      display: block;
      user-select: none;
    }
  }

  .size {
    margin: 0;
    padding: 0;
    opacity: 0;
    position: absolute;

    &:checked {
      & + label {
        .text {
          background: $green300;
          color: $white;
        }
      }
    }

    & + label {
      .icon {
        display: block;
        margin: 0 auto 1.2rem;

        &.active {
          display: none;
        }
      }
    }

    &.s,
    &.m,
    &.l,
    &.xl {
      &:checked {
        & + label {
          .icon {
            display: none;

            &.active {
              display: block;
            }
          }
        }
      }
    }
  }
}